import React from "react";
import styled from "styled-components/macro";
import { map } from "lodash-es";
import { ReactComponent as Icon } from "../../../../src/assets/v-icon.svg";

const RoleRadioButtonGroup = ({ config, value, onChange }) => (
  <Container>
    {map(config, (radioObject) => {
      const { label, value: buttonValue } = radioObject;
      const isSelected = radioObject.value === value;

      return (
        <Role key={buttonValue} onClick={() => onChange(buttonValue)}>
          <Circle>{isSelected && <StyledIcon />}</Circle>
          <Label>{label}</Label>
        </Role>
      );
    })}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Role = styled.div`
  height: 50px;
  background: #ffffff;
  box-shadow: 0 0 15px #d3ddfa;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const Circle = styled.div`
  width: 32px;
  height: 32px;
  background: #e9eeff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 40px;

  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
    left: 24px;
  }
`;

const StyledIcon = styled(Icon)`
  color: #09216a;
  width: 13px;
  height: 11px;

  @media (max-width: 768px) {
    width: 11px;
    height: 9px;
  }
`;

export default RoleRadioButtonGroup;
