import { httpsCallable } from "firebase/functions";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import Dropdown from "../Components/core/formElements/dropdown/Dropdown";
import Checkbox from "../Components/core/formElements/Checkbox";
import InfoIcon from "../Components/core/icons/InfoIcon";
import Input from "../Components/core/formElements/Input";
import ValidateIcon from "../Components/core/icons/ValidateIcon";
import DatePicker from "../Components/core/formElements/DatePicker";
import HorizontalProgressLine from "../Components/core/HorizontalProgressLine";
import TextArea from "../Components/core/formElements/TextArea";
import VerticalStepsLine from "../Components/VerticalStepsLine";
import PhoneCodeDropdown from "../Components/core/formElements/PhoneCodeDropdown";
import { useAuthenticated } from "../hooks/useAuthenticated";
import FileUpload from "../Components/core/fileUpload/FileUpload";
import Avatar from "../Components/core/Avatar";
import DALService, {
  DALServiceV2,
} from "../services/data-access-layer.service";
import TimeCountdown from "../Components/TimeCountdown";
import ShowMoreBox from "../Components/ShowMoreBox";
import FiltersArea from "../Components/FiltersArea";
import { modalTypes, projectFilterTypes } from "../consts";
import { useAppContract } from "../hooks/useAppContract";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../utils/connectors";
import CollapsibleProjectInfo from "./account/CollapsibleProjectInfo";
import { projectsSelector } from "../store/selectors/projects";
import SliderNav from "../Components/Section/sliderNav/SliderNav";
import { AuthService } from "../services/auth.service";
import { auth as Authmodule } from "../utils/db";
import ModalInput from "../Components/core/formElements/ModalInput";
import RoleRadioButtonGroup from "../Components/core/formElements/RoleRadioButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../store/modal/actions";
import CodeInputs from "../Components/core/CodeInputs";
import {
  applyActionCode,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  sendEmailVerification,
} from "firebase/auth";
import { downloadLocalFile } from "../utils/fileUtils";
import { functions } from "../utils/db";
import Slider from "../Components/core/slider/Slider";

const investorData = {
  completedSteps: ["Contact info"],
  contactInfo: {
    fullName: "Mark Investor",
    email: "matulu.uvimeg@jollyfree.com",
    phoneCode: { label: "+972", value: "+972", meta: { code: "IL" } },
    phoneNumber: "190019192",
    isAgreedWithPolicy: true,
    notUSResident: true,
  },
  companyInfo: {
    companyName: "Mark Invest",
    countryIncorporation: [{ label: "All", value: "All" }],
    incorporationLicense: {
      symbolURL:
        "https://res.cloudinary.com/pitchshow/image/upload/v1681560388/pitchshow/tlxtvh2q2baost6zg1fw.jpg",
      fileName: "puppy.jpg",
    },
    typeOfInvestment: { value: "Equity", label: "Equity" },
    typeOfIndustry: [
      {
        value: "Automobiles and Components",
        label: "Automobiles and Components",
      },
      { value: "Banks, Capital Goods", label: "Banks, Capital Goods" },
    ],
    otherIndustryType: "",
    investmentSize: { value: "100K - 1M", label: "100K - 1M" },
    groupOrIndividual: { value: "Group", label: "Group" },
    companyStage: { value: "Pre-revenue", label: "Pre-revenue" },
    isRegulated: { value: false, label: "No" },
    jurisdiction: "",
  },
};

const companyData = {
  contactInfo: {
    fullName: "Mark Comp",
    email: "matulu.uvimeg@jollyfree.com",
    phoneCode: { label: "+972", value: "+972", meta: { code: "IL" } },
    phoneNumber: "1900102020",
    isAgreedWithPolicy: true,
  },
};

const equityProjectData = {
  companyType: { value: "Equity", label: "Equity" },
  foundersInfo: [
    {
      name: "alalalal",
      role: "LOL",
      background: "https://mail.google.com/mail/u/1/#inbox",
      linkedIn: "https://mail.google.com/mail/u/1/#inbox",
    },
  ],
  equityCompanyInfo: {
    companyName: "Eq Company",
    countryOfCooperation: [{ label: "All", value: "All" }],
    companyStage: { value: "Seed", label: "Seed" },
    companyWebsite: "https://mail.google.com/mail/u/1/#inbox",
    incorporationLicense: {
      symbolURL:
        "https://res.cloudinary.com/pitchshow/image/upload/v1681560553/pitchshow/ytviszazghgfirmap70s.jpg",
      fileName: "puppy.jpg",
    },
    industry: [
      {
        value: "Automobiles and Components",
        label: "Automobiles and Components",
      },
      { value: "Banks, Capital Goods", label: "Banks, Capital Goods" },
    ],
    otherIndustryType: "",
    projectDescription: "https://mail.google.com/mail/u/1/#inbox",
    numberOfEmployees: "4444",
    teamBackground: "https://mail.google.com/mail/u/1/#inbox",
    pitchDeck: {
      symbolURL:
        "https://res.cloudinary.com/pitchshow/image/upload/v1681560562/pitchshow/utosz1xdrq2b90y8wpjr.jpg",
      fileName: "puppy.jpg",
    },
    financialRecords: {
      symbolURL:
        "https://res.cloudinary.com/pitchshow/raw/upload/v1681560569/pitchshow/dk5vlf8sjhqzdfqao3so.xls",
      fileName: "file_example_XLS_10.xls",
    },
    businessPlan: {
      symbolURL:
        "https://res.cloudinary.com/pitchshow/raw/upload/v1681560573/pitchshow/jsdvr2g5abgbeh1segux.docx",
      fileName: "file_example_XLS_10 copy.docx",
    },
    logo: {
      symbolURL:
        "https://res.cloudinary.com/pitchshow/image/upload/v1681560577/pitchshow/g7bt6efseqlj8qm9yavc.jpg",
      fileName: "puppy.jpg",
    },
  },
  sharesInfo: {
    currentValuation: "1000000",
    maxAllocation: "20",
    discountedValuation: "900000",
    totalRaisedTillDate: "25000",
    totalInvestorsToDate: "30",
    totalRevenue: "100000",
    last12MonthsRevenue: "3222",
    lastRaiseDate: "2023-02-26T22:00:00.000Z",
    lastRaiseAmount: "90000",
    typeOfInvestors: { value: "Founder", label: "Founder" },
    investors: [],
  },
  raisingInfo: {
    currentRound: "1000000",
    additionalPlatforms: "binance",
    pitchshowsSize: "",
    dateOfOpening: new Date(),
    timeframe: "90",
    vestingTerms: {
      symbolURL:
        "https://res.cloudinary.com/pitchshow/image/upload/v1681560645/pitchshow/cndyhozz2itrpbq1mlc4.jpg",
      fileName: "puppy.jpg",
    },
    pitchshowVestingTerms: {
      symbolURL:
        "https://res.cloudinary.com/pitchshow/image/upload/v1681560649/pitchshow/irsfxswjnsfazt22ax6u.jpg",
      fileName: "puppy.jpg",
    },
    moneyLastDuration: { value: "6 months", label: "6 months" },
  },
  partnersAndClients: {
    topPartners: "LOLO partner",
    numberOfClientsUsers: "1000",
    facebookLink: "https://mail.google.com/mail/u/1/#inbox",
    instagramLink: "https://mail.google.com/mail/u/1/#inbox",
    linkedInLink: "https://mail.google.com/mail/u/1/#inbox",
    isRegulated: { value: false, label: "No" },
    isHaveClients: { value: true, label: "Yes" },
    clients: ["cl1", "cl2", "cl3"],
    jurisdiction: "",
  },
  equityExitStrategy: {
    potentialValuation: "950000",
    nextRoundDate: "2023-06-28T21:00:00.000Z",
    yearOfListing: { value: "2027", label: "2027" },
    listingStockExchange: "nasdaq",
  },
  videoPitchLink: { videoPitchLink: "https://mail.google.com/mail/u/1/#inbox" },
  documentsLink: { documentsLink: "https://mail.google.com/mail/u/1/#inbox" },
};

const Components = () => {
  const { account, activate, error } = useWeb3React();
  const [sliderValue, setSliderValue] = useState(0);
  const [dropdownValue, setDropdownValue] = useState();
  const [phoneCode, setPhoneCode] = useState();
  const [isMulti, setIsMulti] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [numericValue, setNumericValue] = useState();
  const [validateInputValue, setValidateInputValue] = useState();
  const [datePickerValue, setDatePickerValue] = useState();
  const [textAreaValue, setTextAreaValue] = useState();
  const [radioValue, setRadioValue] = useState();
  const [file, setFile] = useState();
  const [codeInputs, setCodeInputs] = useState([]);
  const { app } = useSelector((state) => state);
  const {
    isAuthenticated,
    auth,
    signIn,
    signOut,
    mfaEnabled,
    isEmailVerified,
  } = useAuthenticated();
  const dalService = new DALService();
  const dispatch = useDispatch();

  const { projectsList } = useSelector(projectsSelector);
  const loginClickHandler = () => {
    return isAuthenticated ? signOut() : signIn();
  };

  const { getInvestmentsAmount, getMyInvestments } = useAppContract();
  const [chainProjectInvestment, setChainProjectInvestment] = useState();

  const onInit = async () => {
    if (account) {
      const myInvestments = await getMyInvestments();
      const myInvestmentAmount = await getInvestmentsAmount(0);
      setChainProjectInvestment(myInvestmentAmount);
    }
  };

  const recaptchaRef = useRef();
  const testWholeflow = async () => {
    try {
      const email = "levior1992@gmail.com";
      const password = "password";
      const phoneNumber = "+972533395963";

      // register
      const userCred = await createUserWithEmailAndPassword(
        AuthService.auth,
        email,
        password
      );
      // send email verification
      await sendEmailVerification(AuthService.auth.currentUser);
      // get the action code along with the mode that (need to be extracted from the verification email url)
      const actionCode = prompt("enter oobCode");
      // apply the code and verify the email
      await applyActionCode(AuthService.auth, actionCode);
      // reauthenticate the user
      const credential = EmailAuthProvider.credential(email, password);
      const newUserCred = await reauthenticateWithCredential(
        AuthService.auth.currentUser,
        credential
      );
      // setting the recaptcha verifier
      const recaptchaVerifier = new RecaptchaVerifier(
        recaptchaRef.current,
        {
          size: "invisible",
          callback: async function (response) {
            // reCAPTCHA solved, you can proceed with
            const multiFactorSession = await multiFactor(
              AuthService.auth.currentUser
            ).getSession();
            const phoneInfoOptions = {
              phoneNumber: phoneNumber,
              session: multiFactorSession,
            };
            const phoneAuthProvider = new PhoneAuthProvider(AuthService.auth);
            let verificationId;
            try {
              verificationId = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                recaptchaVerifier
              );
            } catch (e) {
              recaptchaVerifier.clear();
              // wrong or invalid number try again
            }

            const verificationCode = prompt("enter verificationCode");
            const cred = PhoneAuthProvider.credential(
              verificationId,
              verificationCode
            );
            const multiFactorAssertion =
              PhoneMultiFactorGenerator.assertion(cred);
            await multiFactor(AuthService.auth.currentUser).enroll(
              multiFactorAssertion,
              phoneNumber
            );
          },
        },
        AuthService.auth
      );
      const recaptchaWidgetId = await recaptchaVerifier.render();
      await recaptchaVerifier.verify();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  // useEffect(() => testWholeflow(), []);

  const divId = "captcha";
  useEffect(() => {
    // onInit();
  }, [account]);

  const signupWithEmailPassHandler = async () => {
    try {
      const user = await AuthService.createUserWithEmailAndPassword(
        "3lkjsflksdj@gotgel.org",
        "password"
      );
      await AuthService.sendEmailVerification("https://pitchshow.io");
    } catch (e) {
      try {
        console.error(e);
        const { code } = e;
        switch (code) {
          case "auth/email-already-in-use":
            if (!Authmodule?.currentUser?.emailVerified) {
              await AuthService.sendEmailVerification();
              return;
            } else {
              throw e;
            }
          default:
            throw e;
        }
      } catch (e) {
        console.error("error occured while sending a verification email");
        throw e;
      }
    }
  };

  function handleResetPassword(actionCode, continueUrl, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    AuthService.verifyPasswordResetCode(actionCode)
      .then((email) => {
        const accountEmail = email;

        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.
        const newPassword = "...";

        // Save the new password.
        AuthService.confirmPasswordReset(actionCode, newPassword)
          .then((resp) => {
            // Password reset has been confirmed and new password updated.
            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
          })
          .catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
          });
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
  }

  function handleVerifyEmail(actionCode, continueUrl, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    AuthService.applyActionCode(actionCode)
      .then((resp) => {
        // Email address has been verified.
        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
      });
  }

  const signinWithEmailAndPassHandler = async () => {
    try {
      const verifier = await AuthService.recaptchaVerifier(
        divId,
        async (ver) => {
          try {
            const { verificationId, resolver, phoneNumber } =
              await AuthService.signInWithEmailAndPassword(
                "levior1992@gmail.com",
                "password",
                ver
              );
            const code = prompt(`please enter the code sent to ${phoneNumber}`);
            const res = await AuthService.signInWithEmailAndPassword2Fa(
              verificationId,
              code,
              resolver
            );
            return res;
          } catch (e) {
            ver.clear();
          }
        }
      );
      const widgetId = await verifier.render();
      const verified = await verifier.verify();
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const mode = urlSearchParams.get("mode");
    const actionCode = urlSearchParams.get("oobCode");
    const continueUrl = urlSearchParams.get("continueUrl");
    const lang = urlSearchParams.get("lang") || "en";

    switch (mode) {
      case "verifyEmail":
        handleVerifyEmail(actionCode, continueUrl, lang);
        break;
      case "resetPassword":
        handleResetPassword(actionCode, continueUrl, lang);
        break;
      default:
        return;
    }
  }, []);

  const phoneNumber = "+972549937452";

  const verifyCodeSentHandler = async (phoneNumber = phoneNumber) => {
    try {
      if (!window.confirmationResult) {
        throw "confirmationResult not exists";
      }
      const code = prompt("Please enter the code");
      await AuthService.verifyCodeSent(
        phoneNumber,
        code,
        window.confirmationResult
      );
    } catch (e) {
      console.error(e);
    }
  };

  const [recaptcha, setRecaptcha] = useState(null);
  async function initRegisterPhone() {
    try {
      const _verifier = AuthService.recaptchaVerifier(
        divId,
        async (verifier) => {
          try {
            const confirmationResult = await AuthService.registerPhone(
              phoneNumber,
              verifier
            );
            window.confirmationResult = confirmationResult;
            return confirmationResult;
          } catch (e) {
            console.error(e);
            verifier.clear();
            return;
          }
        }
      );
      window.recaptchaWidgetId = await _verifier.render();
      return _verifier;
    } catch (e) {
      window.recaptchaWidgetId = null;
      console.error(e);
      throw e;
    }
  }

  const recaptchaId = "recaptcha-id";
  const resendCode = async () => {
    try {
      const phoneNumber = "+972533395963";
      const recaptchaVerifier = new RecaptchaVerifier(
        recaptchaRef.current,
        {
          size: "invisible",
          callback: async function () {
            try {
              if (AuthService.auth?.currentUser) {
                await AuthService.registerPhone(phoneNumber, recaptchaVerifier);
              }
            } catch (e) {
              recaptchaVerifier.clear();
            }
          },
        },
        AuthService.auth
      );
      try {
        const recaptchaToken = await recaptchaVerifier.verify();
        const recaptchaWidgetId = await recaptchaVerifier.render();
      } catch (e) {
        console.error(e);
        recaptchaVerifier.clear();
        recaptchaRef.current.innerHTML = `<div id="${recaptchaId}"></div>`;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  // const [verificationId, setVerificationId] = useState();

  const registerPhoneHandler = async () => {
    try {
      let verifier = recaptcha;
      if (!verifier) {
        verifier = await initRegisterPhone();
        setRecaptcha(verifier);
      }
      await verifier.verify();
    } catch (e) {
      throw e;
    }
  };

  const fillInvestorForm = async () => {
    try {
      const response = await DALServiceV2.setRoleProfile(
        "investor",
        investorData
      );

      alert("success");
    } catch (e) {
      alert(`error: ${e}`);
    }
  };

  const fillCompanyAndProjectForm = async () => {
    try {
      const response = await DALServiceV2.setRoleProfile(
        "company",
        companyData
      );
      const projectResponse = await DALServiceV2.createCompanyProject(
        equityProjectData
      );

      alert("success");
    } catch (e) {
      alert(`error: ${e}`);
    }
  };

  return (
    <Container>
      <SliderNav />

      <Slider
        value={sliderValue}
        onChange={setSliderValue}
        min={0}
        max={20000}
      />

      <button onClick={fillInvestorForm}>FILL INVESTOR FORM</button>
      <button onClick={fillCompanyAndProjectForm}>
        FILL COMPANY + PROJECT FORM
      </button>

      <Dropdown
        value={dropdownValue}
        onChange={setDropdownValue}
        placeholder="Country of incorporation"
        options={[
          { label: "Australia", value: "australia" },
          { label: "Austria", value: "austria" },
          { label: "Belgium", value: "belgium" },
          { label: "Brazil", value: "brazil" },
          { label: "Canada", value: "canada" },
          { label: "USA", value: "usa" },
          { label: "Israel", value: "israel" },
        ]}
        isMulti={isMulti}
      />
      <Wrapper>
        <Checkbox
          value={isMulti}
          onChange={(newChecked) => {
            setIsMulti(newChecked);
          }}
          label="Is Multi Select"
        />
        <InfoIcon info="Some text description of the field" />

        <ValidateIcon />
      </Wrapper>
      <Wrapper>
        <PhoneCodeDropdown
          value={phoneCode}
          onChange={setPhoneCode}
          backgroundColor="white"
        />

        <Input
          value={numericValue}
          onChange={setNumericValue}
          placeholder="Phone Number"
          isNumeric
        />
      </Wrapper>
      <Input
        value={inputValue}
        onChange={setInputValue}
        placeholder="With info field"
        InnerComponent={() => (
          <InfoIcon info="Some text description of the field" />
        )}
      />
      <Input
        value={validateInputValue}
        onChange={setValidateInputValue}
        placeholder="With validate icon"
        InnerComponent={() => (
          <ValidateIcon isValid={validateInputValue?.length > 3} />
        )}
      />
      <DatePicker
        value={datePickerValue}
        onChange={setDatePickerValue}
        placeholder="Date of birth"
      />
      <HorizontalProgressLine fillPercent={33} />
      <TextArea
        value={textAreaValue}
        onChange={setTextAreaValue}
        placeholder="Background..."
      />
      <VerticalStepsLineWrapper>
        <VerticalStepsLine
          steps={[
            "First Step",
            "Second Step",
            "Third Step",
            "Fourth Step",
            "Blabla",
          ]}
          completedSteps={["First Step", "Second Step"]}
          currentStepIndex={2}
        />
      </VerticalStepsLineWrapper>
      <FileUpload
        title="Pitch deck"
        subtitle="Upload Pitch deck"
        value={file}
        onChange={setFile}
      />
      <button onClick={loginClickHandler}>
        {isAuthenticated
          ? `hello ${app.displayName} - signout`
          : "sign in with google"}
      </button>
      <Avatar name="Mark Test" />
      <TimeCountdown endDate={Date.now() + 1500000} />
      <ShowMoreBox maxHeight={50}>
        alskd;laks;dlka;lsdk asdasdaslk;lasd alskd;laks;dlka;lsdk
        asdasdaslk;lasd alskd;laks;dlka;lsdk asdasdaslk;lasd
        alskd;laks;dlka;lsdk asdasdaslk;lasd
      </ShowMoreBox>
      <button
        onClick={async () => {
          const data = await dalService.createProject();
          alert(JSON.stringify(data));
        }}
      >
        BO - createProject
      </button>
      <button
        onClick={async () => {
          const data = await dalService.createInvestment();
          alert(JSON.stringify(data));
        }}
      >
        BO - createInvestment
      </button>
      <button
        onClick={async () => {
          const data = await dalService.getProjects();
          alert(JSON.stringify(data));
        }}
      >
        getProjects
      </button>
      <button
        onClick={async () => {
          const data = await dalService.getInvestments();
          alert(JSON.stringify(data));
        }}
      >
        getInvestments
      </button>
      <button
        onClick={async () => {
          const data = await dalService.getInvestors();
          alert(JSON.stringify(data));
        }}
      >
        getInvestors
      </button>
      <button
        onClick={async () => {
          const data = await dalService.updateWatchList();
          alert(JSON.stringify(data));
        }}
      >
        updateWatchList
      </button>
      <button
        onClick={async () => {
          const data = await dalService.getProjectData();
          alert(JSON.stringify(data));
        }}
      >
        getProjectData
      </button>
      <button
        onClick={async () => {
          const data = await dalService.getUserWatchlist();
          alert(JSON.stringify(data));
        }}
      >
        getUserWatchlist
      </button>
      <button
        onClick={async () => {
          const data = await dalService.getInvestorsAddedToWatchList(
            "1659169785606"
          );
          alert(JSON.stringify(data));
        }}
      >
        get Investors Added To Watch List
      </button>
      <button
        onClick={async () => {
          const data = await dalService.getCompanyIdRelatedByUserId(
            "1659169785606"
          );
          alert(JSON.stringify(data));
        }}
      >
        get Company Id Related By User Id
      </button>
      <VerticalStepsLineWrapper>
        <FiltersArea
          filterTypes={{
            [projectFilterTypes.projectStatus]: null,
            [projectFilterTypes.typeOfIndustry]: null,
            [projectFilterTypes.typeOfInvestment]: null,
            [projectFilterTypes.companyStage]: null,
            [projectFilterTypes.countryOfCooperation]: null,
          }}
        />
      </VerticalStepsLineWrapper>
      <button
        onClick={() => {
          try {
            activate(injected);
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {account ? account : "activate web3 injected provider"}
      </button>
      <div>investment amount: {chainProjectInvestment}</div>
      <CollapsibleProjectInfo
        numberOfInvestors={29}
        project={projectsList[0]}
      />

      <button
        onClick={async () => {
          alert(
            await AuthService.fetchSignInMethodsFor("levior1992@gmail.com")
          );
        }}
      >
        email auth methods
      </button>

      <button
        onClick={async () => {
          alert(AuthService.isMultiFactorEnabled());
        }}
      >
        isMultiFactorEnabled
      </button>

      <button
        onClick={async () => {
          const pass = prompt("enter ur password?");
          await AuthService.reauthenticateUser(pass);
        }}
      >
        reauth user
      </button>

      <button onClick={signupWithEmailPassHandler}>
        (1) signup email pass
      </button>
      <button onClick={registerPhoneHandler}>(2) registerPhoneHandler</button>
      <button onClick={verifyCodeSentHandler}>(3) verifyCodeSent</button>
      <button onClick={signinWithEmailAndPassHandler}>
        signinWithEmailAndPassHandler
      </button>

      <button onClick={resendCode}>resendCode</button>

      <div id={divId} />

      <ModalInput label="Test" value={inputValue} onChange={setInputValue} />

      <RoleRadioButtonGroup
        value={radioValue}
        onChange={setRadioValue}
        config={[
          { label: "Test", value: "test" },
          { label: "Lol", value: "lol" },
          { label: "Mark", value: "mark" },
        ]}
      />

      <button
        onClick={() =>
          dispatch(
            openModal({
              type: modalTypes.stripePaymentModal,
            })
          )
        }
      >
        Show Modal
      </button>

      <button
        onClick={() => {
          // window.open("data-room.pdf");
          downloadLocalFile("data-room.pdf");
        }}
      >
        Download File
      </button>

      <div id={recaptchaId} ref={recaptchaRef}></div>

      <CodeInputs
        value={codeInputs}
        setValue={setCodeInputs}
        onFilled={(result) => console.debug(`done`)}
      />

      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            await DALServiceV2.createUser(uid);
          } catch (e) {
            console.error(e);
          }
        }}
      >
        create user
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            await DALServiceV2.createCompanyProject(uid, { logo: "some_logo" });
          } catch (e) {
            console.error(e);
          }
        }}
      >
        createCompanyProject
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            await DALServiceV2.updateCompanyProject(
              uid,
              "eCZSGIjQoQ8aCAkaWalX",
              { logo: "ra" }
            );
          } catch (e) {
            console.error(e);
          }
        }}
      >
        updateCompanyProject
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            const docs = await DALServiceV2.getCompanyProjects(uid);
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getCompanyProjects
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            const docs = await DALServiceV2.getProjectsInvested(uid);
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getProjectsInvested
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            await DALServiceV2.addToWatchList(
              uid,
              "E076YrZgMIgxtUJiNwSa5raHj0w2",
              "eCZSGIjQoQ8aCAkaWalX",
              { name: "lol" }
            );
          } catch (e) {
            console.error(e);
          }
        }}
      >
        addToWatchList
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            await DALServiceV2.removeFromWatchList(uid, "eCZSGIjQoQ8aCAkaWalX");
          } catch (e) {
            console.error(e);
          }
        }}
      >
        removeFromWatchList
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            console.log({ uid });
            const docs = await DALServiceV2.getProjectWatchList(
              "eCZSGIjQoQ8aCAkaWalX"
            );
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getProjectWatchList
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            const docs = await DALServiceV2.getWatchList(uid);
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getWatchList
      </button>

      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            const data = { roles: "asd", initialRole: "investor" };
            await DALServiceV2.setUserData(data);
          } catch (e) {
            console.error(e);
          }
        }}
      >
        setUserData
      </button>

      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;

            const docs = await DALServiceV2.getUserData(uid);
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getUserData
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;

            const docs = await DALServiceV2.getProjects(uid, "company");
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getProjects
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;

            const docs = await DALServiceV2.getVerifiedProjects();
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getVerifiedProjects
      </button>

      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            const docs = await DALServiceV2.getInvestments(uid);
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getInvestments
      </button>

      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            const docs = await DALServiceV2.getProjectInvestments(uid, "ram");
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getProjectInvestments
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            await DALServiceV2.setRoleProfile("consultant", {
              some_data: true,
              lol: 1,
            });
          } catch (e) {
            console.error(e);
          }
        }}
      >
        setRoleProfile
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            const docs = await DALServiceV2.getConsultantProfile(uid);
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getConsultantProfile
      </button>
      <button
        onClick={async () => {
          try {
            const uid = AuthService.auth.currentUser.uid;
            const docs = await DALServiceV2.getRoleProfiles(uid);
            alert(JSON.stringify(docs));
          } catch (e) {
            console.error(e);
          }
        }}
      >
        getRoleProfiles
      </button>
      <button
        onClick={async () => {
          try {
            await DALServiceV2.updateRoleProfile("investor", {
              contactInfo: { phoneCode: {}, phoneNumber: "newphone" },
            });
          } catch (e) {
            console.error(e);
          }
        }}
      >
        updateRoleProfile
      </button>

      <button
        onClick={async () => {
          try {
            const callableReturnMessage = httpsCallable(
              functions,
              "makePayment"
            );
            callableReturnMessage({
              paymentMethod: "card",
              data: {
                paymentMethodId: "213131231",
                currency: "usd",
                amount: "123",
                projectId: "",
              },
              intent: "SIGNUP_FOR_FUND_RAISING",
            })
              .then((result) => {
                console.log(result.data.output);
              })
              .catch((error) => {
                console.log(`error: ${JSON.stringify(error)}`);
              });
          } catch (e) {
            console.error(e);
          }
        }}
      >
        makePayment
      </button>

      <button
        onClick={async () => {
          try {
            const projectId = prompt("enter the required projectId?");
            const data = await DALServiceV2.getInvestedAmount(projectId);
            alert(JSON.stringify(data));
          } catch (e) {
            console.error(e);
            throw e;
          }
        }}
      >
        get invested amount for project by company / consultant
      </button>

      <button
        onClick={async () => {
          try {
            const projectId = prompt("enter the required projectId?");
            const data = await DALServiceV2.getProject(projectId);
            alert(JSON.stringify(data));
          } catch (e) {
            console.error(e);
            throw e;
          }
        }}
      >
        getProject
      </button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

const VerticalStepsLineWrapper = styled.div`
  padding: 20px;
  background: #203471;
`;

export default Components;
