import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { resetInvestorSignup } from "../../../store/investorSignUp/actions";
import { useRerouteToFormStart } from "../../../hooks/useRerouteToFormStart";
import { investorSignUpCompletedStepsSelector } from "../../../store/selectors/signUp";

const Investor = () => {
  const dispatch = useDispatch();
  useRerouteToFormStart({
    formStartUrl: "/sign-up/investor/contact-info",
    completedStepsSelector: investorSignUpCompletedStepsSelector,
  });

  useEffect(() => {
    return () => {
      dispatch(resetInvestorSignup());
    };
  }, []);

  return <Outlet />;
};

export default Investor;
