import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { AuthService } from "../services/auth.service";

export const recaptchaId = "recaptchaId";

const Recaptcha = () => {
  useEffect(() => {
    AuthService.recaptchaVerifier(recaptchaId, (e) => {});
  }, []);

  return <Container id={recaptchaId} />;
};

const Container = styled.div``;

export default Recaptcha;
