import { useSelector } from "react-redux";
import { filter, map } from "lodash";
import { projectsListSelector } from "../store/selectors/projects";
import { useFavorites } from "../utils/api/queries/useFavorites";

export const useFavoriteProjects = () => {
  const projects = useSelector(projectsListSelector);
  const { data: favoritesData } = useFavorites();

  const favorites = filter(
    favoritesData,
    (favoriteObject) => favoriteObject.isInWatchList
  );

  const data = map(favorites, (project) => ({
    ...projects[project.projectId],
  }));

  return { data };
};
