import styled from "styled-components/macro";
import HorizontalProgressLine from "../../../Components/core/HorizontalProgressLine";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(
    115.04deg,
    #e5ebff 27.52%,
    rgba(242, 245, 255, 0) 96.92%
  );
  padding-bottom: 60px;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 212px;
  max-width: 473px;

  @media (max-width: 768px) {
    padding: 0 18px;
  }
`;

export const Header = styled.div`
  font-size: 23px;
  font-weight: 600;
  color: #021652;
  text-align: center;
`;

export const Description = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: #021652;
  margin-top: 16px;
  text-align: center;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 45px;
  margin-bottom: 60px;
`;

export const StyledHorizontalProgressLine = styled(HorizontalProgressLine)`
  margin-bottom: 37px;
`;
