import React from "react";
import Navbar from "../../../Components/Section/Navbar";
import Footer from "../../../Components/Section/Footer";
import styled from "styled-components/macro";
import {
  Content,
  Description,
  PageContainer,
  RowsWrapper,
  SearchWrapper,
  StyledBreadcrumbs,
  Title,
  TitleWrapper,
  TopElements,
} from "../shared";
import { useDispatch, useSelector } from "react-redux";
import PageSearch from "../../../Components/core/PageSearch";
import { setSearch } from "../../../store/search/actions";
import { screenSizes } from "../../../consts";
import CollapsibleRow from "../CollapsibleRow";
import { map } from "lodash";
import { getCountriesListMessage } from "../../../utils/projectUtils";
import {
  normalizeDollarAmount,
  normalizeNumber,
} from "../../../utils/normalizations";
import { ShowMoreButton } from "../../launchpad/shared";
import { useClientPagination } from "../../../hooks/useClientPagination";
import { searchSelector } from "../../../store/selectors/search";
import { useFuzzySearch } from "../../../hooks/useFuzzySearch";
import CollapsibleProjectInfo from "../CollapsibleProjectInfo";
import { projectsListSelector } from "../../../store/selectors/projects";
import { useWindowSize } from "../../../hooks/useWindowSize";
import ProjectInfo from "../ProjectInfo";
import { useProjectInvestments } from "../../../utils/api/queries/useProjectInvestments";
import { useParams } from "react-router-dom";

const ManageProject = () => {
  const dispatch = useDispatch();
  const projectsList = useSelector(projectsListSelector);
  const { projectId } = useParams();

  const investments = useProjectInvestments({ projectId });

  const { filteredData } = useFuzzySearch({
    originalData: investments,
    searchFields: ["investorData.contactInfo.fullName"],
  });

  const { slicedData, viewNextPage, isHasMore } = useClientPagination({
    pageSize: 9,
    allData: filteredData,
  });

  const { width } = useWindowSize();

  const search = useSelector(searchSelector);

  const isDesktop = width > screenSizes.large;
  const project = projectsList[projectId];

  const breadcrumbsLinks = [
    { text: "My profile", url: "/account" },
    { text: "Manage project", url: "/account/manage-project" },
  ];

  const getFieldValuesArray = (investment) => {
    const { amount, investorData } = investment;
    const { contactInfo, companyInfo } = investorData;
    const { phoneCode, phoneNumber, email } = contactInfo;
    const { companyName, countryIncorporation } = companyInfo;
    const { finalPrice, totalRaising } = project;

    const shares = amount / finalPrice;
    const percentage = ((amount / totalRaising) * 100).toFixed(2);

    return [
      {
        field: "Investment amount",
        value: normalizeDollarAmount(amount),
        isInitiallyShowed: true,
        isMobileFullLine: true,
      },
      {
        field: "Number of shares",
        value: normalizeNumber(shares),
        isInitiallyShowed: true,
        isMobileFullLine: true,
      },
      {
        field: "Share price",
        value: normalizeDollarAmount(finalPrice),
        isInitiallyShowed: true,
        isMobileFullLine: true,
      },
      {
        field: "Percentage",
        value: `${percentage}%`,
        isInitiallyShowed: true,
        isInBadge: true,
        isMobileFullLine: true,
      },
      {
        field: "Phone number",
        value: `${phoneCode.value}${phoneNumber}`,
        isMobileFullLine: true,
      },
      {
        field: "Email",
        value: email,
        isMobileFullLine: true,
      },
      {
        field: "Project / Brand Name",
        value: companyName,
        isMobileFullLine: true,
      },
      {
        field: "Country of incorporation",
        value: getCountriesListMessage(countryIncorporation),
        isMobileFullLine: true,
      },
    ];
  };

  const renderInvestors = () => (
    <RowsWrapper>
      {map(slicedData, (investment) => {
        const { orderId, investorData } = investment;
        const { contactInfo } = investorData;
        const { fullName } = contactInfo;

        const fieldValuesArray = getFieldValuesArray(investment);

        return (
          <StyledCollapsibleRow
            key={orderId}
            logo={"/img/avatar.svg"}
            name={fullName}
            fieldValuesArray={fieldValuesArray}
            openDesktopHeight="220px"
          />
        );
      })}
    </RowsWrapper>
  );

  return (
    <PageContainer>
      <Navbar status={"account"} />

      <Content>
        <StyledBreadcrumbs linksArray={breadcrumbsLinks} />
        <TopElements>
          <TitleWrapper>
            <Title>Manage project</Title>
            <Description>
              Viewing investors information of <b>{project?.projectName}</b>
            </Description>
          </TitleWrapper>

          <SearchWrapper>
            <PageSearch
              value={search}
              onChange={(newValue) => dispatch(setSearch(newValue))}
              placeholder="Search by name..."
            />
          </SearchWrapper>
        </TopElements>

        <ProjectInfoWrapper>
          {isDesktop ? (
            <ProjectInfo
              project={projectsList[projectId]}
              numberOfInvestors={investments?.length}
            />
          ) : (
            <CollapsibleProjectInfo
              project={projectsList[projectId]}
              numberOfInvestors={investments?.length}
            />
          )}
        </ProjectInfoWrapper>

        {renderInvestors()}
        {isHasMore && (
          <ShowMoreButton onClick={viewNextPage}>Show More</ShowMoreButton>
        )}
      </Content>

      <Footer />
    </PageContainer>
  );
};

const StyledCollapsibleRow = styled(CollapsibleRow)`
  .grid {
    padding-left: 48px;
  }

  .logo {
    width: 21px;
    height: 21px;

    @media (max-width: 1280px) {
      height: 14px;
      width: 14px;
    }
  }
`;

const ProjectInfoWrapper = styled.div`
  margin-bottom: 21px;
`;

export default ManageProject;
