import React from "react";
import styled from "styled-components/macro";

const ProjectInfoCard = ({ className, title, icon, description }) => (
  <Container className={className}>
    <Icon src={icon} />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
);

const Container = styled.div`
  padding: 15px 0;
  color: #000f3c;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 0 30px 12px rgba(93, 130, 241, 0.2);
  text-align: center;
  border-radius: 27px;
  width: 190px;
  height: 165px;

  font-size: 17px;

  @media (max-width: 768px) {
    border-radius: 16px;
    width: 130px;
    height: 107px;
    font-size: 12px;
  }
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
  margin: 0 auto 24px auto;

  @media (max-width: 768px) {
    width: 15px;
    height: 15px;
    margin: 0 auto 16px auto;
  }
`;

const Title = styled.div`
  color: #000f3c;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Description = styled.div`
  font-weight: 500;
  margin-top: auto;
`;

export default ProjectInfoCard;
