import {
  EmailAuthProvider,
  multiFactor,
  PhoneAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
} from "firebase/auth";
import { AuthService } from "../services/auth.service";

export const registerPhoneHandler = async ({
  phoneNumber,
  recaptchaContainerId,
  onSuccess,
}) => {
  try {
    const _verifier = await AuthService.recaptchaVerifier(
      recaptchaContainerId,
      async (verifier) => {
        try {
          const { verificationId } = await AuthService.signInWithPhoneNumber(
            phoneNumber,
            verifier
          );

          onSuccess?.(verificationId);
        } catch (e) {
          console.error(e);
          _verifier.clear();
        }
      }
    );
    await _verifier.verify();
  } catch (e) {
    throw e;
  }
};

export const registerPhone = async ({
  email,
  password,
  recaptchaRef,
  phoneNumber,
  onSMSSent,
  changeRecaptchaKey,
}) => {
  // reauthenticate the user
  try {
    if (password) {
      const credential = EmailAuthProvider.credential(email, password);
      const newUserCred = await reauthenticateWithCredential(
        AuthService.auth.currentUser,
        credential
      );
    }
    // setting the recaptcha verifier
    const recaptchaVerifier = new RecaptchaVerifier(
      recaptchaRef.current,
      {
        size: "invisible",
        callback: async function (response) {
          // reCAPTCHA solved, you can proceed with
          const multiFactorSession = await multiFactor(
            AuthService.auth.currentUser
          ).getSession();
          const phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: multiFactorSession,
          };
          const phoneAuthProvider = new PhoneAuthProvider(AuthService.auth);
          try {
            const verificationId = await phoneAuthProvider.verifyPhoneNumber(
              phoneInfoOptions,
              recaptchaVerifier
            );

            onSMSSent && onSMSSent({ verificationId, phoneNumber });
          } catch (e) {
            console.log(`e`, e);
            recaptchaVerifier.clear();
            changeRecaptchaKey && changeRecaptchaKey();

            // wrong or invalid number try again
          }
        },
      },
      AuthService.auth
    );

    const recaptchaWidgetId = await recaptchaVerifier.render();
    await recaptchaVerifier.verify();
  } catch (e) {
    console.log(`e`, e);
    changeRecaptchaKey && changeRecaptchaKey();
  }
};
