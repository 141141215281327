import React from "react";
import styled from "styled-components/macro";
import countriesData from "../../assets/countriesData.json";

const CountryPhoneCode = ({ countryCode }) => (
  <Container>
    <FlagIcon
      src={`https://flagcdn.com/${countryCode?.toLowerCase()}.svg`}
      alt={countryCode}
    />

    <span>+{countriesData[countryCode].phone}</span>
  </Container>
);

const Container = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  color: #09216a;
`;

const FlagIcon = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 9px;
`;

export default CountryPhoneCode;
