import React from "react";
import styled from "styled-components/macro";
import { Description, FieldsWrapper, Header } from "../investor/styled";
import { Controller } from "react-hook-form";
import Input from "../../../Components/core/formElements/Input";
import { getFieldError } from "../../../utils/formUtils";
import { emailRegex } from "../../../consts";
import PhoneCodeDropdown from "../../../Components/core/formElements/PhoneCodeDropdown";
import Checkbox from "../../../Components/core/formElements/Checkbox";
import ClickableText from "../../../Components/core/ClickableText";

const ContactInfoStep = ({ className, control, isInvestor }) => {
  const handleUserPolicyClick = (e) => {
    e.stopPropagation();
    window.open("/pitchshow-terms-of-use.pdf");
  };

  return (
    <Container className={className}>
      <Header>Contact info</Header>

      <Description>
        Enter your personal info below to create an account
      </Description>

      <FieldsWrapper>
        <Controller
          name="fullName"
          control={control}
          rules={{ required: true }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => {
            return (
              <Input
                name="fullName"
                placeholder="Enter full name"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Full name",
                })}
              />
            );
          }}
        />

        <Controller
          name="email"
          control={control}
          rules={{ required: true, pattern: emailRegex }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => {
            return (
              <Input
                name="email"
                placeholder="Enter your email"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Email",
                })}
              />
            );
          }}
        />

        <PhoneNumberWrapper>
          <Controller
            name="phoneCode"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <StyledPhoneCodeDropdown
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Code",
                  })}
                />
              );
            }}
          />

          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                name="phoneNumber"
                placeholder="Enter number"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isNumeric
                isInteger
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Phone number",
                })}
              />
            )}
          />
        </PhoneNumberWrapper>

        <Controller
          name="isAgreedWithPolicy"
          control={control}
          rules={{ required: true }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <Checkbox
              label={
                <span>
                  I have read and agree to Pitchshow’s
                  <UserPolicy onClick={handleUserPolicyClick}>
                    User Policy
                  </UserPolicy>
                </span>
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={getFieldError({
                error,
                fieldLabel: "Checkbox",
              })}
            />
          )}
        />

        {isInvestor && (
          <Controller
            name="notUSResident"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Checkbox
                label={
                  <span>
                    I declare that <b>I am not a US resident</b>
                  </span>
                }
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Checkbox",
                })}
              />
            )}
          />
        )}
      </FieldsWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const StyledPhoneCodeDropdown = styled(PhoneCodeDropdown)`
  width: 128px;
  margin-right: 10px;
  flex-shrink: 0;
`;

const UserPolicy = styled(ClickableText)`
  color: #021652;
  margin-left: 5px;
  text-decoration: underline;
`;

export default ContactInfoStep;
