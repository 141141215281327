import React from "react";
import Navbar from "../../../Components/Section/Navbar";
import Footer from "../../../Components/Section/Footer";
import {
  Content,
  Description,
  Filter,
  FilterBy,
  PageContainer,
  RowsWrapper,
  SearchWrapper,
  StyledBreadcrumbs,
  StyledFiltersArea,
  Title,
  TitleWrapper,
  TopElements,
} from "../shared";
import { useDispatch, useSelector } from "react-redux";
import PageSearch from "../../../Components/core/PageSearch";
import { setSearch } from "../../../store/search/actions";
import { buttonVariants, projectFilterTypes } from "../../../consts";
import CollapsibleRow from "../CollapsibleRow";
import { filter, map } from "lodash";
import { useInvestments } from "../../../hooks/useInvestments";
import { getProjectOpenStatusMessage } from "../../../utils/projectUtils";
import {
  normalizeDollarAmount,
  normalizeNumber,
} from "../../../utils/normalizations";
import { ShowMoreButton } from "../../launchpad/shared";
import { useClientPagination } from "../../../hooks/useClientPagination";
import config from "../../../assets/config.json";
import { useProjectSearchAndFilters } from "../../../hooks/useProjectSearchAndFilters";
import { searchSelector } from "../../../store/selectors/search";
import { useInvestor } from "../../../utils/api/queries/useInvestor";
import { toLower } from "lodash-es";

const Investments = () => {
  const dispatch = useDispatch();

  const { data: investments } = useInvestments();
  const { data: investor } = useInvestor({
    select: (response) => response.roleData,
  });

  const { filteredData } = useProjectSearchAndFilters({
    originalData: investments,
    searchFields: [
      "projectData.projectName",
      "projectData.currentStage",
      "projectData.listingStockExchange",
    ],
    projectDataPath: "projectData",
  });

  const { slicedData, viewNextPage, isHasMore } = useClientPagination({
    pageSize: 9,
    allData: filteredData,
  });

  const search = useSelector(searchSelector);

  const fullName = investor?.contactInfo?.fullName;

  const breadcrumbsLinks = [
    { text: "My profile", url: "/account" },
    { text: "My investments", url: "/account/investments" },
  ];

  const getFieldValuesArray = (investment) => {
    const { projectData, amount } = investment;
    const {
      fundingType,
      currentStage,
      totalRaised,
      totalRaising,
      currentValuation,
      listingStockExchange,
      tokenPrice,
      symbol,
      totalSupply,
      finalPrice,
    } = projectData;

    const percentage = ((amount / totalRaising) * 100).toFixed(2);
    const sharesAmount = amount / finalPrice;

    switch (toLower(fundingType)) {
      case "equity":
        return [
          {
            field: "Status",
            value: getProjectOpenStatusMessage(projectData),
            isInitiallyShowed: true,
          },
          {
            field: "Investment amount",
            value: normalizeDollarAmount(amount),
            isInitiallyShowed: true,
          },
          {
            field: "My percentage",
            value: `${percentage}%`,
            isInitiallyShowed: true,
          },
          {
            field: "Purchase price",
            value: normalizeDollarAmount(finalPrice),
            isInitiallyShowed: true,
          },
          {
            field: "Current stage",
            value: currentStage,
            isInitiallyShowed: true,
            isInBadge: true,
          },
          {
            field: "My number of shares",
            value: normalizeNumber(sharesAmount),
          },
          {
            field: "Total raising",
            value: normalizeDollarAmount(totalRaising),
          },
          {
            field: "Raised to date",
            value: normalizeDollarAmount(totalRaised),
          },
          {
            field: "Current Valuation",
            value: normalizeDollarAmount(currentValuation),
          },
          {
            field: "Listing Stock Exchange",
            value: listingStockExchange,
            isMobileFullLine: true,
          },
        ];

      case "token":
        return [
          {
            field: "Status",
            value: getProjectOpenStatusMessage(projectData),
            isInitiallyShowed: true,
          },
          {
            field: "Investment amount",
            value: normalizeDollarAmount(amount),
            isInitiallyShowed: true,
          },
          {
            field: "Number of tokens",
            value: normalizeNumber(amount / tokenPrice),
            isInitiallyShowed: true,
          },
          {
            field: "Symbol",
            value: symbol,
            isInitiallyShowed: true,
            isInBadge: true,
          },
          {
            field: "Total raising",
            value: normalizeDollarAmount(totalRaising),
          },
          {
            field: "Raised to date",
            value: normalizeDollarAmount(totalRaised),
          },
          {
            field: "Total supply",
            value: normalizeNumber(totalSupply),
          },
          {
            field: "Token price",
            value: normalizeDollarAmount(tokenPrice),
          },
        ];

      default:
        return [];
    }
  };

  const renderInvestments = () => (
    <RowsWrapper>
      {map(slicedData, (investment) => {
        const { projectData, id } = investment;
        const { logo, projectName, website } = projectData;

        const buttonConfig = {
          variant: buttonVariants.primary,
          onClick: () => {
            window.open(website);
          },
          content: "Project page",
        };

        const fieldValuesArray = getFieldValuesArray(investment);

        return (
          <CollapsibleRow
            key={id}
            logo={logo}
            name={projectName}
            buttonConfig={buttonConfig}
            fieldValuesArray={fieldValuesArray}
          />
        );
      })}
    </RowsWrapper>
  );

  return (
    <PageContainer>
      <Navbar status={"account"} />

      <Content>
        <StyledBreadcrumbs linksArray={breadcrumbsLinks} />
        <TopElements>
          <TitleWrapper>
            <Title>My investments</Title>
            <Description>
              Viewing investments information of <b>{fullName}</b>
            </Description>
          </TitleWrapper>

          <SearchWrapper>
            <PageSearch
              value={search}
              onChange={(newValue) => dispatch(setSearch(newValue))}
              placeholder="Search by keywords..."
            />

            <Filter>
              <FilterBy>Filter by</FilterBy>

              <StyledFiltersArea
                filterTypes={{
                  [projectFilterTypes.projectStatus]: {
                    options: filter(
                      config.projectStatusOptions,
                      ({ value }) => value !== "upcoming"
                    ),
                  },
                  [projectFilterTypes.typeOfInvestment]: null,
                }}
              />
            </Filter>
          </SearchWrapper>
        </TopElements>

        {renderInvestments()}

        {isHasMore && (
          <ShowMoreButton onClick={viewNextPage}>Show More</ShowMoreButton>
        )}
      </Content>

      <Footer />
    </PageContainer>
  );
};

export default Investments;
