import React from "react";
import { BackToSignIn, ModalContent, ModalTitle, SubmitButton } from "./shared";
import styled from "styled-components/macro";
import { Controller, useForm } from "react-hook-form";
import ModalInput from "../../../core/formElements/ModalInput";
import { buttonVariants, modalTypes, passwordRegex } from "../../../../consts";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../store/modal/actions";
import PasswordExplanation from "./PasswordExplanation";
import { useMutation } from "@tanstack/react-query";
import { confirmPasswordReset } from "../../../../utils/api/mutations";

const ResetPasswordModal = ({ oobCode }) => {
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation({
    mutationFn: confirmPasswordReset,
    onSuccess: () =>
      dispatch(openModal({ type: modalTypes.resetPasswordSubmittedModal })),
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = ({ password }) => {
    mutate({ password, oobCode });
  };

  const handleSignIn = () => {
    dispatch(openModal({ type: modalTypes.signInModal }));
  };

  return (
    <ModalContent>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledModalTitle>Reset Password</StyledModalTitle>

        <InputsWrapper>
          <Password>
            <Controller
              name="password"
              control={control}
              rules={{ required: true, pattern: passwordRegex }}
              render={({ field: { value, onChange } }) => (
                <ModalInput
                  value={value}
                  onChange={onChange}
                  label="New password"
                  type="password"
                />
              )}
            />

            <PasswordExplanation />
          </Password>

          <Controller
            name="repeatPassword"
            control={control}
            rules={{
              required: true,
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();

                  return password === value || "Passwords should match!";
                },
              },
            }}
            render={({ field: { value, onChange } }) => (
              <ModalInput
                value={value}
                onChange={onChange}
                label="Confirm new password"
                type="password"
              />
            )}
          />
        </InputsWrapper>

        <SubmitButton
          variant={buttonVariants.primary}
          type="submit"
          disabled={!isValid || isLoading}
        >
          Submit
        </SubmitButton>

        <StyledBackToSignIn onClick={handleSignIn}>
          Back to Sign in
        </StyledBackToSignIn>
      </Form>
    </ModalContent>
  );
};

const Form = styled.form``;

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 67px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    gap: 18px;
  }
`;

const Password = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBackToSignIn = styled(BackToSignIn)`
  margin-top: 25px;
`;

export default ResetPasswordModal;
