import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../store/search/actions";
import { searchSelector } from "../store/selectors/search";
import { getFuzzySearchResults } from "../utils/projectUtils";

export const useFuzzySearch = ({ originalData, searchFields }) => {
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);

  const filteredData = useMemo(
    () =>
      getFuzzySearchResults({
        originalData,
        searchFields,
        inputValue: search,
      }),
    [search, originalData]
  );

  useEffect(() => {
    return () => {
      dispatch(setSearch(""));
    };
  }, []);

  return { filteredData };
};
