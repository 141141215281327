import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../consts";
import { DALServiceV2 } from "../../../services/data-access-layer.service";
import { useUserDBData } from "./useUserDBData";
import { forEach, map } from "lodash-es";
import { useSelector } from "react-redux";
import { projectsListSelector } from "../../../store/selectors/projects";

export const useUserProjects = (queryOptions = {}) => {
  const { data } = useUserDBData();
  const projectsList = useSelector(projectsListSelector);
  const role = data?.role;

  return useQuery([queryKeys.userProjects, role], {
    queryFn: () => DALServiceV2.getProjects(role),
    enabled: !!role,
    select: (response) => {
      const projectIds = map(response, ({ id }) => id);
      const result = {};

      forEach(projectIds, (id) => {
        if (!!projectsList[id]) {
          result[id] = projectsList[id];
        }
      });

      return result;
    },
    ...queryOptions,
  });
};
