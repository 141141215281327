import { DALServiceV2 } from "../../services/data-access-layer.service";
import { AuthService } from "../../services/auth.service";
import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { functions } from "../db";
import { paymentMethods } from "../../consts";

export const createEquityInvestmentRequest = async ({ projectId }) =>
  DALServiceV2.createInvestmentEnquiry(projectId);

export const updateRoleProfile = async ({ role, roleData }) =>
  DALServiceV2.updateRoleProfile(role, roleData);

export const addToWatchList = async ({
  projectId,
  projectCreatorId,
  investorData,
}) => DALServiceV2.addToWatchList(projectCreatorId, projectId, investorData);

export const removeFromWatchList = async ({ projectId }) =>
  DALServiceV2.removeFromWatchList(projectId);

export const createUserWithEmailAndPassword = async ({
  email,
  password,
  role,
}) => {
  const user = await AuthService.createUserWithEmailAndPassword(
    email,
    password
  );
  await AuthService.sendEmailVerification();
  setTimeout(async () => {
    try {
      await DALServiceV2.setUserData({ initialRole: role });
    } catch (e) {
      console.error(e);
    }
  }, 1000);
};

export const signInWithEmailAndPassword = async ({
  email,
  afterEmailVerification,
  password,
  verifier,
}) =>
  AuthService.signInWithEmailAndPassword(
    email,
    password,
    verifier,
    afterEmailVerification
  );

export const verifyCode = async ({ verificationId, phoneNumber, code }) => {
  const cred = PhoneAuthProvider.credential(verificationId, code);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

  await multiFactor(AuthService.auth.currentUser).enroll(
    multiFactorAssertion,
    phoneNumber
  );

  await DALServiceV2.setUserData({ phoneNumber });
};

export const verifyCodeSent = async ({ phoneNumber, code, verificationId }) =>
  AuthService.verifyCodeSent(phoneNumber, code, verificationId);

export const sendPasswordResetEmail = async ({ email }) =>
  AuthService.sendPasswordResetEmail(email);

export const confirmPasswordReset = async ({ oobCode, password }) =>
  AuthService.confirmPasswordReset(oobCode, password);

export const registerPhone = async ({
  phoneNumber,
  verifier,
  twoFaAlreadyEnabled,
}) => AuthService.registerPhone(phoneNumber, verifier, twoFaAlreadyEnabled);

export const signInWithEmailAndPassword2Fa = async ({
  verificationId,
  code,
  resolver,
}) => AuthService.signInWithEmailAndPassword2Fa(verificationId, code, resolver);

export const setRoleProfile = async ({ role, roleData }) => {
  await DALServiceV2.setRoleProfile(role, roleData);
  await DALServiceV2.setUserData({ role });
};

export const createCompanyProject = async ({ data }) =>
  DALServiceV2.createCompanyProject(data);

export const createConsultantProject = async ({ data }) =>
  DALServiceV2.createConsultantProject(data);

export const setUserData = async (data) => DALServiceV2.setUserData(data);

export const makePayment = async ({
  paymentMethodId,
  projectId,
  amount,
  intent,
  returnUrl,
  paymentMethod,
  successUrl,
  errorUrl,
}) => {
  const callableReturnMessage = httpsCallable(functions, "makePayment");

  return callableReturnMessage({
    paymentMethod,
    data: {
      paymentMethodId,
      projectId,
      amount,
      currency: "usd",
      returnUrl,
      successUrl,
      errorUrl,
    },
    intent,
  });
};
