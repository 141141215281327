import { DALServiceV2 } from "../../../services/data-access-layer.service";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../consts";

export const useCompany = (queryOptions = {}) =>
  useQuery(
    [queryKeys.company],
    () => DALServiceV2.getCompanyProfile(),
    queryOptions
  );
