import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { clearUserData, setUserData } from "../store/app/actions";
import { signInWithGoogle } from "../services/sign-in-service";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth as Auth } from "../utils/db";
import { AuthService } from "../services/auth.service";
import { openModal } from "../store/modal/actions";
import { modalTypes } from "../consts";
import { useQueryClient } from "@tanstack/react-query";

export const useAuthenticated = () => {
  const [user, loading] = useAuthState(Auth);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const isAuthenticated =
    user?.emailVerified && AuthService.isMultiFactorEnabled();
  const isEmailVerified = useMemo(() => AuthService.isEmailVerified(), [user]);
  const mfaEnabled = useMemo(() => AuthService.isMultiFactorEnabled(), [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(Auth, async (user) => {
      if (user) {
        const {
          uid: id,
          accessToken,
          displayName,
          email,
          emailVerified,
          photoURL,
        } = user;
        try {
          dispatch(setUserData(user));
        } catch (e) {
          dispatch(
            setUserData({
              id,
              accessToken,
              displayName,
              email,
              emailVerified,
              photoURL,
            })
          );
        }
        // setAuth(true);
      } else {
        dispatch(clearUserData());
        // setAuth(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const signOut = async () => {
    try {
      await AuthService.auth?.currentUser?.auth?.signOut();
      queryClient.clear();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const signIn = async (recaptchaVerifier) => {
    try {
      const data = await signInWithGoogle(recaptchaVerifier);
      const { is2faActive, phoneNumber, resolver, verificationId, userData } =
        data || {};

      if (!is2faActive) {
        dispatch(
          openModal({
            type: modalTypes.enterPhoneNumber,
          })
        );
        dispatch(setUserData(userData));
      } else {
        dispatch(
          openModal({
            type: modalTypes.phoneCodeModal,
            phoneNumber,
            resolver,
            verificationId,
          })
        );
      }
    } catch (e) {
      // TODO: error handling
      console.error(e);
      dispatch(clearUserData());
      throw e;
    }
  };

  return {
    signIn,
    signOut,
    user,
    loading,
    isEmailVerified,
    mfaEnabled,
    isAuthenticated,
  };
};
