import { auth as _auth } from "../utils/db";
import {
  reauthenticateWithCredential as _reauthenticateWithCredential,
  confirmPasswordReset as _confirmPasswordReset,
  createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
  PhoneAuthProvider,
  signInWithPhoneNumber as _signInWithPhoneNumber,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendEmailVerification as _sendEmailVerification,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  updatePassword as _updatePassword,
  verifyPasswordResetCode as _verifyPasswordResetCode,
  applyActionCode as _applyActionCode,
  multiFactor,
  getMultiFactorResolver,
  fetchSignInMethodsForEmail as __fetchSignInMethodsForEmail,
  EmailAuthProvider,
} from "firebase/auth";

export const SignupEmailPasswordErrors = Object.freeze({
  EMAIL_IN_USE: "email-already-in-use",
  INVALID_EMAIL: "auth/invalid-email",
  WEAK_PASSWORD: "auth/weak-password",
});

export const SigninEmailPasswordErrors = Object.freeze({
  USER_NOT_FOUND: "auth/user-not-found",
  WRONG_PASSWORD: "auth/wrong-password",
  EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
});

export class AuthService {
  static auth = _auth;

  static async signInWithPhoneNumber(phoneNumber, appVerifier) {
    try {
      const confirmationResult = await _signInWithPhoneNumber(
        this.auth,
        phoneNumber,
        appVerifier
      );
      window.confirmationResult = confirmationResult;
      return confirmationResult;
    } catch (e) {
      appVerifier.clear();
      window.confirmationResult = "";
      throw e;
    }
  }

  static async sendEmailVerification() {
    try {
      await _sendEmailVerification(this.auth.currentUser);
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async createUserWithEmailAndPassword(email, password) {
    try {
      const user = await _createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      return user.user;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async sendPasswordResetEmail(email) {
    try {
      await _sendPasswordResetEmail(this.auth, email);
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: call this as the second function during the login
  static async signInWithEmailAndPassword2Fa(
    verificationId,
    verificationCode,
    resolver
  ) {
    try {
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      // Complete sign-in.
      const res = await resolver.resolveSignIn(multiFactorAssertion);
      return res;
    } catch (e) {
      throw e;
    }
  }

  // NOTE: call this as the first function during the login
  static async signInWithEmailAndPassword(
    email,
    password,
    recaptchaVerifier,
    afterEmailVerification = false
  ) {
    try {
      if (afterEmailVerification) {
        await this.reauthenticateUser(password);
        return;
      }
      const data = await _signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      if (!this.auth?.currentUser?.emailVerified) {
        throw { code: SigninEmailPasswordErrors.EMAIL_NOT_VERIFIED };
      }
      return data.user;
    } catch (e) {
      console.error(e);
      const { code } = e;
      let phoneNumber;
      // TODO: handle email in use error
      if (code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(this.auth, e);
        phoneNumber = resolver.hints[0].displayName;
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        const phoneAuthProvider = new PhoneAuthProvider(this.auth);
        // Send SMS verification code
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
        return {
          phoneNumber,
          verificationId,
          resolver,
          is2faActive: true,
        };
      } else if (code === "auth/wrong-password") {
        throw code;
      } else {
        throw e.code;
      }
    }
  }

  static async applyActionCode(code) {
    try {
      await _applyActionCode(this.auth, code);
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async updatePassword(newPassword) {
    try {
      await _updatePassword(this.auth.currentUser, newPassword);
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: use this function as the useEffect of the /forgot-password page, extract the oobCode from the query param and apply this function
  static async verifyPasswordResetCode(oobCode) {
    try {
      const res = await _verifyPasswordResetCode(this.auth, oobCode);
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async confirmPasswordReset(oobCode, newPassword) {
    try {
      await _confirmPasswordReset(this.auth, oobCode, newPassword);
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: user solves a captcha
  static recaptchaVerifier(containerId, recaptchaSolvedCB) {
    let verifier;
    try {
      window.recaptchaVerifier = verifier = new RecaptchaVerifier(
        containerId,
        {
          size: "invisible",
          callback: (e) => {
            recaptchaSolvedCB(verifier);
          },
        },
        this.auth
      );
      return verifier;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: user adds his phone as the second auth factor
  static async registerPhone(
    phoneNumber,
    verifier,
    twoFaAlreadyEnabled = true
  ) {
    try {
      let session;
      const phoneOpts = {
        phoneNumber,
      };
      if (!twoFaAlreadyEnabled) {
        session = await multiFactor(this.auth?.currentUser).getSession();
        phoneOpts.session = session;
      }

      const phoneAuthProvider = new PhoneAuthProvider(this.auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneOpts,
        verifier
      );
      return verificationId;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  // NOTE: verify the code sent to the phone on the first time
  static async verifyCodeSent(phoneNumber, code, verificationId) {
    try {
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(this.auth.currentUser).enroll(
        multiFactorAssertion,
        phoneNumber
      );
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async verifyPhoneNumber() {
    try {
      const phoneOpts = {
        multiFactorHint: window.resolver.hints[0],
        session: window.resolver.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider();

      window.verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneOpts,
        window.recaptchaVerifier
      );
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async resolveSignIn(code) {
    try {
      const cred = PhoneAuthProvider.credential(window.verificationId, code);

      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      await window.resolver.resolveSignIn(multiFactorAssertion);
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static isMultiFactorEnabled() {
    try {
      const userMF = multiFactor(this.auth?.currentUser);
      return userMF ? !!userMF?.enrolledFactors[0] : false;
    } catch (e) {
      return false;
    }
  }

  static isEmailVerified() {
    try {
      return !!this.auth?.currentUser?.emailVerified;
    } catch (e) {
      throw e;
    }
  }

  static async _fetchSignInMethodsForEmail(email) {
    try {
      // ["password"]
      return await __fetchSignInMethodsForEmail(this.auth, email);
    } catch (e) {
      throw e;
    }
  }

  static async fetchSignInMethodsFor(email) {
    try {
      if (this.auth?.currentUser) {
        const methods = await this._fetchSignInMethodsForEmail(email);
        if (!methods.includes(PhoneAuthProvider.PHONE_SIGN_IN_METHOD)) {
          const phoneProvider = this.auth.currentUser?.providerData?.find(
            (provider) => {
              return (
                provider &&
                provider.providerId === PhoneAuthProvider.PROVIDER_ID
              );
            }
          );
          if (phoneProvider) {
            methods.push(PhoneAuthProvider.PHONE_SIGN_IN_METHOD);
          }
        }
        return methods;
      } else {
        throw [];
      }
    } catch (e) {
      return e;
    }
  }

  static async reauthenticateUser(password) {
    try {
      if (this.auth.currentUser) {
        const user = this.auth.currentUser;
        const credential = EmailAuthProvider.credential(user.email, password);
        await _reauthenticateWithCredential(user, credential);
        return;
      }
    } catch (e) {
      throw e;
    }
  }
}

/**
 *
 *
 *
 */
