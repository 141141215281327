import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { requestStatus } from "../consts";

export const useStripePaymentStatus = (clientSecret) => {
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (clientSecret) {
      checkPaymentStatus();
    }
  }, [clientSecret]);

  const checkPaymentStatus = async () => {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const { paymentIntent, error } = await stripe.confirmCardPayment(
      clientSecret
    );

    if (error) {
      setStatus(requestStatus.error);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setStatus(requestStatus.success);
    }
  };

  return status;
};
