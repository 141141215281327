import { createSlice } from "@reduxjs/toolkit";
import { consultantSignUpInitialState } from "../../utils/schema";

export const consultantSignUpSlice = createSlice({
  name: "consultantSignUp",
  initialState: consultantSignUpInitialState,
  reducers: {
    setConsultantCompletedSteps: (state, { payload: completedSteps }) => ({
      ...state,
      completedSteps,
    }),

    setConsultantContactInfo: (state, { payload: contactInfo }) => ({
      ...state,
      contactInfo,
    }),

    setConsultantCompanyInfo: (state, { payload: companyInfo }) => {
      return {
        ...state,
        companyInfo,
      };
    },

    resetConsultantSignup: () => consultantSignUpInitialState,
  },
});
