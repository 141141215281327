import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";
import { filter, map } from "lodash";
import { FieldValue } from "./shared";
import Badge from "./Badge";
import { screenSizes } from "../../consts";
import { useWindowSize } from "../../hooks/useWindowSize";
import Button from "../../Components/core/Button";

const CollapsibleRow = ({
  className,
  logo,
  name,
  fieldValuesArray,
  buttonConfig,
  isCollapsible = true,
  openMobileHeight = "555px",
  openDesktopHeight = "293px",
  EndRowComponent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowSize();
  const initiallyShowedData = useMemo(
    () =>
      filter(fieldValuesArray, ({ isInitiallyShowed }) => isInitiallyShowed),
    []
  );

  const initiallyHiddenData = useMemo(
    () =>
      filter(fieldValuesArray, ({ isInitiallyShowed }) => !isInitiallyShowed),
    []
  );

  const isDesktop = width > screenSizes.large;

  if (!isDesktop) {
    return (
      <Container
        isOpen={isOpen}
        className={className}
        openMobileHeight={openMobileHeight}
      >
        <MobileContent>
          <TriggerRow
            isCollapsible={isCollapsible}
            isOpen={isOpen}
            onClick={() => isCollapsible && setIsOpen(!isOpen)}
          >
            <LogoName>
              <CircleWrapper>
                <Circle>
                  <Logo src={logo} className="logo" />
                </Circle>
              </CircleWrapper>

              <Name>{name}</Name>
            </LogoName>

            {EndRowComponent && <EndRowComponent />}

            {isCollapsible && (
              <ArrowIconWrapper>
                <ArrowIcon src="/img/arrow.svg" isOpen={isOpen} />
              </ArrowIconWrapper>
            )}
          </TriggerRow>

          <GridFields className="grid">
            {map(
              fieldValuesArray,
              ({ field, value, isMobileFullLine, isLink }) => {
                if (isLink) {
                  return (
                    <Link key={field} onClick={() => window.open(value)}>
                      {field}
                    </Link>
                  );
                }

                return (
                  <StyledFieldValue
                    key={field}
                    field={field}
                    value={value}
                    isVertical
                    isMobileFullLine={isMobileFullLine}
                  />
                );
              }
            )}
          </GridFields>

          {buttonConfig && (
            <StyledButton {...buttonConfig}>
              {buttonConfig.content}
            </StyledButton>
          )}
        </MobileContent>
      </Container>
    );
  }

  return (
    <Container
      isOpen={isOpen}
      className={className}
      isDesktop={isDesktop}
      openDesktopHeight={openDesktopHeight}
    >
      <CircleWrapper isOpen={isOpen}>
        <Circle>
          <Logo src={logo} className="logo" />
        </Circle>
      </CircleWrapper>

      <Content className="content">
        <TriggerRow
          isCollapsible={isCollapsible}
          isOpen={isOpen}
          onClick={() => isCollapsible && setIsOpen(!isOpen)}
        >
          <Name>{name}</Name>
          {map(initiallyShowedData, ({ field, value, isInBadge, isLink }) => {
            if (isInBadge) {
              return <Badge key={field} field={field} value={value} />;
            }

            if (isLink) {
              return (
                <Link key={field} onClick={() => window.open(value)}>
                  {field}
                </Link>
              );
            }

            return <FieldValue key={field} field={field} value={value} />;
          })}
        </TriggerRow>

        {isCollapsible && (
          <Row>
            {map(initiallyHiddenData, ({ field, value }) => (
              <FieldValue key={field} field={field} value={value} isVertical />
            ))}
          </Row>
        )}

        {buttonConfig && (
          <StyledButton {...buttonConfig}>{buttonConfig.content}</StyledButton>
        )}
      </Content>

      {EndRowComponent && <EndRowComponent />}

      {isCollapsible && (
        <ArrowIconWrapper>
          <ArrowIcon
            src="/img/arrow.svg"
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          />
        </ArrowIconWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: height 0.3s ease-in;
  height: ${({ isDesktop, isOpen, openMobileHeight, openDesktopHeight }) => {
    if (isDesktop) {
      if (isOpen) {
        return openDesktopHeight;
      }

      return "96px";
    }

    if (isOpen) {
      return openMobileHeight;
    }

    return "49px";
  }};

  ${({ isOpen }) =>
    isOpen &&
    css`
      box-shadow: 0 0 10px rgba(150, 177, 255, 0.8);
    `};

  @media (max-width: 1280px) {
    padding: 10px 11px;
    border-radius: 4px;
  }
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 33px;
`;

const CircleWrapper = styled.div`
  display: flex;
  margin-right: 27px;
  height: 100%;
  align-items: flex-start;
  @media (max-width: 1280px) {
    margin-right: 15px;
  }
`;

const Circle = styled.div`
  width: 49px;
  height: 49px;
  background: #e8eeff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  @media (max-width: 1280px) {
    width: 31px;
    height: 31px;
  }
`;

const Logo = styled.img``;

const Name = styled.div`
  color: #000f3b;
  font-weight: 500;
  font-size: 17px;

  @media (max-width: 1280px) {
    font-size: 14px;
  }
`;

const ArrowIconWrapper = styled.div`
  margin-left: 20px;
  height: 100%;
  padding-top: 20px;

  @media (max-width: 1280px) {
    padding-top: 8px;
  }
`;

const ArrowIcon = styled.img`
  width: 8px;
  height: 16px;
  cursor: pointer;
  transition: transform 0.3s ease-in;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(90deg);
    `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TriggerRow = styled(Row)`
  align-self: flex-start;

  ${({ isCollapsible }) =>
    isCollapsible &&
    css`
      cursor: pointer;
    `}
`;

const StyledButton = styled(Button)`
  height: 56px;
  width: 153px;
  margin-left: auto;

  @media (max-width: 1280px) {
    height: 45px;
    margin: auto;
  }
`;

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const GridFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 25px 8px;
  row-gap: 20px;
`;

const StyledFieldValue = styled(FieldValue)`
  ${({ isMobileFullLine }) =>
    isMobileFullLine &&
    css`
      grid-column: 1/-1;
    `}
`;

const LogoName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Link = styled.div`
  color: #000f3c;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1280px) {
    font-size: 15px;
    grid-column: 2;
  }
`;

export default CollapsibleRow;
