import styled from "styled-components/macro";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 473px;
  padding-bottom: 106px;
`;

export const Title = styled.div`
  font-size: 23px;
  font-weight: 600;
  text-align: center;
  color: #021652;
`;

export const Description = styled.div`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: #021652;
  margin-bottom: 30px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SectionTitle = styled.div`
  color: #071b56;
  font-weight: 500;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
