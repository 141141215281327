import React, { useState } from "react";
import { ModalContent, ModalText, ModalTitle, SubmitButton } from "../shared";
import styled from "styled-components/macro";
import { buttonVariants, queryKeys } from "../../../../../consts";
import { useDispatch } from "react-redux";
import ClickableText from "../../../../core/ClickableText";
import { useNavigate } from "react-router-dom";
import { closeModal } from "../../../../../store/modal/actions";
import RoleCard from "./RoleCard";
import { useFilledRoles } from "../../../../../utils/api/queries/useFilledRoles";
import { map } from "lodash-es";
import { useUserDBData } from "../../../../../utils/api/queries/useUserDBData";
import { setUserData } from "../../../../../utils/api/mutations";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const ChooseUserModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: filledRoles } = useFilledRoles();
  const { data } = useUserDBData();

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: setUserData,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.userDBData]);
    },
  });

  const role = data?.role;
  const [selectedRole, setSelectedRole] = useState(role);

  const handleSwitchAccount = () => {
    mutate({ role: selectedRole });
    dispatch(closeModal());
    navigate("/");
  };

  const handleAddNewProfile = () => {
    navigate("/sign-up");
    dispatch(closeModal());
  };

  return (
    <ModalContent>
      <StyledModalTitle>Choose User</StyledModalTitle>

      <Description>Choose the user you want and switch account</Description>

      <Roles>
        {map(filledRoles, (roleObj, key) => (
          <RoleCard
            key={key}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            role={key}
            name={roleObj?.contactInfo?.fullName}
          />
        ))}
      </Roles>

      <SubmitButton
        variant={buttonVariants.primary}
        onClick={handleSwitchAccount}
        disabled={selectedRole === role}
      >
        Switch Account
      </SubmitButton>

      <AddNewProfile onClick={handleAddNewProfile}>
        Add New Profile
      </AddNewProfile>
    </ModalContent>
  );
};

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 15px;
`;

const Description = styled(ModalText)`
  margin-bottom: 45px;
`;

const AddNewProfile = styled(ClickableText)`
  font-size: 16px;
  font-weight: 600;
  margin: 24px auto 0 auto;
`;

const Roles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
`;

export default ChooseUserModal;
