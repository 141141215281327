import React from "react";
import styled from "styled-components/macro";
import HorizontalProgressLine from "../../Components/core/HorizontalProgressLine";
import { CardRow, Logo, Name } from "./shared";
import { normalizeDollarAmount } from "../../utils/normalizations";
import { useNavigate } from "react-router-dom";
import { useAuthenticated } from "../../hooks/useAuthenticated";

const OpenProject = ({
  logo,
  name,
  totalRaised,
  maxAllocation,
  fillPercent,
  projectId,
}) => {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuthenticated();

  return (
    <Container
      isClickable={isAuthenticated}
      onClick={() => isAuthenticated && navigate(`/company-page/${projectId}`)}
    >
      <Card>
        <LogoWrapper>
          <Logo src={logo} />
        </LogoWrapper>

        <Name>{name}</Name>

        <DataRows>
          <CardRow
            field="Total Raised"
            value={normalizeDollarAmount(totalRaised)}
          />
          <CardRow field="Max Allocation" value={maxAllocation} />
        </DataRows>
      </Card>

      <StyledHorizontalProgressLine
        fillPercent={fillPercent}
        backColor="white"
        fillColor="#FF2853"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 100px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  height: 293px;
  padding: 57px 27px 27px 27px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1920px) {
    height: 224px;
    padding: 44px 20px 20px 20px;
  }

  @media (max-width: 768px) {
    height: 123px;
    padding: 24px 11px 11px 11px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHorizontalProgressLine = styled(HorizontalProgressLine)`
  height: 14px;

  @media (max-width: 1920px) {
    height: 8px;
  }

  @media (max-width: 768px) {
    height: 4px;
  }
`;

const DataRows = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

export default OpenProject;
