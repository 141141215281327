import { filtersSlice } from "./slice";
const { actions: slice } = filtersSlice;

export const setFilter =
  ({ type, data }) =>
  (dispatch) => {
    dispatch(slice.setFilter({ type, data }));
  };

export const clearFilters = () => (dispatch) => {
  dispatch(slice.clearFilters());
};
