import { appSlice } from "./slice";
const { actions: slice } = appSlice;

export const setUserData = (userData) => async (dispatch) => {
    dispatch(slice.setUserData(userData));
}

export const clearUserData = () => async (dispatch) => {
    dispatch(slice.clearUserData());
}
