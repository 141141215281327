import React, { useEffect } from "react";
import styled from "styled-components/macro";
import DatePicker from "../../../Components/core/formElements/DatePicker";
import { getFieldError } from "../../../utils/formUtils";
import { Controller } from "react-hook-form";
import config from "../../../assets/config.json";

import Input from "../../../Components/core/formElements/Input";
import Dropdown from "../../../Components/core/formElements/dropdown/Dropdown";
import { InputsWrapper, SectionTitle } from "../company/styled";
import MultipleInputs from "./MultipleInputs";

const LastRaiseInfo = ({ className, control, watch, setValue }) => {
  const typeOfInvestors = watch("typeOfInvestors")?.value;

  useEffect(() => {
    if (!typeOfInvestors) {
      setValue("investors", []);
    }
  }, [typeOfInvestors]);

  const renderMultipleInputs = () => {
    if (typeOfInvestors === "Angel") {
      return (
        <StyledMultipleInputs
          title="Name top 3 Angels:"
          amount={3}
          control={control}
          formFieldName="investors"
          inputPlaceholder="Angel"
        />
      );
    } else if (typeOfInvestors === "Venture Capital List") {
      return (
        <StyledMultipleInputs
          title="Name top 3 Venture Capital funds:"
          amount={3}
          control={control}
          formFieldName="investors"
          inputPlaceholder="Venture Capital Fund"
        />
      );
    }

    return null;
  };

  return (
    <Container className={className}>
      <Title>Last raise info</Title>

      <InputsWrapper>
        <Controller
          name="lastRaiseDate"
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <DatePicker
              placeholder="Date"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={getFieldError({
                error,
                fieldLabel: "Date",
              })}
            />
          )}
        />

        <Controller
          name="lastRaiseAmount"
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => {
            return (
              <Input
                placeholder="Amount (USD)"
                isNumeric
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Amount",
                })}
              />
            );
          }}
        />

        <Controller
          name="typeOfInvestors"
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <Dropdown
              placeholder="Type of investors"
              options={config.shared.typeOfInvestors}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={getFieldError({
                error,
                fieldLabel: "Type",
              })}
            />
          )}
        />
      </InputsWrapper>

      {renderMultipleInputs()}
    </Container>
  );
};

const Container = styled.div``;

const Title = styled(SectionTitle)`
  margin-bottom: 15px;
`;

const StyledMultipleInputs = styled(MultipleInputs)`
  margin-top: 22px;
`;

export default LastRaiseInfo;
