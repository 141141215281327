import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as Icon } from "../../../assets/v-icon.svg";

const DisplayFile = ({ name }) => (
  <Container>
    <Circle>
      <StyledIcon />
    </Circle>

    <FileName>{name}</FileName>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-all;
  padding: 10px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

const Circle = styled.div`
  width: 46px;
  height: 46px;
  background: #ffffff;
  box-shadow: 0 4px 5px 3px rgba(88, 128, 255, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 34px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
    margin: 0 5px 0 0;
  }
`;

const StyledIcon = styled(Icon)`
  fill: #ff2853;
  width: 16px;
  height: 12px;

  @media (max-width: 768px) {
    width: 8px;
    height: 6px;
  }
`;

const FileName = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #09216a;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

export default DisplayFile;
