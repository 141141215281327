import { queryKeys } from "../consts";
import { useQuery } from "@tanstack/react-query";
import { getCountryAndPhoneCode } from "../services/country-service";
import { find, map } from "lodash-es";
import countriesData from "../assets/countriesData.json";

const options = map(countriesData, ({ phone, code }) => ({
  label: `+${phone}`,
  value: `+${phone}`,
  meta: { code },
}));

export const useDefaultCountryCode = () =>
  useQuery([queryKeys.defaultCountryCode], getCountryAndPhoneCode, {
    select: (result) =>
      find(options, {
        value: `+${result?.phoneCode}`,
      }),
  });
