import React from "react";
import styled from "styled-components/macro";

const Badge = ({ className, field, value }) => (
  <Container className={className}>
    {field && <Field>{`${field} :`}</Field>}
    <Value>{value}</Value>
  </Container>
);

const Container = styled.div`
  width: 257px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0 0 5px 2px rgba(160, 184, 255, 0.6);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000f3c;
  font-weight: 500;
  font-size: 17px;
`;

const Field = styled.div`
  margin-right: 5px;
`;

const Value = styled.div`
  font-weight: 600;
`;

export default Badge;
