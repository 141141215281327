import { createSlice, current } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";

export const projectSlice = createSlice({
  name: "projects",
  initialState: {
    projectsList: [],
  },
  reducers: {
    setProjects: (state, action) => {
      return {
        ...state,
        projectsList: action.payload,
      };
    },
    updateProject: (state, action) => {
      const { projectId, project } = action.payload;
      const projectsList = [...current(state.projectsList)];

      const currentProjectIndex = findIndex(projectsList, { id: projectId });
      projectsList[currentProjectIndex] = project;

      return {
        ...state,
        projectsList,
      };
    },
  },
});
