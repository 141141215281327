import React from "react";
import { useNavigate } from "react-router-dom";

const Model = () => {
  const navigate = useNavigate();

  return (
    <div className="model-section">
      <div className="model-section-content">
        <div className="model-img">
          <img src="/img/computer.svg" alt="" />
          <img src="/img/animation.gif" alt="" />
        </div>
        <h2 className="main-title">Our IDO Model</h2>
        <p>Simpler, Safer and effective</p>
      </div>

      <button className="main-btn" onClick={() => navigate("/launchpad")}>
        View Launchpad
      </button>
    </div>
  );
};

export default Model;
