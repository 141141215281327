import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { normalizeDollarAmount } from "../../utils/normalizations";
import { filter } from "lodash-es";
import { getProjectOpenStatus } from "../../utils/projectUtils";
import { projectOpenStatuses } from "../../consts";

const RecentProject = () => {
  const navigate = useNavigate();
  const { projects } = useSelector((state) => state);
  const { projectsList } = projects;

  let finishedProjects = filter(
    projectsList,
    (project) => getProjectOpenStatus(project) === projectOpenStatuses.closed
  );

  if (!finishedProjects?.length) {
    return null;
  }

  return (
    <div className="recent-project">
      <div className="d-flex justify-content-center aling-items-center">
        <h2 className="main-title">Recent projects</h2>
      </div>
      {finishedProjects.length > 0 && finishedProjects.length && (
        <div className="recent-project-table">
          <table>
            <thead>
              <tr>
                <th>Project</th>
                <th>total raise</th>
                <th>closing date</th>
              </tr>
            </thead>
            <tbody>
              {finishedProjects.length > 0 &&
                finishedProjects.slice(0, 4).map((item, index) => {
                  const {
                    logo,
                    projectName,
                    fundingType,
                    totalRaisedTillDate,
                    endTime,
                  } = item;

                  return (
                    <tr
                      key={index}
                      onClick={() => navigate(`/company-page/${item.id}`)}
                    >
                      <td>
                        <div>
                          <div className="recent-project-img-wrapper">
                            <img src={logo} alt="" />
                          </div>
                          <span>{projectName}</span>
                        </div>
                      </td>
                      <td>{normalizeDollarAmount(totalRaisedTillDate)}</td>
                      <td>{moment(endTime).format("DD/MM/YYYY HH:MM:SS")}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {!finishedProjects.length && (
        <div>
          <h5 className="text-capitalize text-center py-5">coming soon...</h5>
        </div>
      )}
    </div>
  );
};

export default RecentProject;
