import React, { useEffect } from "react";
import styled from "styled-components/macro";
import Congratulations from "./Congratulations";
import Navbar from "./Section/Navbar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../consts";

const DataSubmitted = ({ mutation, requestData }) => {
  const queryClient = useQueryClient();

  const { isSuccess, mutate } = useMutation({
    mutationFn: mutation,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.userDBData]);
      queryClient.invalidateQueries([queryKeys.filledRoles]);
    },
  });

  useEffect(() => {
    mutate(requestData);
  }, []);

  return (
    <Container isSuccess={isSuccess}>
      <Navbar status={"submit"} />

      <Content>
        {isSuccess ? (
          <Congratulations />
        ) : (
          <div>
            <Text>Please wait</Text>
            <Animation src="/img/animation5.gif" alt="" />
          </div>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  background: ${({ isSuccess }) =>
    isSuccess
      ? "linear-gradient(115.04deg, #E5EBFF 27.52%, rgba(242, 245, 255, 0) 96.92%);"
      : "#ECF1FF"};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Text = styled.div`
  font-size: 23px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  color: #021652;
  transform: translateY(90px);
`;

const Animation = styled.img`
  width: 206px;
  height: 206px;
`;

export default DataSubmitted;
