import React from "react";
import styled, { css } from "styled-components/macro";
import { roles } from "../../../../../consts";

const RoleCard = ({ name, role, setSelectedRole, selectedRole }) => (
  <Container
    onClick={() => setSelectedRole(role)}
    isSelected={selectedRole === role}
  >
    <LeftContent>
      <Circle className="circle">
        <Image src="/img/avatar.svg" />
      </Circle>

      <Name>{name}</Name>
    </LeftContent>

    <Role>{roles[role].label}</Role>
  </Container>
);

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0 0 15px #d3ddfa;
  border-radius: 8px;
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid #031855;
    `}

  @media (max-width: 768px) {
    padding: 10px 18px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Circle = styled.div`
  width: 67px;
  height: 67px;
  border-radius: 50%;
  background-color: #d3ddfa;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 27px;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }
`;

const Image = styled.img`
  height: 35px;
  width: 38px;

  @media (max-width: 768px) {
    height: 18px;
    width: 19px;
  }
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #000f3b;
  margin: auto;
`;

const Role = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #acacac;
  margin: auto 0 auto 0;

  @media (max-width: 768px) {
    font-size: 11px;
    margin: auto 0 0 5px;
  }
`;
export default RoleCard;
