import React from "react";
import { components } from "react-select";
import CountryPhoneCode from "../../CountryPhoneCode";

const SingleValuePhoneCode = (props) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <CountryPhoneCode countryCode={data?.meta?.code} />
    </components.SingleValue>
  );
};

export default SingleValuePhoneCode;
