import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import Button from "../core/Button";
import { buttonVariants, requestStatus } from "../../consts";
import { closeModal } from "../../store/modal/actions";
import { ModalContent } from "./shared";
import { useInvestor } from "../../utils/api/queries/useInvestor";

const EquityInvestingStatusModal = ({ className, status }) => {
  const dispatch = useDispatch();

  const { data: investorData } = useInvestor({
    select: (response) => response.roleData,
  });

  const { phoneCode: currentPhoneCode, phoneNumber: currentPhoneNumber } =
    investorData?.contactInfo || {};
  const fullPhoneNumber = `${currentPhoneCode?.value}${currentPhoneNumber}`;

  if (status === requestStatus.success) {
    return (
      <Container className={className}>
        <Title>
          Congratulations <br />
          Your investment has been <br />
          successfully made
        </Title>

        <Text>
          We are excited about your investment, we are currently sending you an
          investment confirmation to your email
        </Text>

        <Button
          variant={buttonVariants.primary}
          onClick={() => dispatch(closeModal())}
        >
          Close
        </Button>
      </Container>
    );
  }

  if (status === requestStatus.error) {
    return (
      <ErrorContainer className={className}>
        <Title>
          Oops, something is wrong, the <br />
          payment didn't go through <br />
          successfully
        </Title>

        <Text>
          We will get back to you within 24 hours to the phone number:{" "}
          <b>{fullPhoneNumber}</b> in order to give you all the necessary
          information and to continue the investment process securely.
        </Text>

        <Button
          variant={buttonVariants.primary}
          onClick={() => dispatch(closeModal())}
        >
          Close
        </Button>
      </ErrorContainer>
    );
  }

  return (
    <Container className={className}>
      <Title>Please wait</Title>
      <Loading src="/img/animation5.gif" />
    </Container>
  );
};

const Container = styled(ModalContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 376px;
`;

const ErrorContainer = styled(Container)`
  height: 476px;
`;

const Title = styled.div`
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 40px;
  width: 355px;
  z-index: 1;
  text-align: center;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  width: 390px;
  margin-bottom: 40px;
`;

const Loading = styled.img`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 115px;
`;

export default EquityInvestingStatusModal;
