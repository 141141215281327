export const downloadLocalFile = (fileURL) => {
  const fileName = fileURL[0] === "/" ? fileURL.slice(1) : fileURL;
  const tempLink = document.createElement("a");

  tempLink.href = fileURL;
  tempLink.setAttribute("download", fileName);
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};
