import React from "react";
import styled from "styled-components/macro";
import { buttonVariants, modalTypes } from "../consts";
import Button from "./core/Button";
import { useMobile } from "../hooks/useMobile";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../store/modal/actions";
import { modalTypeSelector } from "../store/selectors/modal";
import { useLocation } from "react-router-dom";
import { useAuthenticated } from "../hooks/useAuthenticated";
import { isEmpty } from "lodash-es";
import { useFilledRoles } from "../utils/api/queries/useFilledRoles";
import { useUserDBData } from "../utils/api/queries/useUserDBData";

const CompleteRegistrationPopup = () => {
  const { isMobile } = useMobile();
  const { isAuthenticated } = useAuthenticated();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: roles, isLoading } = useFilledRoles({
    enabled: !!isAuthenticated,
  });

  const isModalOpen =
    useSelector(modalTypeSelector) === modalTypes.completeRegistrationModal;
  const { data } = useUserDBData({ enabled: !!isAuthenticated });
  const initialRole = data?.initialRole;

  const isSignUpPage = location.pathname.split("/")[1] === "sign-up";
  const text = "Complete the registration process!";
  const isNoRoles = isEmpty(roles);

  const isShown = isAuthenticated && isNoRoles && !isModalOpen && !isSignUpPage;

  if (isLoading || !isShown) {
    return null;
  }

  const handleClick = () => {
    dispatch(
      openModal({
        type: modalTypes.completeRegistrationModal,
        role: initialRole,
      })
    );
  };

  if (isMobile) {
    return <MobileContainer onClick={handleClick}>{text}</MobileContainer>;
  }

  return (
    <Container>
      <StyledButton variant={buttonVariants.primary} onClick={handleClick}>
        {text}
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 140px;
  z-index: 1;
  right: 50px;
`;

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 1;
  padding: 17px;
  background: #ffffff;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0 0 20px;
  font-weight: 600;
  font-size: 14px;
  width: 130px;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  height: 80px;
  border-radius: 80px;
  padding: 0 20px;
`;

export default CompleteRegistrationPopup;
