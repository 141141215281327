import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/modal/actions";
import { modalTypes } from "../../consts";

const Avatar = ({ className, name }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openModal({ type: modalTypes.chooseUserModal }));
  };

  return (
    <Container className={className} onClick={handleClick}>
      <Circle className="circle">
        <Image src="/img/avatar.svg" />
      </Circle>

      <Text className="text">
        <Bold>Hi,</Bold>

        <Name>{name}</Name>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Circle = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  background-color: #d3ddfa;
  flex-shrink: 0;
  margin-right: 8px;
`;

const Image = styled.img`
  width: 21px;
  height: 22px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  color: #000f3b;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
`;

const Bold = styled.div`
  font-weight: 700;
`;

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Avatar;
