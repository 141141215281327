import React from "react";
import styled, { css } from "styled-components/macro";
import { map } from "lodash";
import { Link, useNavigate } from "react-router-dom";

const Breadcrumbs = ({ className, linksArray }) => {
  const navigate = useNavigate();

  return (
    <Container className={className}>
      <LinksWrapper>
        {map(linksArray, ({ text, url }, index) => {
          const isLast = index === linksArray.length - 1;

          return (
            <React.Fragment key={url}>
              <StyledLink to={url} $isLast={isLast}>
                {text}
              </StyledLink>
              {!isLast && <Arrow> > </Arrow>}
            </React.Fragment>
          );
        })}
      </LinksWrapper>

      <BackButton onClick={() => navigate(-1)}>
        <Image src="/img/regular-arrow.svg" />
        Back
      </BackButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 18px;
  color: #000f3b;
`;

const LinksWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  color: #ff2853;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${({ $isLast }) =>
    $isLast &&
    css`
      color: inherit;
      pointer-events: none;
    `}
`;

const Arrow = styled.span`
  margin: 0 5px;
`;

const BackButton = styled.div`
  display: none;
  color: #000f3c;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Image = styled.img`
  width: 18px;
  height: 15px;
  margin-right: 8px;
`;

export default Breadcrumbs;
