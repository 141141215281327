import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: "app",
    initialState: {
    },
    reducers: {

        setUserData: (state, action) => {

            return {
                ...state,
                ...action.payload
            }

        },
        clearUserData: (state = null, action) => {
            return {
                
            }

        },
    },
})
