import { useSelector } from "react-redux";
import { map } from "lodash";
import { useEquityInvestments } from "../utils/api/queries/useEquityInvestments";
import { projectsListSelector } from "../store/selectors/projects";
import { useTokenInvestments } from "../utils/api/queries/useTokenInvestments";

export const useInvestments = () => {
  const projects = useSelector(projectsListSelector);
  const { data: equityInvestments } = useEquityInvestments();
  const { data: tokenInvestments } = useTokenInvestments();

  const normalizedEquityInvestments = map(equityInvestments, (investment) => ({
    ...investment,
    projectData: projects[investment.projectId],
  }));

  const normalizedTokenInvestments = tokenInvestments ? tokenInvestments : [];

  return {
    data: [...normalizedEquityInvestments, ...normalizedTokenInvestments],
  };
};
