import React from "react";
import styled from "styled-components/macro";

const PaddingBox = styled.div`
  padding-left: 150px;
  padding-right: 150px;

  @media (max-width: 1280px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (max-width: 768px) {
    padding-left: 18px;
    padding-right: 18px;
  }
`;

export default PaddingBox;
