import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
} from "../../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/core/Button";
import { buttonVariants, signUpCompanyTokenSteps } from "../../../../consts";
import {
  setCompanyCompletedSteps,
  setMetricsInfo,
} from "../../../../store/companySignUp/actions";
import Dropdown from "../../../../Components/core/formElements/dropdown/Dropdown";
import { getFieldError } from "../../../../utils/formUtils";
import config from "../../../../assets/config.json";
import {
  ButtonsWrapper,
  Container,
  Description,
  InputsWrapper,
  Title,
} from "../styled";
import Input from "../../../../Components/core/formElements/Input";
import FileUpload from "../../../../Components/core/fileUpload/FileUpload";
import LastRaiseInfo from "../../shared/LastRaiseInfo";
import { filterArrayEmptyValues } from "../../../../utils/normalizations";

const MetricsInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { metricsInfo } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: metricsInfo,
  });

  const onSubmit = (data) => {
    const investors = filterArrayEmptyValues(data.investors);

    dispatch(setMetricsInfo({ ...data, investors }));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyTokenSteps.metricsInfo,
      ])
    );

    navigate("/sign-up/company/listing-info");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Metrics info</Title>

      <Description>Enter Metrics info below</Description>

      <Content>
        <Controller
          name="tokenSymbol"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FileUpload
              title="Token Symbol"
              subtitle="Upload token symbol"
              value={value}
              onChange={onChange}
              errorMessage={getFieldError({
                error,
                fieldLabel: "Token symbol",
              })}
            />
          )}
        />
        <StyledInputsWrapper>
          <Controller
            name="blockchainNetwork"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Dropdown
                placeholder="Blockchain network"
                options={
                  config.signUp.company.metricsInfo.blockchainNetworkOptions
                }
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Blockchain",
                })}
              />
            )}
          />

          <Controller
            name="totalSupply"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Total supply"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Total supply",
                })}
              />
            )}
          />

          <Controller
            name="discountedValuation"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Discounted valuation"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Valuation",
                })}
              />
            )}
          />

          <Controller
            name="investedSoFar"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Total invested so far"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Amount",
                })}
              />
            )}
          />

          <Controller
            name="totalRevenue"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Total revenue since encoperation"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Revenue",
                })}
              />
            )}
          />

          <Controller
            name="last12MonthsRevenue"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Last 12 months revenue"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Revenue",
                })}
              />
            )}
          />
        </StyledInputsWrapper>

        <LastRaiseInfo control={control} watch={watch} setValue={setValue} />
      </Content>

      <ButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        <Button
          variant={buttonVariants.secondary}
          onClick={() => navigate("/sign-up/company/token-company-info")}
        >
          Back
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

const StyledInputsWrapper = styled(InputsWrapper)`
  margin-top: 36px;
  margin-bottom: 30px;
`;

const Content = styled.div`
  margin-bottom: 45px;
`;

export default MetricsInfo;
