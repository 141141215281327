import {
  filter,
  forEach,
  isArray,
  isNil,
  isObject,
  map,
  pickBy,
  toUpper,
} from "lodash-es";
import countriesData from "../assets/countriesData.json";
import numeral from "numeral";
import moment from "moment";

export const getCountryOptions = () =>
  map(countriesData, (value, key) => ({
    label: value.name,
    value: value.name,
  }));

export const normalizeFormData = (data) => {
  const flattenData = {};

  forEach(data, (value, key) => {
    let normalizedValue;

    if (isArray(value)) {
      normalizedValue = map(value, ({ value }) => value);
    } else if (isObject(value)) {
      normalizedValue = value.value;
    } else {
      normalizedValue = value;
    }

    flattenData[key] = normalizedValue;
  });

  return flattenData;
};

export const normalizeDollarAmount = (value) =>
  numeral(value).format("$ 0,0[.]00");

export const normalizeDollarAmountWithLetter = (value) =>
  toUpper(numeral(value).format("$ 0[.]0a"));

export const normalizeNumber = (value) => numeral(value).format("0,0");

export const filterArrayEmptyValues = (arr) =>
  filter(arr, (element) => element);

export const removeNullValues = (object) =>
  pickBy(object, (value) => !isNil(value));

export const normalizeTokenProject = (project, onChainProjects) => {
  const { id, projectData, userId, onChainProjectId, role } = project;

  const onChainProject = onChainProjects[onChainProjectId];

  const {
    available_token_amount,
    description,
    end_time,
    project_image,
    project_name,
    start_time,
    swap_rate,
    symbol,
    token_address,
    token_amount,
  } = onChainProject || {};

  const {
    companyType,
    metricsInfo,
    videoPitchLink,
    tokenCompanyInfo,
    listingInfo,
    tokenExitStrategy,
    partnersAndInfluencers,
  } = projectData || {};
  const result = {};
  result.id = id;
  result.fundingType = companyType?.value;
  result.videoPitch = videoPitchLink?.videoPitchLink;
  result.projectCreatorId = userId;
  result.projectCreatorRole = role;

  result.projectName = tokenCompanyInfo?.companyName;
  result.logo = tokenCompanyInfo?.logo?.symbolURL;
  result.typeOfIndustry = map(tokenCompanyInfo?.industry, ({ value }) => value);
  result.numberOfStaff = tokenCompanyInfo?.numberOfEmployees;
  result.currentStage = tokenCompanyInfo?.companyStage.value;
  result.description = tokenCompanyInfo?.projectDescription;
  result.pitchDeck = tokenCompanyInfo?.pitchDeck.symbolURL;
  result.whitepaper = tokenCompanyInfo?.whitepaper.symbolURL;
  result.tokenomics = tokenCompanyInfo?.tokenMetrics.symbolURL;
  result.website = tokenCompanyInfo?.website;

  result.totalRaisedTillDate = metricsInfo?.investedSoFar;
  result.totalRevenueSinceIncorporated = metricsInfo?.totalRevenue;
  result.investors = metricsInfo?.investors;
  result.totalSupply = metricsInfo?.totalSupply;

  result.totalRaising = listingInfo?.idoSize;
  result.startTime = listingInfo?.tokenGenerationEvent.seconds * 1000;
  result.endTime =
    moment(listingInfo?.tokenGenerationEvent.seconds * 1000)
      .add(listingInfo?.idoTimeframe, "days")
      .unix() * 1000;
  result.tokenPrice = listingInfo?.pitchshowsTokenPrice;
  result.maxAllocation = listingInfo?.maxAllocation;

  result.listingPrice = tokenExitStrategy?.listingPrice;
  result.listingDate = tokenExitStrategy?.listingDate.seconds * 1000;
  result.listingExchange = tokenExitStrategy?.listingExchange;

  result.clients = partnersAndInfluencers?.clients;
  result.facebook = partnersAndInfluencers?.facebookLink;
  result.instagram = partnersAndInfluencers?.instagramLink;
  result.twitter = partnersAndInfluencers?.twitterLink;
  result.discord = partnersAndInfluencers?.discordLink;
  result.telegram = partnersAndInfluencers?.telegramLink;
  result.numberOfClientsUsers = partnersAndInfluencers?.numberOfClientsUsers;

  result.availableAmount = 0;
  result.finalPrice = result.tokenPrice;
  // result.totalRaise = result.tokenPrice;

  // BO dependant fields
  result.onChainProjectId = onChainProjectId;

  // Onchain data
  if (!!onChainProject) {
    result.availableAmount = available_token_amount * swap_rate; // in dollars
    result.description = description;
    result.projectImage = project_image;
    result.projectName = project_name;
    result.swapRate = swap_rate;
    result.symbol = symbol;
    result.tokenAddress = token_address;
    result.tokenAmount = token_amount;
    result.startTime = moment(start_time * 1000).toDate();
    result.endTime = moment(end_time * 1000).toDate();
  }

  return result;
};

export const normalizeEquityProject = (project) => {
  const {
    id,
    projectData,
    userId,
    totalRaised = 0,
    finalPrice = 1,
    role,
  } = project;

  const {
    companyType,
    equityCompanyInfo,
    equityExitStrategy,
    partnersAndClients,
    raisingInfo,
    sharesInfo,
    videoPitchLink,
  } = projectData || {};
  const result = {};
  result.id = id;
  result.fundingType = companyType?.value;
  result.videoPitch = videoPitchLink?.videoPitchLink;
  result.projectCreatorId = userId;
  result.projectCreatorRole = role;

  result.projectName = equityCompanyInfo?.companyName;
  result.logo = equityCompanyInfo?.logo.symbolURL;
  result.description = equityCompanyInfo?.projectDescription;
  result.financialRecords = equityCompanyInfo?.financialRecords.symbolURL;
  result.typeOfIndustry = map(
    equityCompanyInfo?.industry,
    ({ value }) => value
  );

  result.totalRaisedTillDate = sharesInfo?.totalRaisedTillDate;
  result.totalRevenueSinceIncorporated = sharesInfo?.totalRevenue;
  result.discountedValuation = sharesInfo?.discountedValuation;
  result.currentValuation = sharesInfo?.currentValuation;
  result.lastRaiseDate = (sharesInfo?.lastRaiseDate?.seconds ?? 0) * 1000;
  result.investors = sharesInfo?.investors;
  result.maxAllocation = sharesInfo?.maxAllocation;

  result.startTime = raisingInfo?.dateOfOpening?.seconds * 1000;
  result.endTime =
    moment(raisingInfo?.dateOfOpening?.seconds * 1000)
      .add(raisingInfo?.timeframe, "days")
      .unix() * 1000;
  result.totalRaising = raisingInfo?.currentRound;

  result.nextRoundDate =
    (equityExitStrategy?.nextRoundDate?.seconds ?? 0) * 1000;
  result.listingYear = equityExitStrategy?.yearOfListing?.value;
  result.potentialValuation = equityExitStrategy?.potentialValuation;
  result.listingStockExchange = equityExitStrategy?.listingStockExchange;

  result.numberOfStaff = equityCompanyInfo?.numberOfEmployees;
  result.businessPlan = equityCompanyInfo?.businessPlan?.symbolURL;
  result.pitchDeck = equityCompanyInfo?.pitchDeck?.symbolURL;
  result.website = equityCompanyInfo?.companyWebsite;
  result.currentStage = equityCompanyInfo?.companyStage?.value;

  result.clients = partnersAndClients?.clients;
  result.facebook = partnersAndClients?.facebookLink;
  result.instagram = partnersAndClients?.instagramLink;
  result.linkedin = partnersAndClients?.linkedInLink;
  result.numberOfClientsUsers = partnersAndClients?.numberOfClientsUsers;

  result.totalRaised = totalRaised;

  // BO dependant fields
  result.finalPrice = finalPrice;

  result.availableAmount = result?.totalRaising - result?.totalRaised;
  result.swapRate = result?.totalRaising / result?.finalPrice;

  return result;
};
