import { combineReducers } from "@reduxjs/toolkit";
import { adminSlice } from "./admins/slice";
import { userSlice } from "./users/slice";
import { projectSlice } from "./projects/slice";
import { appSlice } from "./app/slice";
import { investorSignUpSlice } from "./investorSignUp/slice";
import { companySignUpSlice } from "./companySignUp/slice";
import { filtersSlice } from "./filters/slice";
import { searchSlice } from "./search/slice";
import { modalSlice } from "./modal/slice";
import { consultantSignUpSlice } from "./consultantSignUp/slice";
import { persistSlice } from "./persist/slice";

export const rootReducer = combineReducers({
  persist: persistSlice.reducer,
  users: userSlice.reducer,
  admins: adminSlice.reducer,
  projects: projectSlice.reducer,
  app: appSlice.reducer,
  investorSignUp: investorSignUpSlice.reducer,
  companySignUp: companySignUpSlice.reducer,
  consultantSignUp: consultantSignUpSlice.reducer,
  filters: filtersSlice.reducer,
  search: searchSlice.reducer,
  modal: modalSlice.reducer,
});
