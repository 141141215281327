import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
  selectedCompanyTypeSelector,
} from "../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../Components/core/Button";
import {
  buttonVariants,
  fundingTypes,
  signUpCompanyTokenSteps,
} from "../../../consts";
import {
  setCompanyCompletedSteps,
  setVideoPitchLink,
} from "../../../store/companySignUp/actions";
import { ButtonsWrapper, Container, Description, Title } from "./styled";
import Input from "../../../Components/core/formElements/Input";
import { getFieldError } from "../../../utils/formUtils";
import { toLower } from "lodash-es";

const VideoPitch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { videoPitchLink } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);
  const companyType = useSelector(selectedCompanyTypeSelector);

  const { handleSubmit, control } = useForm({
    defaultValues: videoPitchLink,
  });

  const onSubmit = (data) => {
    dispatch(setVideoPitchLink(data));
    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyTokenSteps.videoPitch,
      ])
    );

    navigate("/sign-up/company/data-room");
  };

  const handleBackButton = () => {
    if (toLower(companyType.value) === fundingTypes.token) {
      navigate("/sign-up/company/token-exit-strategy");
    } else {
      navigate("/sign-up/company/equity-exit-strategy");
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Video pitch</Title>

      <Description>Add your video pitch link below</Description>

      <Video src="https://drive.google.com/file/d/1skM4BZJjA3mdCPmkLlCLhelVfgjgMOS-/preview" />

      <Subtitle>Upload video link</Subtitle>

      <Text>
        Make sure that all the important topics appear in your video in order to
      </Text>

      <Controller
        name="videoPitchLink"
        control={control}
        rules={{ required: true }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) => (
          <Input
            placeholder="Enter video link"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={getFieldError({
              error,
              fieldLabel: "Link",
            })}
          />
        )}
      />

      <StyledButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        <Button variant={buttonVariants.secondary} onClick={handleBackButton}>
          Back
        </Button>
      </StyledButtonsWrapper>
    </Container>
  );
};

const Video = styled.iframe`
  border: 1px solid #2d4798;
  border-radius: 20px;
  margin: 0 auto 28px;
  width: 470px;
  height: 284px;

  @media (max-width: 768px) {
    width: 280px;
    height: 220px;
  }
`;

const Subtitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: #000f3c;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #000f3c;
  margin-bottom: 25px;
`;

const StyledButtonsWrapper = styled(ButtonsWrapper)`
  margin-top: 25px;
`;

export default VideoPitch;
