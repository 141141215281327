import styled from "styled-components/macro";

export const ModalContent = styled.div`
  background: #edf2ff;
  display: flex;
  flex-direction: column;
  text-align: center;

  width: 508px;
  height: 560px;
  border-radius: 20px;
  padding: 0 20px;

  @media (max-width: 768px) {
    width: 300px;
    height: 503px;
    border-radius: 14px;
    padding: 0 14px;
  }
`;
