import { projectSlice } from "./slice";
import {
  normalizeEquityProject,
  normalizeTokenProject,
} from "../../utils/normalizations";
import { toLower } from "lodash-es";
import { fundingTypes } from "../../consts";

const { actions: slice } = projectSlice;

const formatArr = (onChainProjects, offChainProjects = []) => {
  const projects = [];

  offChainProjects.forEach((project) => {
    if (
      toLower(project?.projectData?.companyType?.value) === fundingTypes.token
    ) {
      projects.push(normalizeTokenProject(project, onChainProjects));
    } else {
      projects.push(normalizeEquityProject(project));
    }
  });

  return projects;
};

export const setProjects =
  (onChainProjects, offChainProjects) => async (dispatch) => {
    dispatch(slice.setProjects(formatArr(onChainProjects, offChainProjects)));
  };

export const updateProject =
  ({ projectId, project }) =>
  async (dispatch) => {
    dispatch(slice.updateProject({ projectId, project }));
  };
