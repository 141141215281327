import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
} from "../../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/core/Button";
import { buttonVariants, signUpCompanyEquitySteps } from "../../../../consts";
import {
  setCompanyCompletedSteps,
  setSharesInfo,
} from "../../../../store/companySignUp/actions";
import { getFieldError } from "../../../../utils/formUtils";
import {
  ButtonsWrapper,
  Container,
  Description,
  InputsWrapper,
  Title,
} from "../styled";
import Input from "../../../../Components/core/formElements/Input";
import LastRaiseInfo from "../../shared/LastRaiseInfo";
import { filterArrayEmptyValues } from "../../../../utils/normalizations";

const SharesInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sharesInfo } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: sharesInfo,
  });

  const onSubmit = (data) => {
    const investors = filterArrayEmptyValues(data.investors);

    dispatch(setSharesInfo({ ...data, investors }));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyEquitySteps.sharesInfo,
      ])
    );

    navigate("/sign-up/company/raising-info");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Shares info</Title>

      <Description>Enter Shares info below</Description>

      <Content>
        <StyledInputsWrapper>
          <Controller
            name="currentValuation"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Current valuation ($)"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isNumeric
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Current valuation",
                })}
              />
            )}
          />

          <Controller
            name="maxAllocation"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Shares allocated (%)"
                isNumeric
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                maxValue={100}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Shares amount",
                })}
              />
            )}
          />

          <Controller
            name="discountedValuation"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Discounted valuation"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Valuation",
                })}
              />
            )}
          />

          <Controller
            name="totalRaisedTillDate"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Total Investments to Date"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Total",
                })}
              />
            )}
          />

          <Controller
            name="totalInvestorsToDate"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Total investors to date"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Total",
                })}
              />
            )}
          />

          <Controller
            name="totalRevenue"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Total revenue since incorporation"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Total",
                })}
              />
            )}
          />

          <Controller
            name="last12MonthsRevenue"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Last 12 months revenue"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Revenue",
                })}
              />
            )}
          />
        </StyledInputsWrapper>

        <LastRaiseInfo control={control} watch={watch} setValue={setValue} />
      </Content>

      <ButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        <Button
          variant={buttonVariants.secondary}
          onClick={() => navigate("/sign-up/company/equity-company-info")}
        >
          Back
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

const Content = styled.div`
  margin-bottom: 66px;
`;

const StyledInputsWrapper = styled(InputsWrapper)`
  margin-bottom: 50px;
`;

export default SharesInfo;
