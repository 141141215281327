const _data = [
  {
    chainID: 1,
    currency: "ETH",
    logo: "/img/chain/ETH.webp",
    name: "ETHEREUM",
  },
  {
    chainID: 56,
    currency: "BNB",
    logo: "/img/chain/BNB.svg",
    name: "BINANCE",
  },
  {
    chainID: 97,
    currency: "tBNB",
    logo: "/img/chain/BNB.svg",
    name: "TEST BINANCE",
  },
];

export default _data;
