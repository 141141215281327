import { createSlice } from "@reduxjs/toolkit";
import { companySignUpInitialState } from "../../utils/schema";

export const companySignUpSlice = createSlice({
  name: "companySignUp",
  initialState: companySignUpInitialState,
  reducers: {
    setCompanyCompletedSteps: (state, { payload: completedSteps }) => ({
      ...state,
      completedSteps,
    }),
    setContactInfo: (state, { payload: contactInfo }) => ({
      ...state,
      contactInfo,
    }),
    setCompanyType: (state, { payload: companyType }) => ({
      ...state,
      companyType,
    }),
    setFoundersInfo: (state, { payload: foundersInfo }) => ({
      ...state,
      foundersInfo,
    }),
    setTokenCompanyInfo: (state, { payload: tokenCompanyInfo }) => ({
      ...state,
      tokenCompanyInfo,
    }),
    setMetricsInfo: (state, { payload: metricsInfo }) => ({
      ...state,
      metricsInfo,
    }),
    setListingInfo: (state, { payload: listingInfo }) => ({
      ...state,
      listingInfo,
    }),
    setPartnersAndInfluencers: (
      state,
      { payload: partnersAndInfluencers }
    ) => ({
      ...state,
      partnersAndInfluencers,
    }),
    setTokenExitStrategy: (state, { payload: tokenExitStrategy }) => ({
      ...state,
      tokenExitStrategy,
    }),
    setVideoPitchLink: (state, { payload: videoPitchLink }) => ({
      ...state,
      videoPitchLink,
    }),
    resetCompanySignup: () => companySignUpInitialState,

    setEquityCompanyInfo: (state, { payload: equityCompanyInfo }) => ({
      ...state,
      equityCompanyInfo,
    }),
    setSharesInfo: (state, { payload: sharesInfo }) => ({
      ...state,
      sharesInfo,
    }),
    setRaisingInfo: (state, { payload: raisingInfo }) => ({
      ...state,
      raisingInfo,
    }),
    setPartnersAndClients: (state, { payload: partnersAndClients }) => ({
      ...state,
      partnersAndClients,
    }),
    setEquityExitStrategy: (state, { payload: equityExitStrategy }) => ({
      ...state,
      equityExitStrategy,
    }),
    setDocumentsLink: (state, { payload: documentsLink }) => ({
      ...state,
      documentsLink,
    }),
  },
});
