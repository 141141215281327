import React, { useEffect, useRef, useState } from "react";
import { ModalContent, ModalText, ModalTitle } from "./shared";
import styled, { css } from "styled-components/macro";
import CodeInputs from "../../../core/CodeInputs";
import ClickableText from "../../../core/ClickableText";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../../store/modal/actions";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../../services/auth.service";
import {
  signInWithEmailAndPassword2Fa,
  verifyCode,
} from "../../../../utils/api/mutations";
import { registerPhone } from "../../../../utils/authUtils";
import { modalTypes } from "../../../../consts";
import { nanoid } from "@reduxjs/toolkit";

const recaptchaId = "recaptchaId";
const SECONDS_DELAY = 60;

const PhoneCodeModal = ({
  phoneNumber,
  verificationId,
  resolver,
  isFirstPhoneVerification,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState(nanoid());

  useEffect(() => {
    const timeout = setTimeout(
      () => setIsResendEnabled(true),
      SECONDS_DELAY * 1000
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [isResendEnabled]);

  const { mutate, isError } = useMutation({
    mutationFn: isFirstPhoneVerification
      ? verifyCode
      : signInWithEmailAndPassword2Fa,
    onSuccess: async () => {
      await AuthService.auth.currentUser.reload();
      navigate("/");
      dispatch(closeModal());
    },
    onError: (e) => {
      console.log(`e`, e);
    },
  });

  const { mutate: resendCode } = useMutation({
    mutationFn: registerPhone,
    onSuccess: (data) => {
      if (!data) {
        setIsResendEnabled(true);
      }
    },
  });

  const handleResend = async () => {
    if (!isResendEnabled) {
      return;
    }

    setIsResendEnabled(false);
    setRecaptchaKey(nanoid());
    resendCode({ phoneNumber, recaptchaRef });
  };

  const handleCodeFilled = (code) => {
    mutate({
      phoneNumber,
      code,
      verificationId,
      resolver,
    });
  };

  const handleUpdateNumber = () => {
    dispatch(openModal({ type: modalTypes.enterPhoneNumber }));
  };

  return (
    <ModalContent>
      <div id={recaptchaId} key={recaptchaKey} ref={recaptchaRef} />
      <StyledModalTitle>
        Enter the 6-digit code we just sent to your phone *********
        {phoneNumber?.slice(-3)}
      </StyledModalTitle>

      <Description>
        In order to keep your account secure we want to verify that it is really
        you.
      </Description>

      <StyledCodeInputs onFilled={handleCodeFilled} isError={isError} />

      {isError && <Error>Wrong SMS code. Try again.</Error>}

      <Resend isResendEnabled={isResendEnabled} onClick={handleResend}>
        Resend Code
      </Resend>

      {isFirstPhoneVerification && (
        <ChangePhone>
          Wrong phone number?
          <UpdateNumber onClick={handleUpdateNumber}>
            Update number
          </UpdateNumber>
        </ChangePhone>
      )}
    </ModalContent>
  );
};

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 15px;
  margin-top: 20px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Description = styled(ModalText)`
  margin-bottom: 50px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const StyledCodeInputs = styled(CodeInputs)`
  justify-content: center;
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #ff2853;
  text-align: center;
  margin-top: 20px;
`;

const Resend = styled(ClickableText)`
  text-decoration: underline;
  font-size: 16px;
  font-weight: 600;
  color: #031855;
  text-align: center;
  margin-top: 70px;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  ${({ isResendEnabled }) =>
    !isResendEnabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const ChangePhone = styled.div`
  display: flex;
  margin-top: 100px;
  font-size: 16px;
  font-weight: 400;
  color: #031855;
  justify-content: center;
`;

const UpdateNumber = styled(ClickableText)`
  font-weight: 500;
  color: #2c73eb;
  margin-left: 5px;
`;
export default PhoneCodeModal;
