import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../utils/connectors";
import { NetworkHandler } from "../../helpers/NetworkHandler";
import toast from "react-hot-toast";
import { useAuthenticated } from "../../hooks/useAuthenticated";
import Avatar from "../core/Avatar";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/modal/actions";
import { modalTypes } from "../../consts";
import { appSelector } from "../../store/selectors/app";
import { useUserDBData } from "../../utils/api/queries/useUserDBData";
import { useDisplayName } from "../../utils/api/queries/useDisplayName";

const Navbar = ({ status, withConnect }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navbarShow, setNavbarShow] = useState(false);
  const { active, account, library, connector, activate, deactivate } =
    useWeb3React();

  const { isAuthenticated, signOut } = useAuthenticated();
  const { data } = useUserDBData({ enabled: !!isAuthenticated });
  const role = data?.role;
  const displayName = useDisplayName();

  const loggedInUser = useSelector(appSelector);

  const isFullyRegistered = !!role;

  useEffect(() => {
    // activate(injected);
  }, []);

  const walletConnect = async () => {
    try {
      const { status, message } = await NetworkHandler();
      if (status) {
        try {
          await activate(injected);
        } catch (e) {
          throw e;
        }
      } else {
        toast.error(`${message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(`${error}`);
    }
  };

  const handleLogin = async () => {
    dispatch(openModal({ type: modalTypes.signInModal }));
  };

  const handleSignUp = async () => {
    if (isAuthenticated) {
      await signOut();
    } else {
      dispatch(openModal({ type: modalTypes.signUpModal }));
    }
  };

  useEffect(() => {
    const targetEl = document.querySelector(".navbar").nextElementSibling;
    const navElement = document.querySelector(".navbar");

    const options = {
      root: null,
      rootMargin: `0px 0px ${
        navElement?.getBoundingClientRect()?.height || 0
      }px 0px`,
      threshold: 1,
    };

    const callback = (entries, _) => {
      entries.forEach((entry) => {
        const { isIntersecting } = entry;
        const classToAdd = "sticky";
        if (isIntersecting) {
          navElement.classList.remove(classToAdd);
        } else {
          navElement.classList.add(classToAdd);
        }
      });
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(targetEl);

    return () => {
      observer.unobserve(targetEl);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/img/logo.svg" alt="" />
          <img src="/img/logo2.svg" alt="" />
        </Link>

        <div className="navbar-div">
          {isAuthenticated && (
            <MobileAvatar className="avatar" name={displayName} />
          )}

          <div className={`navbar-menu ${navbarShow ? "active" : ""}`}>
            <div className="mobile-show-navbar">
              <Link to="/">
                <img src="/img/logo.svg" alt="" />
              </Link>

              <button
                className="close"
                onClick={() => setNavbarShow(!navbarShow)}
              >
                <img src="/img/close.svg" alt="" />
              </button>
            </div>
            <ul className="navbar-nav align-items-center">
              <li className={`nav-item ${status === "home" && "active"}`}>
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className={`nav-item ${status === "launchpad" && "active"}`}>
                <Link className="nav-link" to="/launchpad">
                  Launchpad
                </Link>
              </li>
              <li className={`nav-item ${status === "about" && "active"}`}>
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              {isFullyRegistered && (
                <li className={`nav-item ${status === "account" && "active"}`}>
                  <Link className="nav-link" to="/account">
                    Account
                  </Link>
                </li>
              )}

              <span className="separator only-desktop" />

              {!isAuthenticated ? (
                <li className={`nav-item login`}>
                  <Link className="nav-link" onClick={handleLogin} to="">
                    Login
                  </Link>
                </li>
              ) : (
                <StyledAvatar className="avatar" name={displayName} />
              )}

              <li className={`nav-item sign-up`}>
                <Link className="nav-link" onClick={handleSignUp} to="">
                  {isAuthenticated ? "Logout" : "Signup"}
                </Link>
              </li>
            </ul>
          </div>
          {withConnect ? (
            active ? (
              <button onClick={() => deactivate()} className="main-btn">
                Disconnect {account.substring(0, 6)}...{" "}
                {account.substring(account.length - 4, account.length)}
              </button>
            ) : (
              <button onClick={walletConnect} className="main-btn">
                Connect Wallet
              </button>
            )
          ) : null}
          <button
            className="menu-btn"
            onClick={() => setNavbarShow(!navbarShow)}
          >
            <img src="/img/menu-icon.svg" alt="" />
            <img src="/img/menu-icon2.svg" alt="" />
          </button>
        </div>
      </div>
    </nav>
  );
};

const StyledAvatar = styled(Avatar)`
  width: 120px;
  margin-left: 28px;
  margin-right: 14px;

  .text {
    color: white;
  }
`;

const MobileAvatar = styled(Avatar)`
  width: 100px;
  margin-right: 10px;

  .text {
    color: white;
    font-size: 15px;
  }

  .circle {
    width: 35px;
    height: 35px;
  }

  visibility: hidden;

  @media (max-width: 768px) {
    visibility: visible;
  }
`;

export default Navbar;
