export const userData = {
  contactInfo: {
    email: "",
    name: "",
    phoneNumber: "",
    code: "",
    phoneCode: "",
  },
  foundersInfo: {
    founderName: "",
    founderRole: "",
    founderDiscerption: "",
    founderLinkedin: "",
  },
  companyInfo: {
    projectDiscerption: "",
    teamBackground: "",
    compayStage: "",
    selectState: "",
    teamSize: "",
    companyWebsite: "",
    socialsLink: {
      facebook: "",
      instagram: "",
      linkedIn: "",
      discord: "",
      telegram: "",
    },
    pitchDeckURL: "",
    tokenomicsFileURL: "",
    LogoURL: "",
  },
  metricsInfo: {
    blockchainNetwork: "",
    totalSupply: "",
    discountedValuation: "",
    TGEMarketCap: "",
    symbolURL: "",
    lastRaiseDate: null,
    lastRaiseAmount: "",
    typeOfInvestors: "",
    investors: [],
  },
  listingInfo: {
    IDOSize: "",
    additionalPlatforms: "",
    discountedValuation: "",
    tokenGenerationEven: "",
    listingPrice: "",
    pitchshowsTokenPrice: "",
    IDOTimeFrame: "",
    vestingTermsURL: "",
    pitchshowVestingTermsURL: "",
    moneyLastDuration: "",
  },
  partnersInfluencers: {
    topPartners: "",
    topInfluencers: "",
  },
  exitStrategy: {
    choiceTitleSelection: "",
    liquidity: "",
    marketMaker: "",
  },
  videoPitch: {
    url: "",
  },
};

export const investorSignUpInitialState = {
  completedSteps: [],
  contactInfo: {
    fullName: "",
    email: "",
    phoneCode: "",
    phoneNumber: "",
    isAgreedWithPolicy: false,
    notUSResident: false,
  },
  companyInfo: {
    companyName: "",
    countryIncorporation: "",
    incorporationLicense: "",
    typeOfInvestment: "",
    typeOfIndustry: "",
    otherIndustryType: "",
    investmentSize: "",
    groupOrIndividual: "",
    companyStage: "",
    isRegulated: false,
    jurisdiction: "",
  },
};

export const companySignUpInitialState = {
  completedSteps: [],
  contactInfo: {
    fullName: "",
    email: "",
    phoneCode: "",
    phoneNumber: "",
    isAgreedWithPolicy: false,
  },
  companyType: "",
  foundersInfo: [],
  tokenCompanyInfo: {
    companyName: "",
    countryOfCooperation: "",
    companyStage: "",
    website: "",
    incorporationLicense: "",
    industry: "",
    otherIndustryType: "",
    projectDescription: "",
    numberOfEmployees: "",
    teamBackground: "",
    pitchDeck: null,
    tokenMetrics: null,
    whitepaper: null,
    logo: null,
  },
  metricsInfo: {
    tokenSymbol: null,
    blockchainNetwork: "",
    totalSupply: "",
    discountedValuation: "",
    investedSoFar: "",
    totalRevenue: "",
    last12MonthsRevenue: "",
  },
  listingInfo: {
    idoSize: "",
    maxAllocation: "",
    additionalPlatforms: "",
    pitchshowsSize: "",
    tokenGenerationEvent: "",
    idoTimeframe: "",
    pitchshowsTokenPrice: "",
    pitchshowVestingTerms: null,
  },
  partnersAndInfluencers: {
    topPartners: "",
    topInfluencers: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    discordLink: "",
    telegramLink: "",
    isHaveClients: false,
    isHaveUsers: false,
    numberOfClientsUsers: "",
  },
  tokenExitStrategy: {
    dexOrCex: "",
    listingPrice: "",
    listingDate: "",
    listingExchange: "",
  },
  videoPitchLink: "",

  equityCompanyInfo: {
    companyName: "",
    countryOfCooperation: "",
    companyStage: "",
    companyWebsite: "",
    incorporationLicense: "",
    industry: "",
    otherIndustryType: "",
    projectDescription: "",
    numberOfEmployees: "",
    teamBackground: "",
    pitchDeck: null,
    financialRecords: null,
    businessPlan: null,
    logo: null,
  },
  sharesInfo: {
    currentValuation: "",
    maxAllocation: "",
    discountedValuation: "",
    totalRaisedTillDate: "",
    totalInvestorsToDate: "",
    totalRevenue: "",
    last12MonthsRevenue: "",
    lastRaiseDate: null,
    lastRaiseAmount: "",
    typeOfInvestors: "",
    investors: [],
  },
  raisingInfo: {
    currentRound: "",
    additionalPlatforms: "",
    pitchshowsSize: "",
    dateOfOpening: "",
    timeframe: "",
    vestingTerms: null,
    pitchshowVestingTerms: null,
    moneyLastDuration: "",
  },
  partnersAndClients: {
    topPartners: "",
    numberOfClientsUsers: "",
    facebookLink: "",
    instagramLink: "",
    linkedInLink: "",
    isRegulated: false,
    isHaveClients: false,
    isHaveUsers: false,
    jurisdiction: "",
  },
  equityExitStrategy: {
    potentialValuation: "",
    nextRoundDate: "",
    yearOfListing: "",
    listingStockExchange: "",
  },
  documentsLink: "",
};

export const consultantSignUpInitialState = {
  completedSteps: [],
  contactInfo: {
    fullName: "",
    email: "",
    phoneCode: "",
    phoneNumber: "",
    isAgreedWithPolicy: false,
  },
  companyInfo: {
    companyName: "",
    countryIncorporation: "",
    typeOfIndustry: "",
    otherIndustryType: "",
    numberOfCompanies: "",
    investmentSize: "",
    incorporationLicense: null,
  },
};
