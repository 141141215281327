import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
} from "../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../Components/core/Button";
import { buttonVariants, signUpCompanyBasicSteps } from "../../../consts";
import {
  setCompanyCompletedSteps,
  setCompanyType,
} from "../../../store/companySignUp/actions";
import Dropdown from "../../../Components/core/formElements/dropdown/Dropdown";
import { getFieldError } from "../../../utils/formUtils";
import config from "../../../assets/config.json";
import { ButtonsWrapper, Container, Description, Title } from "./styled";

const CompanyType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyType } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);
  const { state } = useLocation();

  // It means we haven't filled the first step (were rerouted from Account page)
  const noBackButton = !state?.contactInfo?.fullName;

  const { handleSubmit, control } = useForm({
    defaultValues: { companyType },
  });

  const onSubmit = (data) => {
    dispatch(setCompanyType(data.companyType));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyBasicSteps.companyType,
      ])
    );

    navigate("/sign-up/company/founders-info");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Company type</Title>

      <Description>Enter your type of Raising funds preference</Description>

      <Controller
        name="companyType"
        control={control}
        rules={{ required: true }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) => {
          return (
            <Dropdown
              placeholder="Raising funds through?"
              options={config.signUp.company.companyTypeOptions}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={getFieldError({
                error,
                fieldLabel: "Type",
              })}
            />
          );
        }}
      />

      <StyledButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        {!noBackButton && (
          <Button
            variant={buttonVariants.secondary}
            onClick={() => navigate("/sign-up/company/contact-info")}
          >
            Back
          </Button>
        )}
      </StyledButtonsWrapper>
    </Container>
  );
};

const StyledButtonsWrapper = styled(ButtonsWrapper)`
  margin-top: 16px;
`;

export default CompanyType;
