import React, { useRef, useState, useLayoutEffect } from "react";
import styled, { css } from "styled-components/macro";

const ShowMoreBox = ({ className, maxHeight, children }) => {
  const childrenRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState();

  useLayoutEffect(() => {
    setIsOverflowing(childrenRef?.current?.scrollHeight > maxHeight);
  }, []);

  return (
    <Container className={className}>
      <ChildrenWrapper
        ref={childrenRef}
        isOverflowing={isOverflowing}
        isOpen={isOpen}
        maxHeight={maxHeight}
      >
        {children}
      </ChildrenWrapper>

      {isOverflowing && (
        <ShowMoreLess onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? "Show less" : "Show more"}
        </ShowMoreLess>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
`;

const ChildrenWrapper = styled.div`
  ${({ isOverflowing, isOpen, maxHeight }) => {
    if (isOverflowing && !isOpen) {
      return css`
        height: ${maxHeight}px;
        overflow: hidden;
      `;
    }
  }}
`;

const ShowMoreLess = styled.div`
  font-weight: 500;
  font-size: 18px;
  text-decoration-line: underline;
  color: #09216a;
  cursor: pointer;
  align-self: start;
`;

export default ShowMoreBox;
