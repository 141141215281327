export const getCountryAndPhoneCode = async () => fetch(`https://api.ipregistry.co/?key=${process.env.REACT_APP_IP_REGISTRY_KEY}`)
    .then(function (response) {
        return response.json();
    })
    .then(function (payload) {
        const code = payload.location.country.code;
        const phoneCode = payload.location.country.calling_code;
        return { code, phoneCode };
    }).catch(e => {
        console.error(e);
        throw e;
    })