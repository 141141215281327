import React from "react";
import { BackToSignIn, ModalContent, ModalText, ModalTitle } from "./shared";
import styled from "styled-components/macro";
import { modalTypes } from "../../../../consts";
import { openModal } from "../../../../store/modal/actions";
import { useDispatch } from "react-redux";

const ForgotPasswordSubmittedModal = () => {
  const dispatch = useDispatch();

  const handleSignIn = () => {
    dispatch(openModal({ type: modalTypes.signInModal }));
  };

  return (
    <ModalContent>
      <StyledModalTitle>Great!</StyledModalTitle>

      <Description>
        We just sent instructions to the email provided if it’s associated with
        an account.
      </Description>

      <BackToSignIn onClick={handleSignIn}>Back to Sign in</BackToSignIn>
    </ModalContent>
  );
};

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 30px;
  font-size: 30px;
  text-align: center;
`;

const Description = styled(ModalText)`
  margin-bottom: 100px;
  text-align: center;
  font-size: 22px !important;
`;

export default ForgotPasswordSubmittedModal;
