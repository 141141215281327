import { roles } from "../../../consts";
import { useUserDBData } from "./useUserDBData";
import { useCompany } from "./useCompany";
import { useConsultant } from "./useConsultant";
import { useInvestor } from "./useInvestor";
import { useSelector } from "react-redux";
import { appSelector } from "../../../store/selectors/app";

export const useDisplayName = () => {
  const { data } = useUserDBData();
  const { email, displayName } = useSelector(appSelector);
  const role = data?.role;

  const { data: companyData } = useCompany({
    enabled: role === roles.company.value,
  });
  const { data: consultantData } = useConsultant({
    enabled: role === roles.consultant.value,
  });
  const { data: investorData } = useInvestor({
    enabled: role === roles.investor.value,
  });

  switch (role) {
    case roles.investor.value:
      return investorData?.roleData?.contactInfo?.fullName;

    case roles.company.value:
      return companyData?.roleData?.contactInfo?.fullName;

    case roles.consultant.value:
      return consultantData?.roleData?.contactInfo?.fullName;

    default:
      return displayName || email;
  }
};
