import React from "react";
import { ModalContent, ModalText, ModalTitle, SubmitButton } from "./shared";
import styled from "styled-components/macro";
import { buttonVariants, roles } from "../../../../consts";
import { useDispatch } from "react-redux";
import ClickableText from "../../../core/ClickableText";
import { useNavigate } from "react-router-dom";
import { closeModal } from "../../../../store/modal/actions";

const CompleteRegistrationModal = ({ role = roles.investor.value }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeProfile = () => {
    navigate("/sign-up");
    dispatch(closeModal());
  };

  const handleSignUp = () => {
    switch (role) {
      case roles.investor.value:
        navigate(`/sign-up/investor/contact-info`);
        break;

      case roles.company.value:
        navigate(`/sign-up/company/contact-info`);
        break;

      case roles.consultant.value:
        navigate(`/sign-up/consultant/contact-info`);
        break;
    }

    dispatch(closeModal());
  };

  return (
    <ModalContent>
      <StyledModalTitle>Complete the registration process!</StyledModalTitle>

      <Description>
        Welcome! We are very happy to see you here. To enjoy content suitable
        for your purposes, we recommend that you complete the registration
        process.
      </Description>

      <StyledModalText>Complete registration as</StyledModalText>

      <Role>{roles[role].label}</Role>

      <StyledModalText>For changing user profile</StyledModalText>

      <ClickHere onClick={handleChangeProfile}>Click here</ClickHere>

      <SubmitButton variant={buttonVariants.primary} onClick={handleSignUp}>
        Sign Up
      </SubmitButton>

      <MaybeLater onClick={() => dispatch(closeModal())}>
        Maybe Later
      </MaybeLater>
    </ModalContent>
  );
};

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 15px;
  text-align: center;
`;

const Description = styled(ModalText)`
  margin-bottom: 50px;
  text-align: center;
`;

const StyledModalText = styled(ModalText)`
  text-align: center;
  margin-bottom: 10px;
`;

const Role = styled.div`
  width: 80%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 0 15px #d3ddfa;
  border-radius: 8px;
  margin: 0 auto 20px auto;
`;

const ClickHere = styled(ClickableText)`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #ff2853;
  margin-bottom: 60px;
  text-decoration: underline;
`;

const MaybeLater = styled(ClickableText)`
  font-size: 16px;
  font-weight: 600;
  margin: 30px auto 0 auto;
`;

export default CompleteRegistrationModal;
