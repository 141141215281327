import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as Icon } from "../../../assets/v-icon.svg";

const ValidateIcon = ({ className, isValid }) => (
  <Container className={className} isValid={isValid}>
    <StyledIcon $isValid={isValid} />
  </Container>
);

const Container = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ isValid }) => (isValid ? "#09216A" : "#D8DDED")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(Icon)`
  fill: ${({ $isValid }) => ($isValid ? "#B9CAFF" : "white")};
`;

export default ValidateIcon;
