import { DALServiceV2 } from "../../../services/data-access-layer.service";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../consts";

export const useProjectWatchList = ({ projectId, queryOptions }) =>
  useQuery(
    [queryKeys.projectWatchList, projectId],
    () => DALServiceV2.getProjectWatchList(projectId),
    {
      ...queryOptions,
    }
  );
