import "react-popper-tooltip/dist/styles.css";
import { top } from "@popperjs/core";
import React, { Children, cloneElement } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import styled from "styled-components/macro";

const Tooltip = ({ children, content, displayArrow = true, popperConfig }) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } =
    usePopperTooltip({
      trigger: "hover",
      placement: top,
      ...popperConfig,
      offset: displayArrow ? [0, 7] : [0, 5],
    }) || {};

  return (
    <>
      {children &&
        Children.map(children, (child) => {
          return (
            child && cloneElement(child, { ...child.props, ref: setTriggerRef })
          );
        })}

      {visible && (
        <TooltipWrapper
          ref={setTooltipRef}
          {...getTooltipProps({ className: `tooltip-container` })}
        >
          {displayArrow && (
            <div {...getArrowProps({ className: "tooltip-arrow" })} />
          )}

          {content}
        </TooltipWrapper>
      )}
    </>
  );
};

const TooltipWrapper = styled.div`
  &.tooltip-container {
    border: none !important;
    box-shadow: 0 0 5px 3px rgba(88, 128, 255, 0.15);
    border-radius: 2px;
    padding: 8px;
    background-color: #f8faff;
    color: #000f3c;
    font-size: 15px;
    font-weight: 300;

    &[data-popper-placement="top"] .tooltip-arrow::after,
    &[data-popper-placement="top-start"] .tooltip-arrow::after,
    &[data-popper-placement="top-end"] .tooltip-arrow::after {
      border-color: #f8faff transparent transparent transparent;
    }

    &[data-popper-placement="bottom"] .tooltip-arrow::after,
    &[data-popper-placement="bottom-start"] .tooltip-arrow::after,
    &[data-popper-placement="bottom-end"] .tooltip-arrow::after {
      border-color: transparent transparent #f8faff transparent;
    }

    &[data-popper-placement="right"] .tooltip-arrow::after {
      border-color: transparent #f8faff transparent transparent;
    }

    &[data-popper-placement="left"] .tooltip-arrow::after {
      border-color: transparent transparent transparent #f8faff;
    }
    & .tooltip-arrow::before {
      border: none;
    }
  }
`;

export default Tooltip;
