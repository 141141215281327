import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Navbar from "../../Components/Section/Navbar";
import Footer from "../../Components/Section/Footer";
import PaddingBox from "../../Components/PaddingBox";
import AccountLink from "./AccountLink";
import { Description, Title } from "./shared";
import AddNewCompany from "./AddNewCompany";
import Dropdown from "../../Components/core/formElements/dropdown/Dropdown";
import { roles } from "../../consts";
import { useUserDBData } from "../../utils/api/queries/useUserDBData";
import { useUserProjects } from "../../utils/api/queries/useUserProjects";
import { map } from "lodash-es";
import { useCompany } from "../../utils/api/queries/useCompany";
import { useConsultant } from "../../utils/api/queries/useConsultant";
import { useDisplayName } from "../../utils/api/queries/useDisplayName";

const Account = () => {
  const [currentProject, setCurrentProject] = useState();

  const { data } = useUserDBData();
  const { data: projects } = useUserProjects();

  const role = data?.role;

  const isInvestor = role === roles.investor.value;

  const { data: companyData } = useCompany({
    enabled: role === roles.company.value,
  });
  const { data: consultantData } = useConsultant({
    enabled: role === roles.consultant.value,
  });
  const displayName = useDisplayName();

  const projectOptions = map(projects, ({ id, projectName }) => ({
    label: projectName,
    value: id,
  }));
  const isHaveProjects = !!projectOptions?.length;
  const isAddCompanyEnabled = companyData
    ? companyData?.verified
    : consultantData?.verified;

  useEffect(
    () => {
      if (isHaveProjects) {
        setCurrentProject(projectOptions[0]);
      }
    },
    [JSON.stringify(projectOptions)],
    isHaveProjects
  );

  return (
    <Container>
      <Navbar status={"account"} />

      <Content>
        <Header>
          <Title>My Profile</Title>
          {displayName && (
            <Description>
              Viewing profile information for <b>{displayName}</b>
            </Description>
          )}
        </Header>

        <Panel isTwoColumns={!isInvestor && isHaveProjects}>
          {isInvestor ? (
            <AccountLinks>
              <LinksWrapper>
                <AccountLink text="My investments" url="/account/investments" />
                <AccountLink text="My favorites" url="/account/favorites" />
              </LinksWrapper>
            </AccountLinks>
          ) : (
            isHaveProjects && (
              <AccountLinks>
                <StyledDropdown
                  value={currentProject}
                  onChange={setCurrentProject}
                  options={projectOptions}
                  isClearable={false}
                  minHeight="60px"
                />

                <LinksWrapper>
                  <AccountLink
                    text="Manage project"
                    url={`/account/${currentProject?.value}/manage-project`}
                  />
                  <AccountLink
                    text="Investors watchlist"
                    url={`/account/${currentProject?.value}/investors-watchlist`}
                  />
                </LinksWrapper>
              </AccountLinks>
            )
          )}

          {!isInvestor && (
            <StyledAddNewCompany isAddCompanyEnabled={isAddCompanyEnabled} />
          )}
        </Panel>
      </Content>

      <Footer />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eaf0ff;
`;

const Content = styled(PaddingBox)`
  padding-top: 240px;
  padding-bottom: 300px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding-top: 110px;
    padding-bottom: 90px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Panel = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: ${({ isTwoColumns }) =>
    isTwoColumns ? "1fr 1fr" : "none"};

  @media (max-width: 768px) {
    grid-template-columns: none;
  }
`;

const AccountLinks = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 657px;
`;

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 35px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

const StyledAddNewCompany = styled(AddNewCompany)`
  justify-self: center;
`;

export default Account;
