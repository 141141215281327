import React from "react";
import styled from "styled-components/macro";

const HorizontalProgressLine = ({
  className,
  height = 9,
  backColor = "#CCDBFF",
  fillColor = "#09216A",
  fillPercent,
}) => (
  <BackColor className={className} height={height} backColor={backColor}>
    <FillColor fillColor={fillColor} fillPercent={fillPercent} />
  </BackColor>
);

const BackColor = styled.div`
  width: 100%;
  background: ${({ backColor }) => backColor};
  border-radius: 6px;
  height: ${({ height }) => height}px;
  position: relative;
`;

const FillColor = styled.div`
  width: ${({ fillPercent }) => (fillPercent > 100 ? 100 : fillPercent)}%;
  background: ${({ fillColor }) => fillColor};
  border-radius: 6px;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export default HorizontalProgressLine;
