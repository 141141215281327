import React from "react";
import styled from "styled-components/macro";
import Tooltip from "../Tooltip";

const InfoIcon = ({ className, info }) => (
  <Tooltip className={className} content={info}>
    <Icon src="/img/info-icon.svg" />
  </Tooltip>
);

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

export default InfoIcon;
