import React from "react";
import styled from "styled-components/macro";

const HeartIcon = ({ className, isFilled, onClick }) => (
  <Container className={className} onClick={onClick}>
    <Icon
      className="icon"
      src={isFilled ? "/img/filled-heart.svg" : "/img/empty-heart.svg"}
    />
  </Container>
);

const Container = styled.div`
  width: 48px;
  background: #ffffff;
  box-shadow: 0 4px 30px 12px rgba(93, 130, 241, 0.2);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
`;

const Icon = styled.img`
  width: 18px;
  height: 14px;
`;

export default HeartIcon;
