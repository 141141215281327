import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import Button from "../../core/Button";
import { buttonVariants } from "../../../consts";
import { ModalContent } from "../shared";
import { closeModal } from "../../../store/modal/actions";

const ApplicationSubmittedModal = () => {
  const dispatch = useDispatch();

  return (
    <StyledModalContent>
      <Title>Thanks for your application!</Title>
      <Message>
        We received your request, we will contact you within 24 hours at the
        phone number you entered.
      </Message>

      <Button
        variant={buttonVariants.primary}
        onClick={() => dispatch(closeModal())}
      >
        Close
      </Button>
    </StyledModalContent>
  );
};

const StyledModalContent = styled(ModalContent)`
  height: 376px;

  @media (max-width: 768px) {
    height: 398px;
  }
`;

const Title = styled.div`
  color: #09216a;
  font-weight: 600;
  font-size: 23px;
  line-height: 23px;

  margin: 109px 150px 18px 150px;

  @media (max-width: 768px) {
    margin: 100px 30px 18px 30px;
  }
`;

const Message = styled.div`
  color: #09216a;
  font-weight: 300;

  margin: 0 auto 36px auto;
  max-width: 317px;
  font-size: 18px;
  line-height: 22px;

  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 19px;
    margin: 0 auto 85px auto;
  }
`;

export default ApplicationSubmittedModal;
