import React from "react";
import styled from "styled-components/macro";
import FormElementWrapper from "./FormElementWrapper";

const Input = ({
  className,
  value,
  onChange,
  placeholder,
  InnerComponent,
  onClick,
  isNumeric,
  onBlur,
  errorMessage,
  name,
  isInteger,
  disabled,
  onKeyDown,
  maxValue,
}) => {
  const handleNumericKeyPress = (e) => {
    // Only allow '0-9' press and '.' if not integer
    if (
      !(
        (e.charCode >= 48 && e.charCode <= 57) ||
        (!isInteger && e.charCode === 46)
      )
    ) {
      e.preventDefault();
    }
  };

  const handleNumericChange = (e) => {
    const { value } = e.target;

    if (value) {
      const numericValue = Number(value);

      if (numericValue > maxValue) {
        onChange(maxValue.toString());
        return;
      }
    }

    onChange(value);
  };

  const numericProps = isNumeric
    ? { onKeyPress: handleNumericKeyPress, onChange: handleNumericChange }
    : {};

  return (
    <StyledFormElementWrapper
      className={className}
      onClick={() => onClick && onClick()}
      onBlur={onBlur}
      errorMessage={errorMessage}
    >
      <Container className="input-container">
        <InputElement
          name={name}
          autoComplete={name ? "on" : "off"}
          type="text"
          placeholder={placeholder}
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          onKeyDown={onKeyDown}
          {...numericProps}
        />

        {InnerComponent && (
          <InnerComponentWrapper>
            <InnerComponent />
          </InnerComponentWrapper>
        )}
      </Container>
    </StyledFormElementWrapper>
  );
};

const StyledFormElementWrapper = styled(FormElementWrapper)`
  width: 100%;
`;

const Container = styled.div`
  height: 40px;
  background: #f1f4ff;
  border: 1px solid #8e9fcb;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const InputElement = styled.input`
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: 300;
  font-size: 18px;
  color: #071b56;
  width: 100%;

  &::placeholder {
    color: #8e9fcb;
  }
`;

const InnerComponentWrapper = styled.div`
  display: flex;
  margin-left: 10px;
`;

export default Input;
