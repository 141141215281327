import React from "react";
import styled from "styled-components/macro";
import PageSearch from "../../Components/core/PageSearch";
import { setSearch } from "../../store/search/actions";
import FiltersArea from "../../Components/FiltersArea";
import { projectFilterTypes } from "../../consts";
import { useSelector, useDispatch } from "react-redux";
import { searchSelector } from "../../store/selectors/search";

const SearchSection = () => {
  const dispatch = useDispatch();

  const search = useSelector(searchSelector);

  return (
    <Container>
      <PageSearch
        value={search}
        onChange={(newValue) => dispatch(setSearch(newValue))}
        placeholder="Search by keywords..."
      />

      <FiltersArea
        filterTypes={{
          [projectFilterTypes.projectStatus]: null,
          [projectFilterTypes.typeOfIndustry]: null,
          [projectFilterTypes.typeOfInvestment]: null,
          [projectFilterTypes.companyStage]: null,
          // [projectFilterTypes.countryOfCooperation]: null,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 91px;

  @media (max-width: 1920px) {
    margin-bottom: 136px;
  }

  @media (max-width: 1280px) {
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 5px;
  }
`;

export default SearchSection;
