import React from "react";
import styled from "styled-components/macro";
import PaddingBox from "../../Components/PaddingBox";

const Header = () => (
  <Container>
    <Title>Discover endless possibilities to grow... </Title>
    <Image src="/img/rocket-clouds.svg" />
  </Container>
);

const Container = styled(PaddingBox)`
  background: linear-gradient(101.01deg, #d3deff -21.7%, #ffffff 46.47%);
  width: 100%;
  height: 506px;
  position: relative;

  @media (max-width: 1920px) {
    height: 464px;
  }

  @media (max-width: 1280px) {
    height: 400px;
  }

  @media (max-width: 768px) {
    height: 360px;
  }
`;

const Title = styled.div`
  color: #09216a;
  font-weight: 600;
  text-align: center;
  font-size: 50px;
  line-height: 50px;
  margin-top: 265px;
  margin-left: auto;
  margin-right: auto;
  max-width: 560px;

  @media (max-width: 1920px) {
    font-size: 45px;
    line-height: 45px;
    margin-top: 248px;
    max-width: 450px;
  }

  @media (max-width: 1280px) {
    font-size: 30px;
    line-height: 30px;
    margin-top: 200px;
    max-width: 300px;
  }

  @media (max-width: 768px) {
    font-size: 26px;
    line-height: 26px;
    margin-top: 110px;
  }
`;

const Image = styled.img`
  position: absolute;
  right: 150px;
  bottom: 0;
  width: 450px;

  @media (max-width: 1280px) {
    width: 300px;
    right: 0;
  }

  @media (max-width: 768px) {
    width: 250px;
  }
`;

export default Header;
