import { createSlice } from "@reduxjs/toolkit";
import { projectFilterTypes } from "../../consts";

const initialState = {
  [projectFilterTypes.projectStatus]: null,
  [projectFilterTypes.typeOfInvestment]: null,
  [projectFilterTypes.typeOfIndustry]: null,
  [projectFilterTypes.companyStage]: null,
  [projectFilterTypes.countryOfCooperation]: null,
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,

  reducers: {
    setFilter: (state, action) => {
      const { type, data } = action.payload;

      return {
        ...state,
        [type]: data,
      };
    },
    clearFilters: () => initialState,
  },
});
