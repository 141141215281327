import React, { useState } from "react";
import Select from "react-select";
import styled from "styled-components/macro";
import OptionWithCheckbox from "./OptionWithCheckbox";
import FormElementWrapper from "../FormElementWrapper";

const Dropdown = ({
  className,
  isMulti,
  options,
  placeholder,
  value,
  onChange,
  onBlur,
  overrideComponents,
  errorMessage,
  isClearable = true,
  backgroundColor = "#EDF1FF",
  minHeight = "40px",
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const multiOptions = isMulti
    ? {
        isMulti: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        backspaceRemovesValue: false,
      }
    : {};

  const localOverrideComponents = {
    ...(isMulti && { Option: OptionWithCheckbox }),
    ...overrideComponents,
  };

  return (
    <FormElementWrapper
      className={className}
      onBlur={onBlur}
      errorMessage={errorMessage}
    >
      <Container>
        <Select
          options={options}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          styles={getCustomStyles({ isOpen, backgroundColor, minHeight })}
          components={localOverrideComponents}
          onMenuOpen={() => !isOpen && setIsOpen(true)}
          onMenuClose={() => isOpen && setIsOpen(false)}
          isClearable={isClearable}
          {...multiOptions}
          {...restProps}
        />
      </Container>
    </FormElementWrapper>
  );
};

const Container = styled.div`
  width: 100%;
`;

export const getCustomStyles = ({ isOpen, backgroundColor, minHeight }) => ({
  container: (provided) => ({
    ...provided,
    outline: "none",
    fontWeight: 300,
    fontSize: "18px",
    lineHeight: "18px",
    color: "#071B56",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor,
    border: "1px solid #8E9FCB",
    borderRadius: "5px",
    boxShadow: "none",
    cursor: "pointer",
    minHeight,
    ...(isOpen && { borderBottom: "none", borderRadius: "5px 5px 0 0" }),

    ":hover": {
      borderColor: "#8E9FCB",
    },
  }),
  input: (provided) => ({
    ...provided,
    color: "#071B56",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#071B56",
  }),
  multiValue: (provided) => ({
    ...provided,
    flexDirection: "row-reverse",
    backgroundColor: "transparent",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#071B56",
    fontSize: "18px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "1px solid",
    height: "22px",
    width: "20px",
    ":hover": {
      backgroundColor: "transparent",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#8E9FCB",
    justifySelf: "flex-start",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    transform: isOpen && "rotate(180deg)",
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    ...(isOpen && { borderRadius: 0 }),
    boxShadow: "none",
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor,
    border: "1px solid #8E9FCB",
    borderTop: "none",
    borderRadius: "0 0 5px 5px",
    maxHeight: "236px",
  }),
  option: (provided) => ({
    ...provided,
    color: "#071B56",
    fontWeight: 300,
    fontSize: "18px",
    lineHeight: "18px",
    backgroundColor: "transparent",
    padding: "8px 10px",
    cursor: "pointer",

    ":hover": {
      backgroundColor: "#DCE4FF",
    },

    ":active": {
      backgroundColor: "transparent",
    },
  }),
});

export default Dropdown;
