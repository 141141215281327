export const buttonVariants = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  bordered: "bordered",
};

export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const atLeast3CharsRegex = /^.{3,}$/;
export const atLeast8CharsRegex = /^.{8,}$/;

export const facebookRegex =
  /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/;

export const urlRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const linkedInRegex =
  /(ftp|http|https):\/\/?((www|\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/;

export const instagramRegex =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;

export const telegramRegex =
  /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/;

export const twitterRegex =
  /(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/;

export const discordRegex =
  /(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/;

export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export const signUpInvestorSteps = {
  contactInfo: "Contact info",
  companyInfo: "Company info",
};

export const signUpConsultantSteps = {
  contactInfo: "Contact info",
  companyInfo: "Company info",
};

export const signUpCompanyBasicSteps = {
  contactInfo: "Contact info",
  companyType: "Company type",
};

export const signUpCompanyTokenSteps = {
  ...signUpCompanyBasicSteps,
  foundersInfo: "Founders info",
  tokenCompanyInfo: "Company info",
  metricsInfo: "Metrics info",
  listingInfo: "Listing info",
  partnersAndInfluencers: "Partners & influencers",
  tokenExitStrategy: "Exit Strategy",
  videoPitch: "Video pitch",
  dataRoom: "Data room",
};

export const signUpCompanyEquitySteps = {
  ...signUpCompanyBasicSteps,
  foundersInfo: "Founders info",
  equityCompanyInfo: "Company info",
  sharesInfo: "Shares info",
  raisingInfo: "Raising info",
  partnersAndClients: "Partners & clients",
  equityExitStrategy: "Exit strategy",
  videoPitch: "Video pitch",
  dataRoom: "Data room",
};

export const screenSizes = {
  small: 320,
  medium: 768,
  large: 1280,
  xlarge: 1920,
};

export const projectFilterTypes = {
  projectStatus: "projectStatus",
  typeOfInvestment: "typeOfInvestment",
  typeOfIndustry: "typeOfIndustry",
  companyStage: "companyStage",
  countryOfCooperation: "countryOfCooperation",
};

export const modalTypes = {
  investingInterest: "investingInterest",
  applicationSubmitted: "applicationSubmitted",
  investingModal: "investingModal",
  signUpModal: "signUpModal",
  signUpSubmittedModal: "signUpSubmittedModal",
  signInModal: "signInModal",
  forgotPasswordModal: "forgotPasswordModal",
  forgotPasswordSubmittedModal: "forgotPasswordSubmittedModal",
  resetPasswordModal: "resetPasswordModal",
  resetPasswordSubmittedModal: "resetPasswordSubmittedModal",
  initialProcessCompletedModal: "initialProcessCompletedModal",
  enterPhoneNumber: "enterPhoneNumber",
  phoneCodeModal: "phoneCodeModal",
  errorModal: "errorModal",
  completeRegistrationModal: "completeRegistrationModal",
  chooseUserModal: "chooseUserModal",
  equityInvestingModal: "equityInvestingModal",
  equityInvestingStatusModal: "equityInvestingStatusModal",
  stripePaymentModal: "stripePaymentModal",
};

export const projectOpenStatuses = {
  open: "open",
  upcoming: "upcoming",
  closed: "closed",
};

export const queryKeys = {
  investor: "investor",
  company: "company",
  consultant: "consultant",
  usdtBalance: "usdtBalance",
  selectedNetwork: "selectedNetwork",
  userWatchlist: "userWatchlist",
  equityInvestments: "equityInvestments",
  tokenInvestments: "tokenInvestments",
  favorites: "favorites",
  projectWatchList: "projectWatchList",
  companyRelatedToUser: "companyRelatedToUser",
  projectInvestors: "projectInvestors",
  projectInvestments: "projectInvestments",
  currentCompanyInvestors: "currentCompanyInvestors",
  defaultCountryCode: "defaultCountryCode",
  filledRoles: "filledRoles",
  userDBData: "userDBData",
  userProjects: "userProjects",
  onChainProjects: "onChainProjects",
};

export const roles = {
  investor: {
    value: "investor",
    label: "Investor",
  },
  company: {
    value: "company",
    label: "Shareholder",
  },
  consultant: {
    value: "consultant",
    label: "Consultant",
  },
};

export const fundingTypes = {
  token: "token",
  equity: "equity",
};

export const paymentIntents = {
  signupForFundRaising: "SIGNUP_FOR_FUND_RAISING",
  projectInvestment: "PROJECT_INVESTMENT",
};

export const paymentMethods = {
  crypto: "crypto",
  card: "card",
  bank: "bank",
};

export const requestStatus = {
  success: "success",
  error: "error",
};
