import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const AccountLink = ({ text, url }) => <Container to={url}>{text}</Container>;

const Container = styled(Link)`
  width: 100%;
  height: 60px;
  border: 1px solid #d6e0ff;
  color: #000f3b;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    font-weight: 500;
  }
`;

export default AccountLink;
