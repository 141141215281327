import React from "react";
import styled from "styled-components/macro";
import { CardRow, Logo, Name } from "./shared";
import { normalizeDollarAmount } from "../../utils/normalizations";
import { useNavigate } from "react-router-dom";
import { useAuthenticated } from "../../hooks/useAuthenticated";
import { isNil } from "lodash-es";

const UpcomingClosedProject = ({
  logo,
  name,
  totalRaising,
  totalRaised,
  daysAmount,
  maxAllocation,
  projectId,
  isClosed,
}) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthenticated();

  const isSoon = daysAmount < 1;

  return (
    <Container
      onClick={() => isAuthenticated && navigate(`/company-page/${projectId}`)}
    >
      <TopElement isClosed={isClosed}>
        <LogoWrapper>
          <StyledLogo src={logo} />
        </LogoWrapper>

        <StyledName>{name}</StyledName>

        {!isClosed && (
          <DaysBadge isSoon={isSoon}>
            {isSoon ? `Soon` : `In ${daysAmount} days`}
          </DaysBadge>
        )}
      </TopElement>

      <DataRows>
        {!isNil(totalRaising) && (
          <CardRow
            isClosed={isClosed}
            field="Total Raising"
            value={normalizeDollarAmount(totalRaising)}
          />
        )}

        {!isNil(totalRaised) && (
          <CardRow
            isClosed={isClosed}
            field="Total Raised"
            value={normalizeDollarAmount(totalRaised)}
          />
        )}

        <CardRow field="Max Allocation" value={maxAllocation} />
      </DataRows>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 315px;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};

  @media (max-width: 1920px) {
    height: 224px;
  }

  @media (max-width: 768px) {
    height: 178px;
  }
`;

const TopElement = styled.div`
  background-color: #e3eafe;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isClosed }) =>
    isClosed ? "space-around" : "space-between"};
  padding: 20px 0;
  border-radius: 3px 3px 0 0;
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 0;
`;

const StyledName = styled(Name)`
  margin-bottom: 0;
`;

const DaysBadge = styled.div`
  background-color: ${({ isSoon }) => (isSoon ? "white" : "#ff2853")};
  box-shadow: 0 4px 10px 10px rgba(87, 128, 255, 0.1);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isSoon }) => (isSoon ? "#000F3C" : "#ffffff")};
  font-weight: 700;
  margin: 0 auto;

  height: 51px;
  width: 210px;
  font-size: 18px;

  @media (max-width: 1920px) {
    height: 36px;
    width: 149px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    height: 24px;
    width: 78px;
    font-size: 10px;
  }
`;

const DataRows = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 40%;
  padding: 30px 20px;
  justify-content: space-around;
  border-radius: 0 0 3px 3px;

  @media (max-width: 1920px) {
    padding: 20px 10px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default UpcomingClosedProject;
