import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import Header from "./Header";
import Navbar from "../../Components/Section/Navbar";
import OpenProjects from "./OpenProjects";
import Footer from "../../Components/Section/Footer";
import UpcomingProjects from "./UpcomingProjects";
import { setSearch } from "../../store/search/actions";
import { clearFilters } from "../../store/filters/actions";
import SearchSection from "./SearchSection";
import PaddingBox from "../../Components/PaddingBox";
import ClosedProjects from "./ClosedProjects";

const Launchpad = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSearch(""));
      dispatch(clearFilters());
    };
  }, []);

  return (
    <Container>
      <Navbar status={"launchpad"} />
      <Header />
      <OpenProjectsWrapper>
        <SearchSection />
        <OpenProjects />
      </OpenProjectsWrapper>
      <UpcomingProjects />
      <ClosedProjects />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const OpenProjectsWrapper = styled(PaddingBox)`
  flex-direction: column;
  background-color: #eaf0ff;
  padding-top: 58px;
  padding-bottom: 84px;

  @media (max-width: 1920px) {
    padding-top: 65px;
    padding-bottom: 101px;
  }

  @media (max-width: 1280px) {
    padding-top: 51px;
    padding-bottom: 60px;
  }

  @media (max-width: 768px) {
    padding-top: 37px;
    padding-bottom: 49px;
  }
`;

export default Launchpad;
