import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  buttonVariants,
  paymentIntents,
  paymentMethods,
} from "../../../../consts";
import Button from "../../../core/Button";
import CurrencyInput from "react-currency-input-field";
import { makePayment } from "../../../../utils/api/mutations";
import { ModalContent } from "../../shared";
import EquityInvestingStatusModal from "../../EquityInvestingStatusModal";
import { useNavigate } from "react-router-dom";

export default function StripePaymentForm({ amount, projectId }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState(null);

  const showError = () => {
    navigate(`/company-page/${projectId}/error`);
  };

  const showSuccess = () => {
    navigate(`/company-page/${projectId}/success`);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();

    if (submitError) {
      return;
    }

    setLoading(true);

    // Create the PaymentMethod using the details collected by the Payment Element
    const data = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: billingDetails,
      },
    });

    const { error, paymentMethod } = data;
    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      showError();
      return;
    }

    const response = await makePayment({
      paymentMethod: paymentMethods.card,
      paymentMethodId: paymentMethod.id,
      projectId,
      amount,
      intent: paymentIntents.projectInvestment,
      returnUrl: window.location.href,
    });

    // // Handle any next actions or errors. See the Handle any next actions step for implementation.
    handleServerResponse(response?.data);
  };

  const handleServerResponse = async (response) => {
    if (!response || response.error || response.status === "failed") {
      showError();
      // Show error from server on payment form
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      const { error, paymentIntent } = await stripe.handleNextAction({
        clientSecret: response.client_secret,
      });

      if (error) {
        showError();
        // Show error from Stripe.js in payment form
      } else {
        showSuccess();
        // Actions handled, show success message
      }
    } else {
      showSuccess();

      // No actions needed, show success message
    }
  };

  const handleAddressChange = (event) => {
    if (event.complete) {
      setBillingDetails(event.value);
    }
  };

  return (
    <>
      <StyledEquityInvestingStatusModal loading={loading} />

      <Container loading={loading}>
        <Form onSubmit={handleSubmit}>
          <StyledPaymentElement />

          <AddressElement
            options={{ mode: "billing" }}
            onChange={handleAddressChange}
          />

          <Footer>
            <Amount>
              <Label>Investment amount ($):</Label>

              <Input value={amount} prefix={"$"} disabled />
            </Amount>

            <StyledButton
              variant={buttonVariants.primary}
              type="submit"
              disabled={!stripe || loading}
            >
              Transfer Investment
            </StyledButton>
          </Footer>
        </Form>
      </Container>
    </>
  );
}

const Container = styled(ModalContent)`
  padding: 90px 30px 40px 30px;
  background-color: white;
  height: ${({ loading }) => (loading ? "376px" : "80vh")};
  visibility: ${({ loading }) => (loading ? "hidden" : "visible")};
`;

const StyledEquityInvestingStatusModal = styled(EquityInvestingStatusModal)`
  position: absolute;
  visibility: ${({ loading }) => (loading ? "visible" : "hidden")};
  z-index: 1;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 0 10px;
`;

const StyledPaymentElement = styled(PaymentElement)`
  margin-bottom: 10px;
`;

const Amount = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  margin-top: 20px;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: 600;
  flex-shrink: 0;
`;

const Input = styled(CurrencyInput)`
  height: 61px;
  text-align: center;
  width: 100%;
  background: #f1f4ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
`;

const Footer = styled.div`
  margin-top: auto;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;
