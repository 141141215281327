// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const {
  REACT_APP_apiKey: apiKey,
  REACT_APP_authDomain: authDomain,
  REACT_APP_projectId: projectId,
  REACT_APP_storageBucket: storageBucket,
  REACT_APP_appId: appId,
  REACT_APP_messagingSenderId: messagingSenderId,
  REACT_APP_measurementId: measurementId,
} = process.env;
// Initialize Firebase
export const app = initializeApp({
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
});
const database = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app, "us-central1");

if (
  process.env.REACT_APP_NODE_ENV === "development" &&
  process.env.REACT_APP_ENABLE_EMULATOR === "true"
) {
  connectFirestoreEmulator(database, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(auth, "http://localhost:9099");
}
export { auth, functions };
export default database;
