import { investorSignUpSlice } from "./slice";
const { actions: slice } = investorSignUpSlice;

export const setInvestorCompletedSteps = (completedSteps) => (dispatch) => {
  dispatch(slice.setInvestorCompletedSteps(completedSteps));
};

export const setInvestorContactInfo = (contactInfo) => (dispatch) => {
  dispatch(slice.setInvestorContactInfo(contactInfo));
};

export const setInvestorCompanyInfo = (companyInfo) => (dispatch) => {
  dispatch(slice.setInvestorCompanyInfo(companyInfo));
};

export const resetInvestorSignup = () => (dispatch) => {
  dispatch(slice.resetInvestorSignup());
};
