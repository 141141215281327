import { useQuery } from "@tanstack/react-query";
import { useERC20Contract } from "../../../hooks/useERC20";
import { queryKeys } from "../../../consts";

export const useUSDTBalance = (queryOptions = {}) => {
  const { usdtAmount } = useERC20Contract(
    process.env.REACT_APP_APP_USDT_ADDRESS
  );

  return useQuery([queryKeys.usdtBalance], () => usdtAmount(), queryOptions);
};
