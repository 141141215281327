import React from "react";
import styled from "styled-components/macro";
import { Controller } from "react-hook-form";
import Input from "../../../../Components/core/formElements/Input";
import { getFieldError } from "../../../../utils/formUtils";
import TextArea from "../../../../Components/core/formElements/TextArea";
import ValidateIcon from "../../../../Components/core/icons/ValidateIcon";
import { atLeast3CharsRegex } from "../../../../consts";

const Founder = ({ index, control, remove }) => (
  <Container>
    <FounderIndex>Founder {index + 1}</FounderIndex>

    <Controller
      name={`founders.${index}.name`}
      control={control}
      rules={{ required: true }}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <Input
          placeholder="Name"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          errorMessage={getFieldError({
            error,
            fieldLabel: "Name",
          })}
        />
      )}
    />

    <Controller
      name={`founders.${index}.role`}
      control={control}
      rules={{ required: true }}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <Input
          placeholder="Role"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          errorMessage={getFieldError({
            error,
            fieldLabel: "Role",
          })}
        />
      )}
    />

    <Controller
      name={`founders.${index}.background`}
      control={control}
      rules={{ required: true }}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <TextArea
          placeholder="Background"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          errorMessage={getFieldError({
            error,
            fieldLabel: "Background",
          })}
        />
      )}
    />

    <Controller
      name={`founders.${index}.linkedIn`}
      control={control}
      rules={{ required: true, pattern: atLeast3CharsRegex }}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => {
        const regex = new RegExp(atLeast3CharsRegex);
        const isValid = regex.test(value);

        return (
          <Input
            placeholder="LinkedIn"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            InnerComponent={() => <ValidateIcon isValid={isValid} />}
            errorMessage={getFieldError({
              error,
              fieldLabel: "LinkedIn",
            })}
          />
        );
      }}
    />

    {index > 0 && (
      <DeleteWrapper onClick={() => remove(index)}>
        <Icon src="/img/trash.svg" />

        <Text>Delete</Text>
      </DeleteWrapper>
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FounderIndex = styled.div`
  font-size: 19px;
  font-weight: 500;
  color: #021652; ;
`;

const DeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const Text = styled.div`
  color: #021652;
  font-weight: 500;
  font-size: 16px;
`;

export default Founder;
