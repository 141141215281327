import React from "react";
import moment from "moment";
import styled from "styled-components/macro";
import { map, slice } from "lodash";
import PaddingBox from "../../Components/PaddingBox";
import Socials from "./Socials";
import BlueBoxes from "./BlueBoxes";
import {
  filterArrayEmptyValues,
  normalizeDollarAmount,
  normalizeNumber,
} from "../../utils/normalizations";
import { toLower } from "lodash-es";
import { fundingTypes } from "../../consts";

// @TODO remove default values
const SharesPoolDetails = ({ project }) => {
  const {
    fundingType,
    listingPrice,
    listingDate,
    listingExchange,
    listingStockExchange,
    symbol,
    tokenPrice,
    finalPrice,
    totalSupply,
    currentValuation,
    discountedValuation,
    investors,
    listingYear,
    lastRaiseDate,
    nextRoundDate,
    potentialValuation,
    numberOfClientsUsers,
  } = project;

  const isToken = toLower(fundingType) === fundingTypes.token;
  const formattedListingDate =
    !!listingDate && moment(listingDate).format("DD.MM.YY");
  const formattedLastRaiseDate =
    !!lastRaiseDate && moment(lastRaiseDate).format("DD.MM.YY");
  const formattedNextRoundDate = moment(nextRoundDate).format("DD.MM.YY");
  const filteredInvestors = filterArrayEmptyValues(slice(investors, 0, 3));
  const investorsAmount = filteredInvestors.length;

  const getTitleText = () => (isToken ? "Pool Details" : "Shares Info");

  return (
    <Container>
      <TextAndIcons>
        <div>
          <Title>{getTitleText()}</Title>
          <RowsWrapper>
            <Row>
              <Field>{isToken ? "Token Price" : "Share Price"}</Field>
              <Value>
                {normalizeDollarAmount(isToken ? tokenPrice : finalPrice)}
              </Value>
            </Row>

            {!isToken && (
              <>
                <Row>
                  <Field>Discounted Valuation</Field>
                  <Value>{normalizeDollarAmount(discountedValuation)}</Value>
                </Row>

                <Row>
                  <Field>Current Valuation</Field>
                  <Value>{normalizeDollarAmount(currentValuation)}</Value>
                </Row>

                {!!formattedLastRaiseDate && (
                  <Row>
                    <Field>Last Round</Field>
                    <Value>{formattedLastRaiseDate}</Value>
                  </Row>
                )}

                {!!nextRoundDate && (
                  <Row>
                    <Field>Next Round</Field>
                    <Value>{formattedNextRoundDate}</Value>
                  </Row>
                )}

                <Row>
                  <Field>Listing Year</Field>
                  <Value>{listingYear}</Value>
                </Row>

                <Row>
                  <Field>Potential Valuation</Field>
                  <Value>{normalizeDollarAmount(potentialValuation)}</Value>
                </Row>
              </>
            )}

            {isToken && (
              <Row>
                <Field>Listing Price</Field>
                <Value>{normalizeDollarAmount(listingPrice)}</Value>
              </Row>
            )}

            <Row>
              <Field>
                {isToken ? "Listing Exchange" : "Listing Stock Exchange"}
              </Field>
              <Value>{isToken ? listingExchange : listingStockExchange}</Value>
            </Row>

            {!!formattedListingDate && (
              <Row>
                <Field>Listing Date</Field>
                <Value>{formattedListingDate}</Value>
              </Row>
            )}

            {!!numberOfClientsUsers && (
              <Section>
                <Row>
                  <Field>Clients / User amount</Field>
                  <Value>{normalizeNumber(numberOfClientsUsers)}</Value>
                </Row>
              </Section>
            )}

            {!!investorsAmount && (
              <Section>
                <Row>
                  <Field>Investors List</Field>
                  <Value>{`(Top ${investorsAmount})`}</Value>
                </Row>

                {map(filteredInvestors, (investor, index) => (
                  <SubField key={`${investor}_${index}`}>{investor}</SubField>
                ))}
              </Section>
            )}
          </RowsWrapper>

          {isToken && <Subtitle>Token Information</Subtitle>}

          {isToken && (
            <RowsWrapper>
              <Row>
                <Field>Symbol</Field>
                <Value>{symbol}</Value>
              </Row>
              <Row>
                <Field>Total Supply</Field>
                <Value>{normalizeNumber(totalSupply)}</Value>
              </Row>
            </RowsWrapper>
          )}

          <StyledSocials project={project} />
        </div>
      </TextAndIcons>

      <StyledBlueBoxes project={project} />
    </Container>
  );
};

const Container = styled(PaddingBox)`
  color: #09216a;
  background-color: #dee6ff;
  display: flex;
  flex-direction: row;
  padding-top: 110px;
  padding-bottom: 110px;
  justify-content: space-between;

  @media (max-width: 1920px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1280px) {
    padding-top: 88px;
    padding-bottom: 88px;
  }

  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const TextAndIcons = styled.div`
  max-width: 557px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: 1921px) {
    text-align: start;
  }
`;

const RowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(9, 33, 106, 0.2);

  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Field = styled.div`
  font-weight: 600;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SubField = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Value = styled.div`
  font-weight: 400;
  text-align: end;
`;

const Subtitle = styled.div`
  color: #09216a;
  font-weight: 400;
  font-size: 18px;
  margin: 25px 0;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const StyledSocials = styled(Socials)`
  margin-top: 53px;
  margin-bottom: 80px;
`;

const StyledBlueBoxes = styled(BlueBoxes)`
  @media (min-width: 1921px) {
    padding-top: 52px;
  }
`;

export default SharesPoolDetails;
