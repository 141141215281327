import { createSlice } from "@reduxjs/toolkit";

const initialState = "";

export const searchSlice = createSlice({
  name: "search",
  initialState,

  reducers: {
    setSearch: (state, action) => action.payload,
  },
});
