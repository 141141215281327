import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./sass/main.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomePage from "./Components/HomePage";
import CompanyInfo from "./Components/CompanyInfo";
import PartnersInfluencers from "./Components/PartnersInfluencers";
import Congratulations from "./Components/Congratulations";
import { useDispatch } from "react-redux";
import { fetchOnChainProjects } from "./helpers/HttpService";
import { setProjects } from "./store/projects/actions";
import { Toaster } from "react-hot-toast";
import "reactjs-popup/dist/index.css";
import SaleWillStartSoon from "./Components/SaleWillStartSoon";
import About from "./Components/About";
import ScrollToTop from "./Components/scrollToTop";
import Components from "./pages/Components";
import CompleteSignUp from "./pages/completeSignUp/CompleteSignUp";
import InvestorContactInfo from "./pages/completeSignUp/investor/InvestorContactInfo";
import Company from "./pages/completeSignUp/company/Company";
import InvestorCompanyInfo from "./pages/completeSignUp/investor/InvestorCompanyInfo";
import CompanyContactInfo from "./pages/completeSignUp/company/CompanyContactInfo";
import CompanyType from "./pages/completeSignUp/company/CompanyType";
import FoundersInfo from "./pages/completeSignUp/company/foundersInfo/FoundersInfo";
import TokenCompanyInfo from "./pages/completeSignUp/company/tokenFlow/TokenCompanyInfo";
import MetricsInfo from "./pages/completeSignUp/company/tokenFlow/MetricsInfo";
import ListingInfo from "./pages/completeSignUp/company/tokenFlow/ListingInfo";
import PartnersAndInfluencers from "./pages/completeSignUp/company/tokenFlow/PartnersAndInfluencers";
import TokenExitStrategy from "./pages/completeSignUp/company/tokenFlow/TokenExitStrategy";
import VideoPitch from "./pages/completeSignUp/company/VideoPitch";
import EquityCompanyInfo from "./pages/completeSignUp/company/equityFlow/EquityCompanyInfo";
import SharesInfo from "./pages/completeSignUp/company/equityFlow/SharesInfo";
import RaisingInfo from "./pages/completeSignUp/company/equityFlow/RaisingInfo";
import PartnersAndClients from "./pages/completeSignUp/company/equityFlow/PartnersAndClients";
import EquityExitStrategy from "./pages/completeSignUp/company/equityFlow/EquityExitStrategy";
import Investor from "./pages/completeSignUp/investor/Investor";
import CompanyPage from "./pages/companyPage/CompanyPage";
import { DALServiceV2 } from "./services/data-access-layer.service";
import Modal from "./Components/modal/Modal";
import Launchpad from "./pages/launchpad/Launchpad";
import Account from "./pages/account/Account";
import Investments from "./pages/account/investments/Investments";
import Favorites from "./pages/account/favorites/Favorites";
import ManageProject from "./pages/account/manageProject/ManageProject";
import InvestorsWatchlist from "./pages/account/investorsWatchlist/InvestorsWatchlist";
import { PrivateRoute } from "./Components/routeWrappers/PrivateRoute";
import { NoRoleRoute } from "./Components/routeWrappers/NoRoleRoute";
import Temp from "./Components/Temp";
import CompleteRegistrationPopup from "./Components/CompleteRegistrationPopup";
import { AuthService } from "./services/auth.service";
import { setUserData } from "./store/app/actions";
import DataRoom from "./pages/completeSignUp/company/DataRoom";
import Consultant from "./pages/completeSignUp/consultant/Consultant";
import ConsultantContactInfo from "./pages/completeSignUp/consultant/ConsultantContactInfo";
import ConsultantCompanyInfo from "./pages/completeSignUp/consultant/ConsultantCompanyInfo";
import Initializer from "./Components/Initializer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const currentUser = AuthService.auth.currentUser;

  const init = async () => {
    try {
      const promises = await Promise.all([
        fetchOnChainProjects(),
        DALServiceV2.getVerifiedProjects(),
      ]);

      dispatch(setProjects(promises[0], promises[1]));
    } catch (e) {
      console.error(e);
    } finally {
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(setUserData(currentUser));
    }
  }, [currentUser]);

  const router = useMemo(() => {
    const public_routes = [
      { path: "/", element: <HomePage /> },
      { path: "/_temp", element: <Temp /> },
      { path: "/sale-will-start-soon", element: <SaleWillStartSoon /> },
      { path: "/company-info", element: <CompanyInfo /> },
      { path: "/partnersin-fluencers", element: <PartnersInfluencers /> },
      { path: "/congratulations", element: <Congratulations /> },
      { path: "/about", element: <About /> },
    ];

    let routes = [...public_routes];
    if (process.env.REACT_APP_NODE_ENV !== "production") {
      const dev_routes = [{ path: "/components", element: <Components /> }];
      routes = [...routes, ...dev_routes];
    }
    return routes;
  }, []);

  return (
    isInitialized && (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />

        <div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>

        <BrowserRouter>
          <Modal />
          <CompleteRegistrationPopup />
          <Initializer />

          <ScrollToTop />
          <Routes>
            {router.map((el, ind) => (
              <Route key={ind} path={el.path} element={el.element} />
            ))}
            <Route path="/sign-up" element={<CompleteSignUp />} />
            <Route path="/sign-up" element={<NoRoleRoute />}>
              <Route path="investor" element={<Investor />}>
                <Route path="contact-info" element={<InvestorContactInfo />} />
                <Route path="company-info" element={<InvestorCompanyInfo />} />
              </Route>

              <Route path="consultant" element={<Consultant />}>
                <Route
                  path="contact-info"
                  element={<ConsultantContactInfo />}
                />
                <Route
                  path="company-info"
                  element={<ConsultantCompanyInfo />}
                />
              </Route>

              <Route path="company" element={<Company />}>
                <Route path="contact-info" element={<CompanyContactInfo />} />
                <Route path="company-type" element={<CompanyType />} />
                <Route path="founders-info" element={<FoundersInfo />} />
                <Route path="video-pitch" element={<VideoPitch />} />

                {/*Token Company Routes*/}
                <Route
                  path="token-company-info"
                  element={<TokenCompanyInfo />}
                />
                <Route path="metrics-info" element={<MetricsInfo />} />
                <Route path="listing-info" element={<ListingInfo />} />
                <Route
                  path="partners-and-influencers"
                  element={<PartnersAndInfluencers />}
                />
                <Route
                  path="token-exit-strategy"
                  element={<TokenExitStrategy />}
                />

                <Route
                  path="equity-company-info"
                  element={<EquityCompanyInfo />}
                />
                <Route path="shares-info" element={<SharesInfo />} />
                <Route path="raising-info" element={<RaisingInfo />} />
                <Route
                  path="partners-and-clients"
                  element={<PartnersAndClients />}
                />
                <Route
                  path="equity-exit-strategy"
                  element={<EquityExitStrategy />}
                />
                <Route path="data-room" element={<DataRoom />} />
              </Route>
            </Route>

            <Route path="/account" element={<PrivateRoute />}>
              <Route path="" element={<Account />} />
              <Route path="investments" element={<Investments />} />
              <Route path="favorites" element={<Favorites />} />

              <Route
                path=":projectId/manage-project"
                element={<ManageProject />}
              />
              <Route
                path=":projectId/investors-watchlist"
                element={<InvestorsWatchlist />}
              />
            </Route>

            <Route path="company-page" element={<PrivateRoute />}>
              <Route path=":id" element={<CompanyPage />} />
              <Route path=":id/:paymentStatus" element={<CompanyPage />} />
            </Route>

            <Route path="launchpad" element={<Launchpad />} />

            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    )
  );
};

export default App;
