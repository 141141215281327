import React from "react";
import styled from "styled-components/macro";
import Box from "../../Components/project-details/components/box/box";
import { toLower } from "lodash-es";
import { fundingTypes } from "../../consts";

const BlueBoxes = ({ className, project }) => {
  const {
    fundingType,
    businessPlan,
    website,
    pitchDeck,
    videoPitch,
    tokenomics,
    whitepaper,
    financialRecords,
  } = project;

  const isToken = toLower(fundingType) === fundingTypes.token;

  return (
    <Container className={className}>
      <Row>
        <Box
          onClick={() => window.open(isToken ? whitepaper : businessPlan)}
          title={isToken ? "whitepaper" : "business plan"}
          backgroundColor={"#213C8F"}
          onHoverBackgroundColor={"#FF2853"}
          iconColor={"#FF2853"}
          onHoverIconColor={"white"}
          icon={() =>
            isToken ? (
              <img src="/img/whitepaper.svg" />
            ) : (
              <img src="/img/business-plan.svg" />
            )
          }
        />
        <Box
          onClick={() => window.open(website)}
          title={"website"}
          backgroundColor={"#09216A"}
          onHoverBackgroundColor={"#FF2853"}
          iconColor={"#FF2853"}
          onHoverIconColor={"white"}
          icon={({ color }) => (
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.668 15.6C18.772 14.742 18.85 13.884 18.85 13C18.85 12.116 18.772 11.258 18.668 10.4H23.062C23.27 11.232 23.4 12.103 23.4 13C23.4 13.897 23.27 14.768 23.062 15.6H18.668ZM16.367 22.828C17.147 21.385 17.745 19.825 18.161 18.2H21.996C20.7365 20.3688 18.7383 22.0116 16.367 22.828ZM16.042 15.6H9.958C9.828 14.742 9.75 13.884 9.75 13C9.75 12.116 9.828 11.245 9.958 10.4H16.042C16.159 11.245 16.25 12.116 16.25 13C16.25 13.884 16.159 14.742 16.042 15.6ZM13 23.348C11.921 21.788 11.05 20.059 10.517 18.2H15.483C14.95 20.059 14.079 21.788 13 23.348ZM7.8 7.8H4.004C5.25051 5.62538 7.24723 3.97993 9.62 3.172C8.84 4.615 8.255 6.175 7.8 7.8ZM4.004 18.2H7.8C8.255 19.825 8.84 21.385 9.62 22.828C7.25221 22.0112 5.2583 20.368 4.004 18.2ZM2.938 15.6C2.73 14.768 2.6 13.897 2.6 13C2.6 12.103 2.73 11.232 2.938 10.4H7.332C7.228 11.258 7.15 12.116 7.15 13C7.15 13.884 7.228 14.742 7.332 15.6H2.938ZM13 2.639C14.079 4.199 14.95 5.941 15.483 7.8H10.517C11.05 5.941 11.921 4.199 13 2.639ZM21.996 7.8H18.161C17.7541 6.18989 17.1516 4.63566 16.367 3.172C18.759 3.991 20.748 5.642 21.996 7.8ZM13 0C5.811 0 0 5.85 0 13C0 16.4478 1.36964 19.7544 3.80761 22.1924C5.01477 23.3995 6.44788 24.3571 8.02512 25.0104C9.60235 25.6637 11.2928 26 13 26C16.4478 26 19.7544 24.6304 22.1924 22.1924C24.6304 19.7544 26 16.4478 26 13C26 11.2928 25.6637 9.60235 25.0104 8.02512C24.3571 6.44788 23.3995 5.01477 22.1924 3.80761C20.9852 2.60045 19.5521 1.64288 17.9749 0.989566C16.3977 0.336255 14.7072 0 13 0Z"
                fill={color}
              />
            </svg>
          )}
        />
        <Box
          onClick={() => window.open(pitchDeck)}
          title={"pitch deck"}
          backgroundColor={"#213C8F"}
          onHoverBackgroundColor={"#FF2853"}
          iconColor={"#FF2853"}
          onHoverIconColor={"white"}
          icon={() => <img src="/img/pitch-deck-logo.svg" />}
        />
      </Row>

      <Row>
        <Box
          onClick={() => window.open(videoPitch)}
          title={"video pitch"}
          backgroundColor={"#213C8F"}
          onHoverBackgroundColor={"#FF2853"}
          iconColor={"#FF2853"}
          onHoverIconColor={"white"}
          icon={() => <img src="/img/video-pitch-logo.svg" />}
        />
        {tokenomics && (
          <Box
            onClick={() => window.open(tokenomics)}
            title={"tokenomics"}
            backgroundColor={"#09216A"}
            onHoverBackgroundColor={"#FF2853"}
            iconColor={"#FF2853"}
            onHoverIconColor={"white"}
            icon={({ color }) => (
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.336 11.6584C23.4365 11.3371 23.4915 11.0033 23.4993 10.6667V6.00008C23.4993 2.67508 18.4827 0.166748 11.8327 0.166748C5.18268 0.166748 0.166016 2.67508 0.166016 6.00008V10.6667C0.230327 11.444 0.493851 12.1915 0.931165 12.8373C1.36848 13.4831 1.96483 14.0052 2.66268 14.3534C2.55556 14.6691 2.5004 15.0001 2.49935 15.3334V20.0001C2.49935 23.3251 7.51602 25.8334 14.166 25.8334C20.816 25.8334 25.8327 23.3251 25.8327 20.0001V15.3334C25.7698 14.5573 25.5067 13.8107 25.0691 13.1666C24.6315 12.5225 24.0344 12.0028 23.336 11.6584ZM11.8327 16.5001C16.3243 16.5001 20.0693 15.3334 22.0293 13.5834C22.9627 14.1434 23.4993 14.7501 23.4993 15.3334C23.4993 16.7567 19.8593 18.8334 14.166 18.8334C8.47268 18.8334 4.93768 16.8151 4.83268 15.3917C7.08499 16.1607 9.453 16.5356 11.8327 16.5001ZM11.8327 2.50008C17.526 2.50008 21.166 4.57675 21.166 6.00008C21.166 7.42341 17.526 9.50008 11.8327 9.50008C6.13935 9.50008 2.49935 7.42341 2.49935 6.00008C2.49935 4.57675 6.13935 2.50008 11.8327 2.50008ZM2.49935 9.57008C5.33689 11.1842 8.57094 11.9685 11.8327 11.8334C15.0944 11.9685 18.3285 11.1842 21.166 9.57008V10.6667C21.166 12.0901 17.526 14.1667 11.8327 14.1667C6.13935 14.1667 2.49935 12.0901 2.49935 10.6667V9.57008ZM14.166 23.5001C8.47268 23.5001 4.83268 21.4234 4.83268 20.0001V18.9034C7.67023 20.5176 10.9043 21.3018 14.166 21.1667C17.4278 21.3018 20.6618 20.5176 23.4993 18.9034V20.0001C23.4993 21.4234 19.8593 23.5001 14.166 23.5001Z"
                  fill={color}
                />
              </svg>
            )}
          />
        )}
        {financialRecords && (
          <Box
            onClick={() => window.open(financialRecords)}
            title={"Financial Records"}
            backgroundColor={"#09216A"}
            onHoverBackgroundColor={"#FF2853"}
            iconColor={"#FF2853"}
            onHoverIconColor={"white"}
            icon={({ color }) => (
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.336 11.6584C23.4365 11.3371 23.4915 11.0033 23.4993 10.6667V6.00008C23.4993 2.67508 18.4827 0.166748 11.8327 0.166748C5.18268 0.166748 0.166016 2.67508 0.166016 6.00008V10.6667C0.230327 11.444 0.493851 12.1915 0.931165 12.8373C1.36848 13.4831 1.96483 14.0052 2.66268 14.3534C2.55556 14.6691 2.5004 15.0001 2.49935 15.3334V20.0001C2.49935 23.3251 7.51602 25.8334 14.166 25.8334C20.816 25.8334 25.8327 23.3251 25.8327 20.0001V15.3334C25.7698 14.5573 25.5067 13.8107 25.0691 13.1666C24.6315 12.5225 24.0344 12.0028 23.336 11.6584ZM11.8327 16.5001C16.3243 16.5001 20.0693 15.3334 22.0293 13.5834C22.9627 14.1434 23.4993 14.7501 23.4993 15.3334C23.4993 16.7567 19.8593 18.8334 14.166 18.8334C8.47268 18.8334 4.93768 16.8151 4.83268 15.3917C7.08499 16.1607 9.453 16.5356 11.8327 16.5001ZM11.8327 2.50008C17.526 2.50008 21.166 4.57675 21.166 6.00008C21.166 7.42341 17.526 9.50008 11.8327 9.50008C6.13935 9.50008 2.49935 7.42341 2.49935 6.00008C2.49935 4.57675 6.13935 2.50008 11.8327 2.50008ZM2.49935 9.57008C5.33689 11.1842 8.57094 11.9685 11.8327 11.8334C15.0944 11.9685 18.3285 11.1842 21.166 9.57008V10.6667C21.166 12.0901 17.526 14.1667 11.8327 14.1667C6.13935 14.1667 2.49935 12.0901 2.49935 10.6667V9.57008ZM14.166 23.5001C8.47268 23.5001 4.83268 21.4234 4.83268 20.0001V18.9034C7.67023 20.5176 10.9043 21.3018 14.166 21.1667C17.4278 21.3018 20.6618 20.5176 23.4993 18.9034V20.0001C23.4993 21.4234 19.8593 23.5001 14.166 23.5001Z"
                  fill={color}
                />
              </svg>
            )}
          />
        )}
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;

  gap: 10px;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

export default BlueBoxes;
