import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as Icon } from "../assets/v-icon.svg";

const VerticalStepsLine = ({
  steps,
  completedSteps,
  currentStep,
  stepTopPadding = 12,
}) => (
  <Container>
    <VerticalLine stepTopPadding={stepTopPadding} />

    <StepsWrapper>
      {steps.map((step) => {
        const isCurrentStep = step === currentStep;
        const isCompletedStep = completedSteps.includes(step);

        return (
          <Step
            key={step}
            isCurrentStep={isCurrentStep}
            stepTopPadding={stepTopPadding}
          >
            <Circle
              isCompletedStep={isCompletedStep}
              isCurrentStep={isCurrentStep}
            >
              {isCompletedStep && <StyledIcon />}
            </Circle>

            <Text>{step}</Text>
          </Step>
        );
      })}
    </StepsWrapper>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const VerticalLine = styled.div`
  position: absolute;
  top: ${({ stepTopPadding }) => stepTopPadding}px;
  left: 26px;
  height: calc(100% - ${({ stepTopPadding }) => stepTopPadding * 2}px);
  border-left: 2px dashed #c9d8ff;
`;

const StepsWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 50px;
  }
`;

const Step = styled.div`
  display: flex;
  padding: ${({ stepTopPadding }) => stepTopPadding}px 14px;
  background-color: ${({ isCurrentStep }) =>
    isCurrentStep ? "#112358" : "transparent"};
  align-items: center;
`;

const Circle = styled.div`
  width: 27px;
  height: 27px;
  margin-right: 27px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  flex-shrink: 0;

  background-color: ${({ isCompletedStep, isCurrentStep }) => {
    if (isCurrentStep) {
      return "#5781FF";
    }

    if (isCompletedStep) {
      return "#8EAAFD";
    }

    return "white";
  }};
`;

const StyledIcon = styled(Icon)`
  fill: white;
`;

const Text = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: white;
  white-space: nowrap;
`;

export default VerticalStepsLine;
