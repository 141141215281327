import { useQuery } from "@tanstack/react-query";
import { fetchOnChainProjects } from "../../../helpers/HttpService";
import { queryKeys } from "../../../consts";

export const useOnChainProjects = (options = {}) =>
  useQuery({
    queryKey: queryKeys.onChainProjects,
    queryFn: fetchOnChainProjects,
    ...options,
  });
