export const getFieldError = ({ error, fieldLabel }) => {
  if (!error) {
    return "";
  }

  const { type } = error;

  switch (type) {
    case "required":
      return `${fieldLabel} is ${error.type}`;

    case "pattern":
      return `${fieldLabel} is wrong`;

    default:
      return "Wrong value";
  }

  return;
};
