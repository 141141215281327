import React from "react";
import styled, { css } from "styled-components/macro";
import { buttonVariants } from "../../consts";

const Button = ({
  className,
  onClick,
  type = "button",
  children,
  variant,
  width = "100%",
  height = "38px",
  borderRadius = "7px",
  border = "none",
  color,
  disabled,
}) => (
  <Container
    className={className}
    onClick={(e) => {
      if (!disabled && onClick) {
        onClick(e);
      }
    }}
    variant={variant}
    type={type}
    width={width}
    height={height}
    border={border}
    borderRadius={borderRadius}
    color={color}
    disabled={disabled}
  >
    {children}
  </Container>
);

const Container = styled.button`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  width: ${({ width }) => width};
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ border }) => border};
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: background-color 0.3s ease-in;
  flex-shrink: 0;

  ${({ variant, color, disabled }) => {
    switch (variant) {
      case buttonVariants.primary:
        if (disabled) {
          return css`
            background: #ff2853;
            color: white;
            opacity: 0.5;
            pointer-events: none;
          `;
        }

        return css`
          background: #ff2853;
          color: white;

          &:hover {
            background: white;
            box-shadow: 0 0 13px 3px rgba(88, 128, 255, 0.2);
            color: #ff2853;
          }
        `;

      case buttonVariants.secondary:
        return css`
          background: white;
          box-shadow: 0 0 12px 3px rgba(88, 128, 255, 0.15);
          color: #021652;
        `;

      case buttonVariants.tertiary:
        return css`
          background: #2d4798;
          color: white;
          fill: white;

          &:hover {
            background: white;
            box-shadow: 0 0 13px 3px rgba(88, 128, 255, 0.2);
            color: #000f3c;
            fill: #000f3c;
          }
        `;

      case buttonVariants.bordered:
        return css`
          background-color: transparent;
          border: 1px solid ${color};
          color: ${color};
        `;

      default:
        return "";
    }
  }}
`;

export default Button;
