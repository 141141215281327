import React from "react";
import Dropdown from "./dropdown/Dropdown";
import { map } from "lodash-es";
import countriesData from "../../../assets/countriesData.json";
import OptionWithPhoneCode from "./dropdown/OptionWithPhoneCode";
import SingleValuePhoneCode from "./dropdown/SigleValuePhoneCode";

const options = map(countriesData, ({ phone, code }) => ({
  label: `+${phone}`,
  value: `+${phone}`,
  meta: { code },
}));

const overrideComponents = {
  Option: OptionWithPhoneCode,
  SingleValue: SingleValuePhoneCode,
};

const PhoneCodeDropdown = ({
  className,
  value,
  onChange,
  onBlur,
  errorMessage,
  backgroundColor,
  minHeight,
}) => (
  <Dropdown
    value={value}
    onChange={onChange}
    className={className}
    onBlur={onBlur}
    errorMessage={errorMessage}
    placeholder="Code"
    options={options}
    overrideComponents={overrideComponents}
    isClearable={false}
    backgroundColor={backgroundColor}
    minHeight={minHeight}
  />
);

export default PhoneCodeDropdown;
