import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { resetConsultantSignup } from "../../../store/consultantSignUp/actions";
import { useRerouteToFormStart } from "../../../hooks/useRerouteToFormStart";
import { consultantSignUpCompletedStepsSelector } from "../../../store/selectors/signUp";

const Consultant = () => {
  const dispatch = useDispatch();
  useRerouteToFormStart({
    formStartUrl: "/sign-up/consultant/contact-info",
    completedStepsSelector: consultantSignUpCompletedStepsSelector,
  });

  useEffect(() => {
    return () => {
      dispatch(resetConsultantSignup());
    };
  }, []);

  return <Outlet />;
};

export default Consultant;
