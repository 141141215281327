import React from "react";
import styled from "styled-components/macro";

const ModalInput = ({
  className,
  label,
  value,
  onChange,
  placeholder,
  name,
  type = "text",
}) => {
  return (
    <Container className={className}>
      <Label>{label}</Label>

      <InputElement
        name={name}
        autoComplete={name ? "on" : "off"}
        type={type}
        placeholder={placeholder}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 13px;
    margin-bottom: 9px;
  }
`;

const InputElement = styled.input`
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #494949;
  background-color: transparent;
  padding: 0 34px;
  border: 1px solid #8692a6;
  border-radius: 6px;
  height: 64px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    height: 46px;
    padding: 0 15px;
  }
`;

export default ModalInput;
