import React, { useCallback, useEffect, useRef, useState } from "react";
import SlideItem from "./SlideItem";
import styled from "styled-components/macro";
import CoinGecko from "coingecko-api";
import Marquee from "react-fast-marquee";
import { useMobile } from "../../../hooks/useMobile";

const SliderNav = () => {
  const { isMobile } = useMobile();
  const [balance, setBalance] = useState([]);

  var func = async () => {
    const CoinGeckoClient = new CoinGecko();
    const data = await CoinGeckoClient.coins.all();
    setBalance(data.data);
  };

  useEffect(() => {
    func();
  }, []);

  return (
    <div className="slider-nav">
      <div className="navbar-slider container-fluid overflow-hidden">
        <StyledMarquee gradient={false} pauseOnHover>
          <div className="d-flex flex-row">
            {balance.map((slider, i) => (
              <SlideItem slider={slider} key={i} />
            ))}
          </div>
        </StyledMarquee>
      </div>
    </div>
  );
};

const StyledMarquee = styled(Marquee)`
  .marquee {
    --duration: 160s !important;
  }
`;

export default SliderNav;
