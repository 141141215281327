import {
  getMultiFactorResolver,
  GoogleAuthProvider,
  PhoneAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { getAuth } from "firebase/auth";
import { ERRORS } from "../config/errors";
import { AuthService } from "./auth.service";
import DALService from "./data-access-layer.service";

const googleProvider = new GoogleAuthProvider();
const dataAccessLayerService = new DALService();

export const signOutWithGoogle = async () => {
  try {
    await signOut(getAuth());
    return;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const fetchUserData = async () => {
  try {
    const documentRef = await dataAccessLayerService.getUserById();
    return documentRef.data();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const signInWithGoogle = async (recaptchaVerifier) => {
  try {
    await signInWithPopup(getAuth(), googleProvider);
    const userData = await dataAccessLayerService.createUser();
    return { userData, is2faActive: false };
  } catch (err) {
    switch (err) {
      case ERRORS.USER_EXISTS:
        return fetchUserData();
      default:
        if (err.code === ERRORS.MULTI_FACTOR_AUTH_REQUIRED) {
          const resolver = getMultiFactorResolver(AuthService.auth, err);
          const phoneNumber = resolver.hints[0].displayName;
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          };
          const phoneAuthProvider = new PhoneAuthProvider(AuthService.auth);
          // Send SMS verification code
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          );
          return {
            phoneNumber,
            verificationId,
            resolver,
            is2faActive: true,
          };
        }
    }
  }
};
