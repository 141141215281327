import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const SaleWillStartSoonSection = () => {
  const { projectId } = useParams();
  const { projects } = useSelector((state) => state);
  const { projectsList } = projects;

  const { swapRate, symbol, description, image, projectName } =
    projectsList[projectId];
  return (
    <div className="counter-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="counter-section-leftside">
              <img src={image} alt="" />
              <h2 className="main-title mt-2 mb-4">{projectName}</h2>
              <p>{description}</p>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 mt-lg-0 mt-4">
            <div className="counter-card token-purchase">
              <p className="couter-title mb-3">Sale will start in</p>
              <button className="main-btn-soon">Soon</button>
              <small className="chacker">
                1 USDT = {swapRate}&nbsp;{symbol}
              </small>
              <div className="couter-progass w-75">
                <p className="text-center">Recruitment progress</p>
                <ProgressBar
                  completed={80}
                  className="mt-3"
                  bgColor="#FF2853"
                  labelColor="white"
                  height="15px"
                />
              </div>
            </div>

            <div className="text-center mt-4">
              <button className="main-btn2">Join Whitelist</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleWillStartSoonSection;
