import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
} from "../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../Components/core/Button";
import {
  atLeast3CharsRegex,
  atLeast8CharsRegex,
  buttonVariants,
  signUpCompanyTokenSteps,
} from "../../../consts";
import {
  setCompanyCompletedSteps,
  setDocumentsLink,
} from "../../../store/companySignUp/actions";
import { ButtonsWrapper, Container, Description, Title } from "./styled";
import Input from "../../../Components/core/formElements/Input";
import { getFieldError } from "../../../utils/formUtils";
import { ReactComponent as Icon } from "../../../assets/download-arrow.svg";
import ValidateIcon from "../../../Components/core/icons/ValidateIcon";
import { downloadLocalFile } from "../../../utils/fileUtils";

const DataRoom = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { documentsLink } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);

  const { handleSubmit, control } = useForm({
    defaultValues: documentsLink,
  });

  const onSubmit = (data) => {
    dispatch(setDocumentsLink(data));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyTokenSteps.dataRoom,
      ])
    );
  };

  const handleBackButton = () => {
    navigate("/sign-up/company/video-pitch");
  };

  const handleDownload = () => {
    downloadLocalFile("/data-room.pdf");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Data room</Title>

      <Description>
        Enter all the needed documents in the link below
      </Description>

      <Subtitle>List of required documents</Subtitle>

      <Text>
        Download the following file to see all the documents you need to add.
      </Text>

      <StyledButton
        variant={buttonVariants.tertiary}
        height="40px"
        width="157px"
        onClick={handleDownload}
      >
        <StyledIcon src="/img/download-arrow.svg" />
        Download
      </StyledButton>

      <Subtitle>Upload Doc link (Google Drive, Drop Box)</Subtitle>

      <Text>
        Make sure that all the documents on the list appear. You need to add
        them to a cloud storage folder. We recommend <b>Google Drive</b> or{" "}
        <b>Drop Box</b>
      </Text>

      <Controller
        name="documentsLink"
        control={control}
        rules={{ required: true, pattern: atLeast3CharsRegex }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) => {
          const regex = new RegExp(atLeast3CharsRegex);
          const isValid = regex.test(value || "");

          return (
            <Input
              placeholder="Enter link"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              InnerComponent={() => <ValidateIcon isValid={isValid} />}
              errorMessage={getFieldError({
                error,
                fieldLabel: "Link",
              })}
            />
          );
        }}
      />

      <StyledButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Submit
        </Button>

        <Button variant={buttonVariants.secondary} onClick={handleBackButton}>
          Back
        </Button>
      </StyledButtonsWrapper>
    </Container>
  );
};

const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-bottom: 3px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 40px;
`;

const Subtitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: #000f3c;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #000f3c;
  margin-bottom: 16px;
`;

const StyledButtonsWrapper = styled(ButtonsWrapper)`
  margin-top: 25px;
`;

export default DataRoom;
