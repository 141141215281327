import { ERC20_ABI } from "../utils/config";
import Web3 from "web3";

export function useERC20Contract(address) {
  const httpProvider = new Web3.providers.HttpProvider(
    process.env.REACT_APP_RPC_URI
  );
  const web3 = new Web3(httpProvider);
  const usdtContract = new web3.eth.Contract(ERC20_ABI, address);

  const account = window.ethereum?.selectedAddress;

  const usdtAmount = async () => {
    const amount = await usdtContract.methods.balanceOf(account).call();
    const decimals = await usdtContract.methods.decimals().call();
    return amount / 10 ** decimals;
  };

  return {
    usdtAmount,
  };
}
