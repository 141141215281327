import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { doc, setDoc } from "firebase/firestore";
import db from "../../../utils/db";
import toast from "react-hot-toast";
import PhoneCodeDropdown from "../../core/formElements/PhoneCodeDropdown";
import { getFieldError } from "../../../utils/formUtils";
import Input from "../../core/formElements/Input";
import styled from "styled-components/macro";
import { useDefaultCountryCode } from "../../../hooks/useDefaultCountryCode";

const InputFiled = ({ close, email, setFadeOut, setEmail }) => {
  const [closeModal, setCloseModal] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const { data: phoneCode } = useDefaultCountryCode();

  useEffect(() => {
    if (phoneCode) {
      setValue("phoneCode", phoneCode);
    }
  }, [phoneCode]);

  const onSubmit = async (data) => {
    try {
      setCloseModal(!closeModal);
      const subscriberDoc = doc(db, "subscribers", data.email);
      await setDoc(subscriberDoc, {
        name: data.name,
        email: data.email,
        phone: data.phoneNumber,
        code: data.phoneCode.value,
      });
      toast.success("Subscribed");
      setTimeout(function () {
        setFadeOut(true);
      }, 1500);
    } finally {
      setTimeout(function () {
        close();
        setEmail && setEmail("");
        setFadeOut(false);
      }, 2100);
    }
  };
  return (
    <>
      {closeModal ? (
        <>
          <div className="close-section">
            <img src="img/animation4.gif" alt="" />
            <h3>Thanks for contacting us!</h3>
            <p>
              We will be happy to keep in touch with you and update you on
              everything that is important
            </p>
            <button onClick={() => close()} className="main-btn mt-sm-3">
              Close
            </button>
          </div>
        </>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="contact-form modal-form"
        >
          <div className="modal-header-wrapper">
            <h2 className="main-title my-1 my-sm-3">Contact info</h2>
          </div>
          <div className="form-group mt-sm-3  ">
            <label htmlFor="name">
              Full Name <span>*</span>
            </label>
            <input
              type="text"
              id="name"
              {...register("name", { required: true })}
              className={errors.name ? "incorrect" : "input"}
              placeholder="your name"
            />
            {errors.name && (
              <span>
                {" "}
                <img src="/img/false-icon.svg" alt="" /> Name is required
              </span>
            )}
          </div>
          <div className="form-group mt-sm-3">
            <label htmlFor="email">
              Email<span>*</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder="example@gmail.com"
              defaultValue={email}
              {...register("email", {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                required: true,
              })}
              className={errors.email ? "incorrect" : "input"}
            />
            {errors.email && (
              <span>
                <img src="/img/false-icon.svg" alt="" />
                Incorrect email
              </span>
            )}
          </div>
          <div className="form-group mt-sm-3 ">
            <label htmlFor="number">
              Phone Number<span>*</span>
            </label>
            <PhoneNumberWrapper>
              <Controller
                name="phoneCode"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => {
                  return (
                    <StyledPhoneCodeDropdown
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={getFieldError({
                        error,
                        fieldLabel: "Code",
                      })}
                    />
                  );
                }}
              />

              <Controller
                name="phoneNumber"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <StyledInput
                    name="phoneNumber"
                    placeholder="Number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    isNumeric
                    isInteger
                    errorMessage={getFieldError({
                      error,
                      fieldLabel: "Phone number",
                    })}
                  />
                )}
              />
            </PhoneNumberWrapper>
          </div>

          <button type="submit" className="main-btn mt-sm-3">
            Subscribe
          </button>
        </form>
      )}
    </>
  );
};

const PhoneNumberWrapper = styled.div`
  display: flex;
`;

const StyledPhoneCodeDropdown = styled(PhoneCodeDropdown)`
  margin-right: 10px;
  flex-shrink: 0;
  max-width: 120px;
`;

const StyledInput = styled(Input)`
  .input-container {
    background: inherit;
    border: none;
    padding: inherit;
  }
`;

export default InputFiled;
