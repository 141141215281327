import React from "react";
import styled from "styled-components/macro";
import { normalizeDollarAmountWithLetter } from "../../../utils/normalizations";

const Thumb = ({ props, state }) => {
  const { min } = props;
  const { valueNow } = state;

  return (
    <Container {...props} value={valueNow} min={min}>
      <ValueContainer>
        {normalizeDollarAmountWithLetter(valueNow)}
      </ValueContainer>
      <Triangle />
    </Container>
  );
};

const Container = styled.div`
  cursor: grab;
  transform: ${({ value, min }) =>
    min !== value ? "translate(50%, -100%)" : "translate(-50%, -100%)"};
  display: flex;
  flex-direction: column;
`;

const ValueContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-37%, -115%);
  width: 81px;
  height: 49px;
  background: #edf1ff;
  border-radius: 10px;
  margin-bottom: 5px;
  font-size: 17px;
  font-weight: 600;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px 9px 0 9px;
  border-color: #ff2853 transparent transparent transparent;
`;

export default Thumb;
