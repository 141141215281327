import { useAppContract } from "../../../hooks/useAppContract";
import { useSelector } from "react-redux";
import { forEach, find } from "lodash";
import { tokenProjectsListSelector } from "../../../store/selectors/projects";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../consts";
import { useWeb3React } from "@web3-react/core";

export const useTokenInvestments = () => {
  const { getMyInvestments } = useAppContract();
  const { account } = useWeb3React();
  const tokenProjects = useSelector(tokenProjectsListSelector);

  const getNormalizedInvestments = async () => {
    const investmentsArray = await getMyInvestments();
    const result = [];

    forEach(investmentsArray, (investment, onChainProjectId) => {
      if (investment) {
        result.push({
          investmentAmount: investment / 1e18,
          projectData: find(tokenProjects, {
            onChainProjectId: onChainProjectId.toString(),
          }),
        });
      }
    });

    return result;
  };

  return useQuery([queryKeys.tokenInvestments], getNormalizedInvestments, {
    enabled: !!account,
  });
};
