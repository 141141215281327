import React from "react";
import ReactSlider from "react-slider";
import styled from "styled-components/macro";
import Track from "./Track";
import Thumb from "./Thumb";
import { normalizeDollarAmountWithLetter } from "../../../utils/normalizations";

const Slider = ({ value, onChange, min, max }) => {
  return (
    <Container>
      <Label>{normalizeDollarAmountWithLetter(min)}</Label>

      <StyledRactSlider
        value={value}
        onChange={onChange}
        renderTrack={(props, state) => (
          <Track props={{ ...props, min }} state={state} />
        )}
        renderThumb={(props, state) => (
          <Thumb props={{ ...props, min }} state={state} />
        )}
        min={min}
        max={max}
      />

      <Label>{normalizeDollarAmountWithLetter(max)}</Label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  flex-shrink: 0;
`;

const StyledRactSlider = styled(ReactSlider)`
  width: 100%;
  height: 10px;
`;

export default Slider;
