import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
} from "../../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/core/Button";
import {
  buttonVariants,
  signUpCompanyEquitySteps,
  urlRegex,
} from "../../../../consts";
import {
  setCompanyCompletedSteps,
  setEquityCompanyInfo,
} from "../../../../store/companySignUp/actions";
import Dropdown from "../../../../Components/core/formElements/dropdown/Dropdown";
import { getFieldError } from "../../../../utils/formUtils";
import config from "../../../../assets/config.json";
import {
  ButtonsWrapper,
  Container,
  Description,
  InputsWrapper,
  Title,
} from "../styled";
import Input from "../../../../Components/core/formElements/Input";
import { getCountryOptions } from "../../../../utils/normalizations";
import InfoIcon from "../../../../Components/core/icons/InfoIcon";
import TextArea from "../../../../Components/core/formElements/TextArea";
import FileUpload from "../../../../Components/core/fileUpload/FileUpload";
import ValidateIcon from "../../../../Components/core/icons/ValidateIcon";

const countryOptions = [{ label: "All", value: "All" }, ...getCountryOptions()];

const EquityCompanyInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { equityCompanyInfo } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);

  const { handleSubmit, control, watch } = useForm({
    defaultValues: equityCompanyInfo,
  });

  const currentIndustry = watch("industry");
  const isOtherIndustry =
    currentIndustry?.length === 1 && currentIndustry[0].value === "Other";

  const onSubmit = (data) => {
    dispatch(setEquityCompanyInfo(data));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyEquitySteps.equityCompanyInfo,
      ])
    );

    navigate("/sign-up/company/shares-info");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Company info</Title>

      <Description>Enter your company info below</Description>

      <div>
        <InputsWrapper>
          <Controller
            name="companyName"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Project / Brand Name"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Project / Brand Name",
                })}
              />
            )}
          />
          <Controller
            name="countryOfCooperation"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Dropdown
                placeholder="Country of incorporation"
                options={countryOptions}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isMulti
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Country",
                })}
              />
            )}
          />

          <Controller
            name="companyStage"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Dropdown
                placeholder="Company stage"
                options={
                  config.signUp.company.tokenCompanyInfo.companyStageOptions
                }
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Stage",
                })}
              />
            )}
          />

          <Controller
            name="companyWebsite"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              const regex = new RegExp(urlRegex);
              const isValid = regex.test(value);

              return (
                <Input
                  placeholder="Company website"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  InnerComponent={() => <ValidateIcon isValid={isValid} />}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Website",
                  })}
                />
              );
            }}
          />

          <Controller
            name="incorporationLicense"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <StyledFileUpload
                title="Company incorporation license"
                subtitle="Upload incorporation license"
                value={value}
                onChange={onChange}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "File",
                })}
              />
            )}
          />

          <Controller
            name="industry"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Dropdown
                placeholder="Industry"
                options={
                  config.signUp.investor.companyInfo.typeOfIndustryOptions
                }
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isMulti
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Industry",
                })}
              />
            )}
          />

          {isOtherIndustry && (
            <Controller
              name="otherIndustryType"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <Input
                  placeholder="Name type of industry"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Industry",
                  })}
                />
              )}
            />
          )}

          <Controller
            name="projectDescription"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextArea
                placeholder="Project description..."
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Description",
                })}
              />
            )}
          />

          <Controller
            name="numberOfEmployees"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Number of employees"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isNumeric
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Number",
                })}
              />
            )}
          />

          <Controller
            name="teamBackground"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextArea
                placeholder="Team background..."
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Background",
                })}
              />
            )}
          />
        </InputsWrapper>

        <FilesUploadWrapper>
          <Controller
            name="pitchDeck"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FileUpload
                title="Pitch deck"
                subtitle="Upload pitch deck"
                value={value}
                onChange={onChange}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Pitch Deck",
                })}
              />
            )}
          />

          <Controller
            name="financialRecords"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FileUpload
                title="Financial plan"
                subtitle="Upload financial plan"
                value={value}
                onChange={onChange}
                accept=".xls, .xlsx, .pdf"
                info="*excel, pdf (50MB max size)"
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Records file",
                })}
              />
            )}
          />

          <Controller
            name="businessPlan"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FileUpload
                title="Business plan"
                subtitle="Upload business plan"
                value={value}
                onChange={onChange}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Business plan",
                })}
              />
            )}
          />

          <Controller
            name="logo"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FileUpload
                title="Logo"
                subtitle="Upload logo"
                value={value}
                onChange={onChange}
                info="*Jpeg/Png (50MB max size)"
                accept=".jpg, .jpeg, .png"
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Logo",
                })}
              />
            )}
          />
        </FilesUploadWrapper>
      </div>

      <ButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        <Button
          variant={buttonVariants.secondary}
          onClick={() => navigate("/sign-up/company/founders-info")}
        >
          Back
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

const FilesUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 80px;
  gap: 40px;
`;

const StyledFileUpload = styled(FileUpload)`
  margin: 20px 0;
`;

export default EquityCompanyInfo;
