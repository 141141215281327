import { useFilledRoles } from "../utils/api/queries/useFilledRoles";
import { useUserDBData } from "../utils/api/queries/useUserDBData";
import { useAuthenticated } from "../hooks/useAuthenticated";

const Initializer = () => {
  const { isAuthenticated } = useAuthenticated();

  useFilledRoles({ enabled: !!isAuthenticated });
  useUserDBData({ enabled: !!isAuthenticated });

  return null;
};

export default Initializer;
