import React from "react";
import styled from "styled-components/macro";

const FormElementWrapper = ({
  className,
  errorMessage,
  children,
  onClick,
  onBlur,
}) => (
  <Container
    className={className}
    onClick={onClick}
    onBlur={onBlur && onBlur()}
  >
    <Content className="content" errorMessage={errorMessage}>
      {children}
    </Content>

    {errorMessage && (
      <Error>
        <Icon src="/img/false-icon.svg" />
        {errorMessage}
      </Error>
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const Icon = styled.img`
  margin-right: 5px;
  width: 19px;
  height: 19px;
`;

export default FormElementWrapper;
