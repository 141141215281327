import { companySignUpSlice } from "./slice";
const { actions: slice } = companySignUpSlice;

export const setCompanyCompletedSteps = (completedSteps) => (dispatch) => {
  dispatch(slice.setCompanyCompletedSteps(completedSteps));
};

export const setContactInfo = (contactInfo) => (dispatch) => {
  dispatch(slice.setContactInfo(contactInfo));
};

export const setCompanyType = (companyType) => (dispatch) => {
  dispatch(slice.setCompanyType(companyType));
};

export const setFoundersInfo = (founderInfo) => (dispatch) => {
  dispatch(slice.setFoundersInfo(founderInfo));
};

export const setTokenCompanyInfo = (tokenCompanyInfo) => (dispatch) => {
  dispatch(slice.setTokenCompanyInfo(tokenCompanyInfo));
};

export const setMetricsInfo = (metricsInfo) => (dispatch) => {
  dispatch(slice.setMetricsInfo(metricsInfo));
};

export const setListingInfo = (listingInfo) => (dispatch) => {
  dispatch(slice.setListingInfo(listingInfo));
};

export const setPartnersAndInfluencers =
  (partnersAndInfluencers) => (dispatch) => {
    dispatch(slice.setPartnersAndInfluencers(partnersAndInfluencers));
  };

export const setTokenExitStrategy = (exitStrategy) => (dispatch) => {
  dispatch(slice.setTokenExitStrategy(exitStrategy));
};

export const setVideoPitchLink = (videoPitchLink) => (dispatch) => {
  dispatch(slice.setVideoPitchLink(videoPitchLink));
};

export const resetCompanySignup = () => (dispatch) => {
  dispatch(slice.resetCompanySignup());
};

export const setEquityCompanyInfo = (equityCompanyInfo) => (dispatch) => {
  dispatch(slice.setEquityCompanyInfo(equityCompanyInfo));
};

export const setSharesInfo = (sharesInfo) => (dispatch) => {
  dispatch(slice.setSharesInfo(sharesInfo));
};

export const setRaisingInfo = (raisingInfo) => (dispatch) => {
  dispatch(slice.setRaisingInfo(raisingInfo));
};

export const setPartnersAndClients = (partnersAndClients) => (dispatch) => {
  dispatch(slice.setPartnersAndClients(partnersAndClients));
};

export const setEquityExitStrategy = (equityExitStrategy) => (dispatch) => {
  dispatch(slice.setEquityExitStrategy(equityExitStrategy));
};

export const setDocumentsLink = (documentsLink) => (dispatch) => {
  dispatch(slice.setDocumentsLink(documentsLink));
};
