import styled from "styled-components/macro";

const ClickableText = styled.span`
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export default ClickableText;
