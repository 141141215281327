import styled from "styled-components/macro";
import Button from "../../../core/Button";
import ClickableText from "../../../core/ClickableText";
export const ModalContent = styled.div`
  position: relative;
  width: 538px;
  max-height: 85vh;
  overflow: auto;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 75px 56px 46px 56px;

  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    max-height: none;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 30px;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 23px;
  }
`;

export const ModalText = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

export const SubmitButton = styled(Button)`
  height: 56px;

  @media (max-width: 768px) {
    height: 38px;
  }
`;

export const BackToSignIn = styled(ClickableText)`
  font-size: 16px;
  color: #2c73eb;
  display: flex;
  justify-content: center;
`;
