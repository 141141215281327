import React from "react";
import styled, { css } from "styled-components/macro";
import Button from "../../Components/core/Button";

export const CardRow = ({ field, value, isClosed }) => (
  <Row>
    <Field>{field}</Field>
    <Value isClosed={isClosed}>{value}</Value>
  </Row>
);

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000f3c;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 9px;
  }
`;

const Field = styled.div`
  font-weight: 300;
`;

const Value = styled.div`
  font-weight: 600;

  ${({ isClosed }) =>
    isClosed &&
    css`
      color: #ff2853;
    `}
`;

export const ShowMoreButton = styled(Button)`
  color: white;
  background-color: #273c7c;
  margin: auto;
  width: 153px;
  height: 45px;
`;

export const SectionTitle = styled.div`
  color: #000f3c;
  font-weight: 600;
  margin-bottom: 43px;
  font-size: 30px;

  @media (max-width: 1280px) {
    margin-bottom: 27px;
    font-size: 20px;
    text-align: center;
  }
`;

export const Logo = styled.img`
  width: 56px;
  height: 32px;
  margin-bottom: 30px;

  @media (max-width: 1920px) {
    width: 43px;
    height: 28px;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    width: 23px;
    height: 15px;
    margin-bottom: 13px;
  }
`;

export const Name = styled.div`
  color: #000f3c;
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  margin-bottom: 24px;

  @media (max-width: 1920px) {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

export const ProjectsWrapper = styled.div`
  display: grid;
  grid-gap: 92px 14px;
  grid-template-columns: repeat(auto-fill, 310px);
  margin-bottom: 104px;
  justify-content: flex-start;
  max-width: 1610px;

  @media (max-width: 1280px) {
    grid-gap: 36px 10px;
    grid-template-columns: repeat(auto-fill, 237px);
    margin-bottom: 70px;
  }

  @media (max-width: 768px) {
    grid-gap: 13px 5px;
    grid-template-columns: repeat(auto-fit, 130px);
    margin-bottom: 46px;
  }
`;
