import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../store/search/actions";
import { clearFilters } from "../store/filters/actions";
import { searchSelector } from "../store/selectors/search";
import { filterValuesSelector } from "../store/selectors/filters";
import { filter, get } from "lodash";
import {
  getFuzzySearchResults,
  isProjectFitsFilter,
} from "../utils/projectUtils";

export const useProjectSearchAndFilters = ({
  originalData,
  searchFields,
  projectDataPath,
}) => {
  const dispatch = useDispatch();

  const search = useSelector(searchSelector);
  const filterValues = useSelector(filterValuesSelector);

  const filteredData = useMemo(() => {
    const projectsAfterFilter = filter(originalData, (object) =>
      isProjectFitsFilter({
        project: projectDataPath ? get(object, projectDataPath) : object,
        filterValues,
      })
    );

    return getFuzzySearchResults({
      originalData: projectsAfterFilter,
      searchFields,
      inputValue: search,
    });
  }, [search, originalData]);

  useEffect(() => {
    return () => {
      dispatch(setSearch(""));
      dispatch(clearFilters());
    };
  }, []);

  return { filteredData };
};
