import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../../Components/Section/Navbar";
import { useForm } from "react-hook-form";
import {
  setInvestorCompletedSteps,
  setInvestorContactInfo,
} from "../../../store/investorSignUp/actions";
import { buttonVariants, signUpInvestorSteps } from "../../../consts";
import Button from "../../../Components/core/Button";
import { Container, Content, StyledHorizontalProgressLine } from "./styled";
import { useNavigate } from "react-router-dom";
import { investorSignUpInitData } from "../../../store/selectors/signUp";
import ContactInfoStep from "../shared/ContactInfoStep";
import { useDefaultCountryCode } from "../../../hooks/useDefaultCountryCode";
import { isEmpty } from "lodash-es";

const InvestorContactInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contactInfo = useSelector(investorSignUpInitData);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: contactInfo,
  });

  const { data: phoneCode } = useDefaultCountryCode();

  useEffect(() => {
    // If the values have been already stored in redux
    if (!isEmpty(contactInfo.phoneCode)) {
      reset(contactInfo);
    }
    // Init values
    else {
      reset({ ...contactInfo, phoneCode });
    }
  }, [contactInfo, phoneCode]);

  const onSubmit = (data) => {
    dispatch(setInvestorContactInfo(data));
    dispatch(setInvestorCompletedSteps([signUpInvestorSteps.contactInfo]));
    navigate("/sign-up/investor/company-info");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Navbar status={"submit"} />

      <Content>
        <StyledHorizontalProgressLine fillPercent={50} />

        <ContactInfoStep control={control} isInvestor />

        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>
      </Content>
    </Container>
  );
};

export default InvestorContactInfo;
