import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpInitData,
} from "../../../store/selectors/signUp";
import { useForm } from "react-hook-form";
import ContactInfoStep from "../shared/ContactInfoStep";
import Button from "../../../Components/core/Button";
import {
  buttonVariants,
  queryKeys,
  roles,
  signUpCompanyBasicSteps,
} from "../../../consts";
import {
  setCompanyCompletedSteps,
  setContactInfo,
} from "../../../store/companySignUp/actions";
import { Container } from "./styled";
import { useDefaultCountryCode } from "../../../hooks/useDefaultCountryCode";
import { isEmpty } from "lodash-es";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setRoleProfile } from "../../../utils/api/mutations";

const CompanyContactInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: setRoleProfile,
    onSuccess: () => {
      dispatch(
        setCompanyCompletedSteps([
          ...completedSteps,
          signUpCompanyBasicSteps.contactInfo,
        ])
      );
      navigate("/sign-up/company/company-type");
      queryClient.invalidateQueries([queryKeys.userDBData]);
      queryClient.invalidateQueries([queryKeys.filledRoles]);
    },
  });

  const completedSteps = useSelector(companySignUpCompletedStepsSelector);
  const contactInfo = useSelector(companySignUpInitData);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: contactInfo,
  });

  const { data: phoneCode } = useDefaultCountryCode();

  useEffect(() => {
    // If the values have been already stored in redux
    if (!isEmpty(contactInfo.phoneCode)) {
      reset(contactInfo);
    }
    // Init values
    else {
      reset({ ...contactInfo, phoneCode });
    }
  }, [contactInfo, phoneCode]);

  const onSubmit = (data) => {
    dispatch(setContactInfo(data));
    mutate({ role: roles.company.value, roleData: { contactInfo: data } });
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <ContactInfoStep control={control} />

      <Button type="submit" variant={buttonVariants.primary}>
        Next
      </Button>
    </Container>
  );
};

export default CompanyContactInfo;
