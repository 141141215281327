import { createSelector } from "reselect";
import { removeNullValues } from "../../utils/normalizations";
import { appSelector } from "./app";
import { toLower } from "lodash-es";
import { fundingTypes } from "../../consts";

export const investorSignUpSelector = (state) => state.investorSignUp;
export const companySignUpSelector = (state) => state.companySignUp;
export const consultantSignUpSelector = (state) => state.consultantSignUp;

export const investorSignUpInitData = createSelector(
  [investorSignUpSelector, appSelector],
  (investorSignUp, loggedInUser) => {
    const { contactInfo } = investorSignUp;

    return {
      ...contactInfo,
      ...(!contactInfo.fullName && { fullName: loggedInUser?.displayName }),
      ...(!contactInfo.email && { email: loggedInUser?.email }),
    };
  }
);

export const investorSignUpCompletedStepsSelector = createSelector(
  [investorSignUpSelector],
  (investorData) => investorData.completedSteps
);

export const consultantSignUpInitData = createSelector(
  [consultantSignUpSelector, appSelector],
  (consultantSignUp, loggedInUser) => {
    const { contactInfo } = consultantSignUp;

    return {
      ...contactInfo,
      ...(!contactInfo.fullName && { fullName: loggedInUser?.displayName }),
      ...(!contactInfo.email && { email: loggedInUser?.email }),
    };
  }
);

export const consultantSignUpCompletedStepsSelector = createSelector(
  [consultantSignUpSelector],
  (consultantData) => consultantData.completedSteps
);

export const companySignUpInitData = createSelector(
  [companySignUpSelector, appSelector],
  (companySignUp, loggedInUser) => {
    const { contactInfo } = companySignUp;

    return {
      ...contactInfo,
      ...(!contactInfo.fullName && { fullName: loggedInUser?.displayName }),
      ...(!contactInfo.email && { email: loggedInUser?.email }),
    };
  }
);

export const selectedCompanyTypeSelector = createSelector(
  [companySignUpSelector],
  (companyData) => companyData.companyType
);

export const companySignUpCompletedStepsSelector = createSelector(
  [companySignUpSelector],
  (companyData) => companyData.completedSteps
);

export const projectSignUpDataByCompanyTypeSelector = createSelector(
  [companySignUpSelector, selectedCompanyTypeSelector],
  (companySignUpData, companyType) => {
    let formData;
    if (toLower(companyType.value) === fundingTypes.token) {
      formData = {
        companyType: companySignUpData?.companyType,
        foundersInfo: companySignUpData?.foundersInfo,
        tokenCompanyInfo: companySignUpData?.tokenCompanyInfo,
        metricsInfo: removeNullValues(companySignUpData?.metricsInfo),
        listingInfo: companySignUpData?.listingInfo,
        partnersAndInfluencers: companySignUpData?.partnersAndInfluencers,
        tokenExitStrategy: companySignUpData?.tokenExitStrategy,
        videoPitchLink: companySignUpData?.videoPitchLink,
        documentsLink: companySignUpData?.documentsLink,
      };
    } else {
      formData = {
        companyType: companySignUpData?.companyType,
        foundersInfo: companySignUpData?.foundersInfo,
        equityCompanyInfo: companySignUpData?.equityCompanyInfo,
        sharesInfo: companySignUpData?.sharesInfo,
        raisingInfo: companySignUpData?.raisingInfo,
        partnersAndClients: companySignUpData?.partnersAndClients,
        equityExitStrategy: companySignUpData?.equityExitStrategy,
        videoPitchLink: companySignUpData?.videoPitchLink,
        documentsLink: companySignUpData?.documentsLink,
      };
    }

    return formData;
  }
);
