import { createSelector } from "reselect";
import { forEach } from "lodash";

export const filtersSelector = (state) => state.filters;

export const filterValuesSelector = createSelector(
  [filtersSelector],
  (filters) => {
    const result = {};

    forEach(filters, (object, key) => {
      result[key] = object?.value;
    });

    return result;
  }
);
