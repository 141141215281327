import React from "react";
import styled from "styled-components/macro";
import Navbar from "../../Components/Section/Navbar";
import Footer from "../../Components/Section/Footer";
import Card from "./Card";
import { useNavigate } from "react-router-dom";
import { useAuthenticated } from "../../hooks/useAuthenticated";
import { modalTypes, roles } from "../../consts";
import { openModal } from "../../store/modal/actions";
import { useDispatch } from "react-redux";
import { useFilledRoles } from "../../utils/api/queries/useFilledRoles";

const CompleteSignUp = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuthenticated();
  const navigate = useNavigate();

  const { data: filledRoles } = useFilledRoles();

  const handleInvestorClick = async () => {
    navigate("/sign-up/investor/contact-info");
  };

  const handleCompanyClick = async () => {
    navigate("/sign-up/company/contact-info");
  };

  const handleConsultantClick = () => {
    navigate("/sign-up/consultant/contact-info");
  };

  return (
    <Container>
      <div>
        <Navbar status={"submit"} />

        <Content>
          <Title>Welcome! Choose your profile</Title>
          <CardsContainer>
            <Card
              title={roles.investor.label}
              content="I'm interested in pitchshow investment opportunities"
              onClick={handleInvestorClick}
              disabled={!!filledRoles?.[roles.investor.value]}
            />

            <Card
              title={roles.company.label}
              content="I hold shares in a private company"
              onClick={handleCompanyClick}
              disabled={!!filledRoles?.[roles.company.value]}
            />

            <Card
              title={roles.consultant.label}
              content="I am a company consultant"
              onClick={handleConsultantClick}
              disabled={!!filledRoles?.[roles.consultant.value]}
            />
          </CardsContainer>

          {!isAuthenticated && (
            <BottomMessage>
              Already have an account?
              <ClickableText
                onClick={() =>
                  dispatch(openModal({ type: modalTypes.signInModal }))
                }
              >
                Log in now!
              </ClickableText>
            </BottomMessage>
          )}
        </Content>
      </div>

      <StyledFooter />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  background: linear-gradient(101.01deg, #d3deff -21.7%, #ffffff 46.47%);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 212px;
  padding: 0 35px;
  align-items: center;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: #000f3c;
  margin-bottom: 20px;
  text-align: center;
`;

const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 17px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(251px, 341px));
  margin-bottom: 39px;

  @media (max-width: 768px) {
    margin-bottom: 83px;
  }
`;

const BottomMessage = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #09216a;
`;

const ClickableText = styled.div`
  color: #ff2853;
  cursor: pointer;
  margin-left: 5px;
  display: inline-block;

  @media (max-width: 768px) {
    display: block;
  }
`;

const StyledFooter = styled(Footer)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export default CompleteSignUp;
