import { consultantSignUpSlice } from "./slice";
const { actions: slice } = consultantSignUpSlice;

export const setConsultantCompletedSteps = (completedSteps) => (dispatch) => {
  dispatch(slice.setConsultantCompletedSteps(completedSteps));
};

export const setConsultantContactInfo = (contactInfo) => (dispatch) => {
  dispatch(slice.setConsultantContactInfo(contactInfo));
};

export const setConsultantCompanyInfo = (companyInfo) => (dispatch) => {
  dispatch(slice.setConsultantCompanyInfo(companyInfo));
};

export const resetConsultantSignup = () => (dispatch) => {
  dispatch(slice.resetConsultantSignup());
};
