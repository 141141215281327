import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import Navbar from "../../../Components/Section/Navbar";
import { Controller, useForm } from "react-hook-form";
import { setInvestorCompanyInfo } from "../../../store/investorSignUp/actions";
import Input from "../../../Components/core/formElements/Input";
import { getFieldError } from "../../../utils/formUtils";
import { buttonVariants, roles, urlRegex } from "../../../consts";
import Button from "../../../Components/core/Button";
import {
  Container,
  Content,
  Description,
  FieldsWrapper,
  Header,
  StyledHorizontalProgressLine,
} from "./styled";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../Components/core/formElements/dropdown/Dropdown";
import { getCountryOptions } from "../../../utils/normalizations";
import InfoIcon from "../../../Components/core/icons/InfoIcon";
import config from "../../../assets/config.json";
import DataSubmitted from "../../../Components/DataSubmitted";
import { investorSignUpSelector } from "../../../store/selectors/signUp";
import { setRoleProfile } from "../../../utils/api/mutations";
import FileUpload from "../../../Components/core/fileUpload/FileUpload";

const countryOptions = [{ label: "All", value: "All" }, ...getCountryOptions()];

const InvestorCompanyInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyInfo } = useSelector(investorSignUpSelector) || {};

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitSuccessful },
  } = useForm({ defaultValues: companyInfo });

  const investorSignUpData = useSelector(investorSignUpSelector);

  const isRegulated = watch("isRegulated")?.value;
  const currentIndustry = watch("typeOfIndustry");
  const isOtherIndustry =
    currentIndustry?.length === 1 && currentIndustry[0].value === "Other";

  const onSubmit = (data) => {
    dispatch(setInvestorCompanyInfo(data));
  };

  if (isSubmitSuccessful) {
    return (
      <DataSubmitted
        mutation={setRoleProfile}
        requestData={{
          role: roles.investor.value,
          roleData: investorSignUpData,
        }}
      />
    );
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Navbar status={"submit"} />
      <Content>
        <StyledHorizontalProgressLine fillPercent={100} />

        <Header>Company info</Header>

        <Description>
          Enter your company info below to create an account
        </Description>

        <FieldsWrapper>
          <Controller
            name="companyName"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Input
                  placeholder="Project / Brand Name"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Project / Brand Name",
                  })}
                />
              );
            }}
          />
          <Controller
            name="countryIncorporation"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Dropdown
                  placeholder="Company incorporated"
                  options={countryOptions}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isMulti
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Company country",
                  })}
                />
              );
            }}
          />

          <Controller
            name="incorporationLicense"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <StyledFileUpload
                title="Company incorporation license"
                subtitle="Upload incorporation license"
                value={value}
                onChange={onChange}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "File",
                })}
              />
            )}
          />

          <Controller
            name="typeOfInvestment"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Dropdown
                  placeholder="Select type of investment"
                  options={
                    config.signUp.investor.companyInfo.typeOfInvestmentOptions
                  }
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Type",
                  })}
                />
              );
            }}
          />

          <Controller
            name="typeOfIndustry"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Dropdown
                  placeholder="Select type of industry"
                  options={
                    config.signUp.investor.companyInfo.typeOfIndustryOptions
                  }
                  isMulti
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Type",
                  })}
                />
              );
            }}
          />

          {isOtherIndustry && (
            <Controller
              name="otherIndustryType"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <Input
                  placeholder="Name type of industry"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Industry",
                  })}
                />
              )}
            />
          )}

          <Controller
            name="investmentSize"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Dropdown
                  placeholder="Select investment size"
                  options={
                    config.signUp.investor.companyInfo.investmentSizeOption
                  }
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Size",
                  })}
                />
              );
            }}
          />

          <Controller
            name="groupOrIndividual"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Dropdown
                  placeholder="Are you investing as a group or an individual?"
                  options={config.signUp.investor.companyInfo.groupOrIndividual}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Selection",
                  })}
                />
              );
            }}
          />

          <Controller
            name="companyStage"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Dropdown
                  placeholder="Select company stage"
                  options={config.signUp.investor.companyInfo.companyStage}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Stage",
                  })}
                />
              );
            }}
          />

          <Controller
            name="isRegulated"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Dropdown
                  placeholder="Are you regulated?"
                  options={config.shared.yesNoOptions}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Selection",
                  })}
                />
              );
            }}
          />

          {isRegulated && (
            <Controller
              name="jurisdiction"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="Enter what jurisdiction"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={getFieldError({
                      error,
                      fieldLabel: "Jurisdiction",
                    })}
                  />
                );
              }}
            />
          )}
        </FieldsWrapper>

        <Button type="submit" variant={buttonVariants.primary}>
          Submit
        </Button>

        <StyledButton
          variant={buttonVariants.secondary}
          onClick={() => navigate("/sign-up/investor/contact-info")}
        >
          Back
        </StyledButton>
      </Content>
    </Container>
  );
};

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

const StyledFileUpload = styled(FileUpload)`
  margin: 20px 0;
`;

export default InvestorCompanyInfo;
