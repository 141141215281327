import React from "react";
import { ModalContent, ModalText, ModalTitle, SubmitButton } from "./shared";
import styled from "styled-components/macro";
import { closeModal } from "../../../../store/modal/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const ResetPasswordSubmittedModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleContinue = () => {
    dispatch(closeModal());
    navigate("/");
  };

  return (
    <ModalContent>
      <StyledModalTitle>Thank you!</StyledModalTitle>

      <Description>Your password has been successfully reset.</Description>

      <SubmitButton variant="primary" onClick={handleContinue}>
        Continue to Pitchshow
      </SubmitButton>
    </ModalContent>
  );
};

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 30px;
  font-size: 30px;
  text-align: center;
`;

const Description = styled(ModalText)`
  text-align: center;
  font-size: 18px !important;
  margin-bottom: 85px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export default ResetPasswordSubmittedModal;
