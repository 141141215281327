import React from "react";
import styled from "styled-components/macro";
import { FieldValue } from "./shared";
import {
  getProjectOpenStatus,
  getTimeRemainingString,
} from "../../utils/projectUtils";
import {
  normalizeDollarAmount,
  normalizeNumber,
} from "../../utils/normalizations";
import moment from "moment";
import { capitalize } from "lodash";

const ProjectInfo = ({ className, project, numberOfInvestors }) => {
  if (!project) {
    return null;
  }

  const { totalRaising, totalRaised, endTime } = project;

  return (
    <Container className={className}>
      <StyledFieldValue
        field="Project's status"
        value={capitalize(getProjectOpenStatus(project))}
      />
      <StyledFieldValue
        field="Total raising"
        value={normalizeDollarAmount(totalRaising)}
      />

      <StyledFieldValue
        field="Number of investments"
        value={normalizeNumber(numberOfInvestors)}
      />

      <StyledFieldValue
        field="Total amount raised"
        value={normalizeDollarAmount(totalRaised)}
      />

      <StyledFieldValue
        field="Total amount left to be raised"
        value={normalizeDollarAmount(totalRaising - totalRaised)}
      />

      <StyledFieldValue
        field="Remaining time"
        value={getTimeRemainingString(moment(endTime))}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 80px;
  background-color: #c2d0f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  gap: 10px;
`;

const StyledFieldValue = styled(FieldValue)`
  align-items: center;

  .value {
    white-space: nowrap;
  }
`;

export default ProjectInfo;
