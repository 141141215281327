import React, { useRef, useState } from "react";
import { ModalContent, ModalText, ModalTitle, SubmitButton } from "./shared";
import styled from "styled-components/macro";
import { Controller, useForm } from "react-hook-form";
import ModalInput from "../../../core/formElements/ModalInput";
import {
  atLeast8CharsRegex,
  buttonVariants,
  emailRegex,
  modalTypes,
} from "../../../../consts";
import Checkbox from "../../../core/formElements/Checkbox";
import ClickableText from "../../../core/ClickableText";
import { useAuthenticated } from "../../../../hooks/useAuthenticated";
import { openModal } from "../../../../store/modal/actions";
import { useDispatch } from "react-redux";
import {
  signInWithEmailAndPassword,
  signInWithEmailAndPassword2Fa,
} from "../../../../utils/api/mutations";
import { useMutation } from "@tanstack/react-query";
import { useRecaptcha } from "../../../../hooks/useRecaptcha";
import { SigninEmailPasswordErrors } from "../../../../services/auth.service";

const recaptchaId = "recaptchaId";

const SignInModal = ({ afterEmailVerification }) => {
  const dispatch = useDispatch();
  const verifier = useRecaptcha({ recaptchaId });
  const recaptchaRef = useRef();

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
  } = useForm({
    mode: "onChange",
  });
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [error, setError] = useState("");
  const password = watch("password");
  const email = watch("email");

  const { signIn } = useAuthenticated();
  const { mutate: signInWithEmail } = useMutation({
    mutationFn: signInWithEmailAndPassword,
    onSuccess: async (response = {}) => {
      const {
        verificationId,
        resolver,
        phoneNumber,
        emailVerified,
        is2faActive,
      } = response;
      if (!is2faActive) {
        dispatch(
          openModal({
            type: modalTypes.enterPhoneNumber,
            email,
            password,
          })
        );
      } else {
        dispatch(
          openModal({
            type: modalTypes.phoneCodeModal,
            phoneNumber,
            resolver,
            verificationId,
          })
        );
      }
    },
    onError: (e) => {
      switch (e) {
        case SigninEmailPasswordErrors.USER_NOT_FOUND:
          setError("Something went wrong!\nUser not found.");
          break;

        case SigninEmailPasswordErrors.WRONG_PASSWORD:
          setError("Something went wrong!\nWrong password.");
          break;

        case SigninEmailPasswordErrors.EMAIL_NOT_VERIFIED:
          setError(
            "Something went wrong!\nEmail is not verified. Please verify your email and then come back."
          );
          break;

        default:
          setError("Something went wrong!");
      }
    },
  });

  const onSubmit = ({ email, password }) => {
    signInWithEmail({ email, password, verifier, afterEmailVerification });
  };

  const handleGoogleLogin = async () => {
    await signIn(verifier);
  };

  const handleSignUp = () => {
    dispatch(openModal({ type: modalTypes.signUpModal }));
  };

  const handleForgotPassword = () => {
    dispatch(openModal({ type: modalTypes.forgotPasswordModal }));
  };

  return (
    <ModalContent>
      <div id={recaptchaId} ref={recaptchaRef} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledModalTitle>Account Log In</StyledModalTitle>

        <Description>
          {!error ? (
            <p>
              If you are already a member you can login with your email address
              and password.
            </p>
          ) : (
            <Error>{error}</Error>
          )}
        </Description>

        <InputsWrapper>
          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: emailRegex }}
            render={({ field: { value, onChange } }) => (
              <ModalInput
                value={value}
                onChange={onChange}
                label="Email address"
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: true, pattern: atLeast8CharsRegex }}
            render={({ field: { value, onChange } }) => (
              <ModalInput
                value={value}
                onChange={onChange}
                label="Password"
                type="password"
              />
            )}
          />
        </InputsWrapper>

        <Row>
          {/*<StyledCheckbox*/}
          {/*  label="Remember me"*/}
          {/*  value={isRememberMe}*/}
          {/*  onChange={setIsRememberMe}*/}
          {/*/>*/}

          <ForgotPassword onClick={handleForgotPassword}>
            Forgot Password?
          </ForgotPassword>
        </Row>

        <StyledSubmitButton
          variant={buttonVariants.primary}
          type="submit"
          disabled={!isValid}
        >
          Log In
        </StyledSubmitButton>

        <DontHaveAccount>
          Don't have an account?
          <SignUp onClick={handleSignUp}>Sign up here</SignUp>
        </DontHaveAccount>

        <GoogleLogin src="/img/google-login.svg" onClick={handleGoogleLogin} />
      </Form>
    </ModalContent>
  );
};

const Form = styled.form``;

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 15px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Description = styled(ModalText)`
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    gap: 18px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: 16px;
`;

const ForgotPassword = styled(ClickableText)`
  font-weight: 500;
  font-size: 15px;
  color: #ff2853;
  margin-left: auto;
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin-bottom: 50px;
`;

const DontHaveAccount = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #696f79;
  margin-bottom: 30px;
  text-align: center;
`;

const SignUp = styled(ClickableText)`
  color: #2c73eb;
  margin-left: 10px;
`;

const GoogleLogin = styled.img`
  height: 40px;
  cursor: pointer;
`;

const Error = styled.div`
  font-size: 18px;
  color: #ff2853;
  white-space: break-spaces;
`;

export default SignInModal;
