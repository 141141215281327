import { DALServiceV2 } from "../../../services/data-access-layer.service";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../consts";
import { useUserDBData } from "./useUserDBData";
import { map } from "lodash-es";

export const useProjectInvestments = ({ projectId }) => {
  const { data: userData } = useUserDBData();
  const role = userData?.role;

  const { data: investorsData } = useQuery({
    queryKey: [queryKeys.projectInvestors],
    queryFn: () => DALServiceV2.getInvestmentsData({ role }),
    enabled: !!role,
    select: (response) => response?.data?.results,
  });

  const { data: investmentsData } = useQuery({
    queryKey: [queryKeys.projectInvestments, projectId],
    queryFn: () => DALServiceV2.getInvestmentsAsFundRaiser(projectId),
    enabled: !!role && !!investorsData,
    select: (response) =>
      map(response, (investment) => {
        const { uid } = investment;

        return {
          ...investment,
          investorData: investorsData[uid]?.roleData,
        };
      }),
  });

  return investmentsData;
};
