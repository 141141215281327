import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
} from "../../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/core/Button";
import { buttonVariants, signUpCompanyTokenSteps } from "../../../../consts";
import {
  setCompanyCompletedSteps,
  setTokenExitStrategy,
} from "../../../../store/companySignUp/actions";
import { getFieldError } from "../../../../utils/formUtils";
import {
  ButtonsWrapper,
  Container,
  Description,
  InputsWrapper,
  Title,
} from "../styled";
import Input from "../../../../Components/core/formElements/Input";
import Dropdown from "../../../../Components/core/formElements/dropdown/Dropdown";
import config from "../../../../assets/config.json";
import DatePicker from "../../../../Components/core/formElements/DatePicker";

const TokenExitStrategy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tokenExitStrategy } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);

  const { handleSubmit, control } = useForm({
    defaultValues: tokenExitStrategy,
  });

  const onSubmit = (data) => {
    dispatch(setTokenExitStrategy(data));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyTokenSteps.tokenExitStrategy,
      ])
    );

    navigate("/sign-up/company/video-pitch");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Exit Strategy</Title>

      <Description>Enter your Exit Strategy below</Description>

      <Content>
        <InputsWrapper>
          <Controller
            name="dexOrCex"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Dropdown
                placeholder="DEX or/and CEX"
                options={
                  config.signUp.company.tokenExitStrategy.dexOrCexOptions
                }
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Selection",
                })}
              />
            )}
          />

          <Controller
            name="listingPrice"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Listing price"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Price",
                })}
              />
            )}
          />

          <Controller
            name="listingDate"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <DatePicker
                placeholder="Listing date"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Date",
                })}
              />
            )}
          />

          <Controller
            name="listingExchange"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Listing exchange"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Exchange",
                })}
              />
            )}
          />
        </InputsWrapper>
      </Content>

      <ButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        <Button
          variant={buttonVariants.secondary}
          onClick={() => navigate("/sign-up/company/partners-and-influencers")}
        >
          Back
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

const Content = styled.div`
  margin-bottom: 36px;
`;

export default TokenExitStrategy;
