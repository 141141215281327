import React from "react";
import styled from "styled-components/macro";
import Countdown from "react-countdown";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

const TimeCountdown = ({ className, endDate }) => {
  const renderer = (props) => {
    const { days, hours, minutes, seconds, completed } = props;

    return (
      <Container className={className}>
        <TimeElementWrapper>
          <DayTimeElement>{days}</DayTimeElement>
          <Text>DAYS</Text>
        </TimeElementWrapper>

        <TimeElementWrapper>
          <TimeElement>{hours}</TimeElement>
          <Text>HOURS</Text>
        </TimeElementWrapper>

        <TimeElementWrapper>
          <TimeElement>{minutes}</TimeElement>
          <Text>MINUTES</Text>
        </TimeElementWrapper>

        <TimeElementWrapper>
          <AnimatePresence>
            <TimeElement
              key={seconds}
              initial={{ y: 50, opacity: 0, position: "absolute" }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0, position: "absolute" }}
              transition={{ duration: 0.3 }}
            >
              {seconds}
            </TimeElement>
          </AnimatePresence>
          <Text>SECONDS</Text>
        </TimeElementWrapper>
      </Container>
    );
  };

  return <Countdown date={endDate} renderer={renderer} />;
};

const Container = styled.div`
  display: flex;
  gap: 42px;

  @media (max-width: 768px) {
    gap: 25px;
  }
`;

const TimeElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimeElement = styled(motion.div)`
  font-size: 40px;
  font-weight: 500;
  color: #09216a;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const Text = styled.div`
  color: #09216a;
  font-size: 13px;
  margin-top: auto;
`;

const DayTimeElement = styled(TimeElement)`
  color: #ff2853;
`;

export default TimeCountdown;
