import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import {
  modalPropsSelector,
  modalTypeSelector,
} from "../../store/selectors/modal";
import { modalTypes } from "../../consts";
import InvestingInterestModal from "./content/InvestingInterestModal";
import { closeModal } from "../../store/modal/actions";
import ApplicationSubmittedModal from "./content/ApplicationSubmittedModal";
import InvestingModal from "./content/InvestingModal";
import SignUpModal from "./content/authentication/SignUpModal";
import SignInModal from "./content/authentication/SignInModal";
import ForgotPasswordModal from "./content/authentication/ForgotPasswordModal";
import ForgotPasswordSubmittedModal from "./content/authentication/ForgotPasswordSubmittedModal";
import ResetPasswordModal from "./content/authentication/ResetPasswordModal";
import ResetPasswordSubmittedModal from "./content/authentication/ResetPasswordSubmittedModal";
import SignUpSubmittedModal from "./content/authentication/SignUpSubmittedModal";
import InitialProcessCompletedModal from "./content/authentication/InitialProcessCompletedModal";
import PhoneCodeModal from "./content/authentication/PhoneCodeModal";
import EnterPhoneNumberModal from "./content/authentication/EnterPhoneNumberModal";
import ErrorModal from "./content/authentication/ErrorModal";
import CompleteRegistrationModal from "./content/authentication/CompleteRegistrationModal";
import ChooseUserModal from "./content/authentication/chooseUserModal/ChooseUserModal";
import EquityInvestingModal from "./content/EquityInvestingModal";
import EquityInvestingStatusModal from "./EquityInvestingStatusModal";
import StripePaymentModal from "./content/stripePaymentModal/StripePaymentModal";

const Modal = () => {
  const dispatch = useDispatch();

  const modalType = useSelector(modalTypeSelector);
  const modalProps = useSelector(modalPropsSelector);

  useEffect(() => {
    // close modal on browser back click
    window.onpopstate = () => {
      if (modalType) {
        dispatch(closeModal());
      }
    };
  }, [modalType]);

  const renderModalContent = () => {
    switch (modalType) {
      case modalTypes.investingInterest:
        return <InvestingInterestModal />;

      case modalTypes.applicationSubmitted:
        return <ApplicationSubmittedModal />;

      case modalTypes.investingModal:
        return <InvestingModal />;

      case modalTypes.signUpModal:
        return <SignUpModal />;

      case modalTypes.signUpSubmittedModal:
        return <SignUpSubmittedModal {...modalProps} />;

      case modalTypes.signInModal:
        return <SignInModal {...modalProps} />;

      case modalTypes.forgotPasswordModal:
        return <ForgotPasswordModal />;

      case modalTypes.forgotPasswordSubmittedModal:
        return <ForgotPasswordSubmittedModal />;

      case modalTypes.resetPasswordModal:
        return <ResetPasswordModal {...modalProps} />;

      case modalTypes.resetPasswordSubmittedModal:
        return <ResetPasswordSubmittedModal />;

      case modalTypes.initialProcessCompletedModal:
        return <InitialProcessCompletedModal />;

      case modalTypes.enterPhoneNumber:
        return <EnterPhoneNumberModal {...modalProps} />;

      case modalTypes.phoneCodeModal:
        return <PhoneCodeModal {...modalProps} />;

      case modalTypes.errorModal:
        return <ErrorModal {...modalProps} />;

      case modalTypes.completeRegistrationModal:
        return <CompleteRegistrationModal {...modalProps} />;

      case modalTypes.chooseUserModal:
        return <ChooseUserModal />;

      case modalTypes.equityInvestingModal:
        return <EquityInvestingModal {...modalProps} />;

      case modalTypes.equityInvestingStatusModal:
        return <EquityInvestingStatusModal {...modalProps} />;

      case modalTypes.stripePaymentModal:
        return <StripePaymentModal {...modalProps} />;

      default:
        return null;
    }
  };

  if (!modalType) {
    return null;
  }

  return (
    <Container>
      <Content>
        <CloseIconWrapper onClick={() => dispatch(closeModal())}>
          <CloseIcon src="/img/close-dark.svg" />
        </CloseIconWrapper>
        {renderModalContent()}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  offset: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.25);
`;

const Content = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9e3ff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;

  width: 42px;
  height: 42px;
  top: 38px;
  right: 34px;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    top: 26px;
    right: 14px;
  }
`;

const CloseIcon = styled.img`
  width: 12px;
  height: 12px;

  @media (max-width: 768px) {
    width: 9px;
    height: 9px;
  }
`;

export default Modal;
