import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "",
  props: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,

  reducers: {
    openModal: (state, action) => action.payload,
    closeModal: () => initialState,
  },
});
