import { createSlice } from "@reduxjs/toolkit";
import { investorSignUpInitialState } from "../../utils/schema";

export const investorSignUpSlice = createSlice({
  name: "investorSignUp",
  initialState: investorSignUpInitialState,
  reducers: {
    setInvestorCompletedSteps: (state, { payload: completedSteps }) => ({
      ...state,
      completedSteps,
    }),

    setInvestorContactInfo: (state, { payload: contactInfo }) => ({
      ...state,
      contactInfo,
    }),

    setInvestorCompanyInfo: (state, { payload: companyInfo }) => {
      return {
        ...state,
        companyInfo,
      };
    },

    resetInvestorSignup: () => investorSignUpInitialState,
  },
});
