import React, { useState } from "react";
import { keys } from "lodash";
import styled from "styled-components/macro";
import config from "../assets/config.json";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "./core/formElements/dropdown/Dropdown";
import { getCountryOptions } from "../utils/normalizations";
import Button from "./core/Button";
import { buttonVariants, projectFilterTypes, screenSizes } from "../consts";
import { useWindowSize } from "../hooks/useWindowSize";
import { clearFilters, setFilter } from "../store/filters/actions";
import { filtersSelector } from "../store/selectors/filters";

const countryOptions = [{ label: "All", value: "All" }, ...getCountryOptions()];

const FiltersArea = ({ className, filterTypes }) => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const filters = useSelector(filtersSelector);

  const filterTypeKeys = keys(filterTypes);
  const isSmallScreen = width <= screenSizes.medium;

  const renderIcon = () => (
    <FilterIconWrapper onClick={() => setIsOpen(true)}>
      <FilterIcon src="/img/filter.svg" />
    </FilterIconWrapper>
  );

  const renderContent = () => (
    <ContentWrapper>
      <CloseIcon src="/img/close-dark.svg" onClick={() => setIsOpen(false)} />

      <Title>Filter Options</Title>

      <DropdownsContainer className="dropdowns-container">
        {filterTypeKeys.includes(projectFilterTypes.projectStatus) && (
          <StyledDropdown
            placeholder="Project's status"
            options={config.projectStatusOptions}
            value={filters[projectFilterTypes.projectStatus]}
            onChange={(data) => {
              dispatch(
                setFilter({ type: projectFilterTypes.projectStatus, data })
              );
            }}
            {...filterTypes[projectFilterTypes.projectStatus]}
          />
        )}

        {filterTypeKeys.includes(projectFilterTypes.typeOfInvestment) && (
          <StyledDropdown
            placeholder="Type of investment"
            options={config.signUp.company.companyTypeOptions}
            value={filters[projectFilterTypes.typeOfInvestment]}
            onChange={(data) => {
              dispatch(
                setFilter({ type: projectFilterTypes.typeOfInvestment, data })
              );
            }}
            {...filterTypes[projectFilterTypes.typeOfInvestment]}
          />
        )}

        {filterTypeKeys.includes(projectFilterTypes.typeOfIndustry) && (
          <StyledDropdown
            placeholder="Type of industry"
            options={config.signUp.investor.companyInfo.typeOfIndustryOptions}
            value={filters[projectFilterTypes.typeOfIndustry]}
            onChange={(data) => {
              dispatch(
                setFilter({ type: projectFilterTypes.typeOfIndustry, data })
              );
            }}
            {...filterTypes[projectFilterTypes.typeOfIndustry]}
          />
        )}

        {filterTypeKeys.includes(projectFilterTypes.companyStage) && (
          <StyledDropdown
            placeholder="Company stage"
            options={config.signUp.company.tokenCompanyInfo.companyStageOptions}
            value={filters[projectFilterTypes.companyStage]}
            onChange={(data) => {
              dispatch(
                setFilter({ type: projectFilterTypes.companyStage, data })
              );
            }}
            {...filterTypes[projectFilterTypes.companyStage]}
          />
        )}

        {filterTypeKeys.includes(projectFilterTypes.countryOfCooperation) && (
          <StyledDropdown
            placeholder="Country of incorporation"
            options={countryOptions}
            value={filters[projectFilterTypes.countryOfCooperation]}
            onChange={(data) => {
              dispatch(
                setFilter({
                  type: projectFilterTypes.countryOfCooperation,
                  data,
                })
              );
            }}
            isMulti
            {...filterTypes[projectFilterTypes.countryOfCooperation]}
          />
        )}
      </DropdownsContainer>

      <ButtonsWrapper>
        <Button
          variant={buttonVariants.primary}
          onClick={() => setIsOpen(false)}
        >
          Search
        </Button>

        <Clear onClick={() => dispatch(clearFilters())}>Clear filter</Clear>
      </ButtonsWrapper>
    </ContentWrapper>
  );

  return (
    <Container className={className}>
      {isOpen || !isSmallScreen ? renderContent() : renderIcon()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const FilterIconWrapper = styled.div`
  width: 42px;
  height: 46px;
  background: #ffffff;
  border-radius: 8px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FilterIcon = styled.img`
  width: 14px;
  height: 15px;
`;

const ContentWrapper = styled.div`
  flex-direction: row;
  padding: inherit;
  width: 100%;
  height: inherit;
  background-color: inherit;
  position: inherit;

  @media (max-width: 768px) {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    background-color: #f3f7ff;
    padding: 86px 48px 59px 19px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
  }
`;

const CloseIcon = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: initial;
    position: absolute;
    width: 17px;
    height: 17px;
    top: 35px;
    right: 26px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: initial;
    font-weight: 500;
    font-size: 20px;
    color: #09216a;
    margin-bottom: 31px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 191px;
`;

const DropdownsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const ButtonsWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    gap: 17px;
    margin-top: auto;
    align-items: center;
  }
`;

const Clear = styled.div`
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
`;

export default FiltersArea;
