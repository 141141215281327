import React from "react";
import styled from "styled-components/macro";
import Navbar from "../../../Components/Section/Navbar";
import Footer from "../../../Components/Section/Footer";
import { capitalize, filter, map } from "lodash";
import {
  Content,
  Description,
  Filter,
  FilterBy,
  PageContainer,
  RowsWrapper,
  SearchWrapper,
  StyledBreadcrumbs,
  StyledFiltersArea,
  Title,
  TitleWrapper,
  TopElements,
} from "../shared";
import { useDispatch, useSelector } from "react-redux";
import PageSearch from "../../../Components/core/PageSearch";
import { setSearch } from "../../../store/search/actions";
import { projectFilterTypes, queryKeys, screenSizes } from "../../../consts";
import CollapsibleRow from "../CollapsibleRow";
import { getProjectOpenStatusMessage } from "../../../utils/projectUtils";
import { normalizeDollarAmount } from "../../../utils/normalizations";
import { ShowMoreButton } from "../../launchpad/shared";
import { useClientPagination } from "../../../hooks/useClientPagination";
import config from "../../../assets/config.json";
import { useProjectSearchAndFilters } from "../../../hooks/useProjectSearchAndFilters";
import { searchSelector } from "../../../store/selectors/search";
import { useFavoriteProjects } from "../../../hooks/useFavoriteProjects";
import HeartIcon from "../../../Components/HeartIcon";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeFromWatchList } from "../../../utils/api/mutations";
import { useInvestor } from "../../../utils/api/queries/useInvestor";

const Favorites = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const queryClient = useQueryClient();
  const { data: favoriteProjects } = useFavoriteProjects();
  const { data: investor } = useInvestor({
    select: (response) => response.roleData,
  });

  const { filteredData } = useProjectSearchAndFilters({
    originalData: favoriteProjects,
    searchFields: ["projectName", "description", "other_industry_type"],
  });

  const { slicedData, viewNextPage, isHasMore } = useClientPagination({
    pageSize: 9,
    allData: filteredData,
  });

  const search = useSelector(searchSelector);
  const fullName = investor?.contactInfo?.fullName;

  const { mutate: removeProjectFromWatchList } = useMutation({
    mutationFn: removeFromWatchList,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.favorites]);
      queryClient.invalidateQueries([queryKeys.projectWatchList]);
    },
  });

  const breadcrumbsLinks = [
    { text: "My profile", url: "/account" },
    { text: "My favorites", url: "/account/favorites" },
  ];

  const isDesktop = width > screenSizes.large;

  const handleHeartClick = (e, id) => {
    e.stopPropagation();

    removeProjectFromWatchList({
      projectId: id.toString(),
    });
  };

  const renderFavorites = () => (
    <RowsWrapper>
      {map(slicedData, (project) => {
        const {
          logo,
          projectName,
          website,
          fundingType,
          totalRaising,
          finalPrice,
          id,
        } = project;

        const fieldValuesArray = [
          {
            field: "Status",
            value: getProjectOpenStatusMessage(project),
            isInitiallyShowed: true,
          },
          {
            field: "Total raising",
            value: normalizeDollarAmount(totalRaising),
            isInitiallyShowed: true,
          },
          {
            field: "Type of investment",
            value: capitalize(fundingType),
            isInitiallyShowed: true,
          },
          {
            field: "Share price",
            value: normalizeDollarAmount(finalPrice),
            isInitiallyShowed: true,
          },
          {
            field: "Visit project page",
            value: website,
            isInitiallyShowed: true,
            isLink: true,
          },
        ];

        return (
          <StyledCollapsibleRow
            key={projectName}
            logo={logo}
            name={projectName}
            fieldValuesArray={fieldValuesArray}
            isCollapsible={!isDesktop}
            openMobileHeight="247px"
            EndRowComponent={() => (
              <StyledHeartIcon
                isFilled
                onClick={(e) => handleHeartClick(e, id)}
              />
            )}
          />
        );
      })}
    </RowsWrapper>
  );

  return (
    <PageContainer>
      <Navbar status={"account"} />

      <Content>
        <StyledBreadcrumbs linksArray={breadcrumbsLinks} />
        <TopElements>
          <TitleWrapper>
            <Title>My favorites</Title>
            <Description>
              Viewing favorite projects of <b>{fullName}</b>
            </Description>
          </TitleWrapper>

          <SearchWrapper>
            <PageSearch
              value={search}
              onChange={(newValue) => dispatch(setSearch(newValue))}
              placeholder="Search by keywords..."
            />

            <Filter>
              <FilterBy>Filter by</FilterBy>

              <StyledFiltersArea
                filterTypes={{
                  [projectFilterTypes.projectStatus]: {
                    options: filter(
                      config.projectStatusOptions,
                      ({ value }) => value !== "upcoming"
                    ),
                  },
                  [projectFilterTypes.typeOfInvestment]: null,
                }}
              />
            </Filter>
          </SearchWrapper>
        </TopElements>

        {renderFavorites()}

        {isHasMore && (
          <ShowMoreButton onClick={viewNextPage}>Show More</ShowMoreButton>
        )}
      </Content>

      <Footer />
    </PageContainer>
  );
};

const StyledCollapsibleRow = styled(CollapsibleRow)`
  .content {
    height: initial;
  }
`;

const StyledHeartIcon = styled(HeartIcon)`
  width: 56px;
  height: 52px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(160, 184, 255, 0.35);
  border-radius: 8px;
  margin-left: 25px;

  @media (max-width: 1280px) {
    width: 28px;
    height: 26px;

    .icon {
      width: 13px;
      height: 11px;
    }
  }
`;

export default Favorites;
