import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "./StripePaymentForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

// 4242424242424242 - card for success payment
// 4000000000009995 - card for fail payment
// 4000002500003155 - card for requires auth payment

const StripePaymentModal = ({ amount, projectId }) => {
  const options = {
    mode: "payment",
    // need to multiply by 100, because stripe receives this value in cents
    amount: amount * 100,
    currency: "usd",
    paymentMethodCreation: "manual",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripePaymentForm amount={amount} projectId={projectId} />
    </Elements>
  );
};

export default StripePaymentModal;
