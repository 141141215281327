import React from "react";
import styled, { css } from "styled-components/macro";

const Card = ({ title, content, onClick, disabled }) => (
  <Container onClick={disabled ? undefined : onClick} disabled={disabled}>
    <Title>{title}</Title>

    <Content>{content}</Content>

    <Icon src="/img/diagonal-arrow.svg" />
  </Container>
);

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Container = styled.div`
  height: 136px;
  background: white;
  box-shadow: 0 0 15px 3px rgba(88, 128, 255, 0.4);
  border-radius: 6px;
  padding: 19px 21px;
  cursor: pointer;
  color: #09216a;
  position: relative;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
          cursor: default;
        `
      : css`
          &:hover {
            ${Title} {
              color: #ff2853;
            }
          }
        `}
`;

const Content = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const Icon = styled.img`
  position: absolute;
  bottom: 9px;
  right: 14px;
  width: 29px;
  height: 29px;
`;

export default Card;
