import React from "react";
import { ModalContent, ModalText, ModalTitle, SubmitButton } from "./shared";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../store/modal/actions";
import { modalTypes } from "../../../../consts";

const InitialProcessCompletedModal = () => {
  const dispatch = useDispatch();
  const handleNext = () => {
    dispatch(
      openModal({ type: modalTypes.signInModal, afterEmailVerification: true })
    );
  };

  return (
    <StyledModalContent>
      <Animation src="/img/thumbs-up.gif" />

      <StyledModalTitle>Excellent!</StyledModalTitle>

      <Description>
        The initial verification step was successfully completed
      </Description>

      <SubmitButton variant="primary" onClick={handleNext}>
        Next
      </SubmitButton>
    </StyledModalContent>
  );
};

const StyledModalContent = styled(ModalContent)`
  padding-top: 40px;
`;

const Animation = styled.img`
  width: 100px;
  height: 100px;
  margin: 0 auto;
`;

const StyledModalTitle = styled(ModalTitle)`
  text-align: center;
`;

const Description = styled(ModalText)`
  margin-bottom: 50px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
export default InitialProcessCompletedModal;
