import React from "react";
import Navbar from "../../../Components/Section/Navbar";
import Footer from "../../../Components/Section/Footer";
import styled from "styled-components/macro";
import moment from "moment";
import {
  Content,
  Description,
  PageContainer,
  RowsWrapper,
  SearchWrapper,
  StyledBreadcrumbs,
  Title,
  TitleWrapper,
  TopElements,
} from "../shared";
import { useDispatch, useSelector } from "react-redux";
import PageSearch from "../../../Components/core/PageSearch";
import { setSearch } from "../../../store/search/actions";
import { screenSizes } from "../../../consts";
import CollapsibleRow from "../CollapsibleRow";
import { map } from "lodash";
import { ShowMoreButton } from "../../launchpad/shared";
import { useClientPagination } from "../../../hooks/useClientPagination";
import { searchSelector } from "../../../store/selectors/search";
import { useFuzzySearch } from "../../../hooks/useFuzzySearch";
import CollapsibleProjectInfo from "../CollapsibleProjectInfo";
import { projectsListSelector } from "../../../store/selectors/projects";
import { useWindowSize } from "../../../hooks/useWindowSize";
import ProjectInfo from "../ProjectInfo";
import { useProjectWatchList } from "../../../utils/api/queries/useProjectWatchList";
import { useParams } from "react-router-dom";

const InvestorsWatchlist = () => {
  const dispatch = useDispatch();
  const projectsList = useSelector(projectsListSelector);
  const { projectId } = useParams();
  const { data: investors } = useProjectWatchList({ projectId });

  const { filteredData } = useFuzzySearch({
    originalData: investors,
    searchFields: ["investorData.contactInfo.fullName"],
  });

  const { slicedData, viewNextPage, isHasMore } = useClientPagination({
    pageSize: 9,
    allData: filteredData,
  });

  const { width } = useWindowSize();

  const search = useSelector(searchSelector);

  const isDesktop = width > screenSizes.large;
  const project = projectsList[projectId];

  const breadcrumbsLinks = [
    { text: "My profile", url: "/account" },
    { text: "Investors watchlist", url: "/account/investors-watchlist" },
  ];

  const getFieldValuesArray = (investor) => {
    const { createdAt, investorData } = investor;
    const { phoneCode, phoneNumber, email } = investorData.contactInfo;

    return [
      {
        field: "Phone number",
        value: `${phoneCode.value}${phoneNumber}`,
        isInitiallyShowed: true,
        isMobileFullLine: true,
      },
      {
        field: "Email",
        value: email,
        isInitiallyShowed: true,
        isMobileFullLine: true,
      },
      {
        field: "Date watchlist",
        value: moment(createdAt.seconds * 1000).format("DD.MM.YYYY"),
        isInitiallyShowed: true,
        isMobileFullLine: true,
      },
      {
        field: "",
        value: "Interested",
        isInBadge: true,
        isInitiallyShowed: true,
        isMobileFullLine: true,
      },
    ];
  };

  const renderInvestors = () => (
    <RowsWrapper>
      {map(slicedData, (investor) => {
        const { createdAt, investorData } = investor;
        const { fullName } = investorData.contactInfo;

        const fieldValuesArray = getFieldValuesArray(investor);

        return (
          <StyledCollapsibleRow
            key={createdAt}
            logo={"/img/avatar.svg"}
            name={fullName}
            fieldValuesArray={fieldValuesArray}
            isCollapsible={!isDesktop}
            openMobileHeight="290px"
          />
        );
      })}
    </RowsWrapper>
  );

  return (
    <PageContainer>
      <Navbar status={"account"} />

      <Content>
        <StyledBreadcrumbs linksArray={breadcrumbsLinks} />
        <TopElements>
          <TitleWrapper>
            <Title>Project’s watchlist</Title>
            <Description>
              Viewing investors information of <b>{project.projectName}</b>
            </Description>
          </TitleWrapper>

          <SearchWrapper>
            <PageSearch
              value={search}
              onChange={(newValue) => dispatch(setSearch(newValue))}
              placeholder="Search by name..."
            />
          </SearchWrapper>
        </TopElements>

        <ProjectInfoWrapper>
          {isDesktop ? (
            <ProjectInfo
              project={project}
              numberOfInvestors={investors?.length}
            />
          ) : (
            <CollapsibleProjectInfo
              project={project}
              numberOfInvestors={investors?.length}
            />
          )}
        </ProjectInfoWrapper>

        {renderInvestors()}
        {isHasMore && (
          <ShowMoreButton onClick={viewNextPage}>Show More</ShowMoreButton>
        )}
      </Content>

      <Footer />
    </PageContainer>
  );
};

const StyledCollapsibleRow = styled(CollapsibleRow)`
  .grid {
    padding-left: 48px;
  }

  .logo {
    width: 21px;
    height: 21px;

    @media (max-width: 1280px) {
      height: 14px;
      width: 14px;
    }
  }
`;

const ProjectInfoWrapper = styled.div`
  margin-bottom: 21px;
`;

export default InvestorsWatchlist;
