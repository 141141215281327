import React from "react";
import styled from "styled-components/macro";
import { buttonVariants } from "../../../../consts";
import Button from "../../../core/Button";

const SwapError = ({ retry }) => (
  <Container>
    <Message>The purchase process failed</Message>

    <Button variant={buttonVariants.primary} onClick={() => retry()}>
      Try again
    </Button>
  </Container>
);

const Container = styled.div`
  margin: auto;
`;

const Message = styled.div`
  font-weight: 600;
  font-size: 23px;
  color: #edf2ff;
  margin: 0 20px 100px 20px;
`;

export default SwapError;
