import React, { useEffect } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Components/Section/Navbar";
import Footer from "../../Components/Section/Footer";
import PoolAlert from "../../Components/Section/PoolAlert";
import TimeCountdown from "../../Components/TimeCountdown";
import PaddingBox from "../../Components/PaddingBox";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ShowMoreBox from "../../Components/ShowMoreBox";
import {
  buttonVariants,
  fundingTypes,
  modalTypes,
  projectOpenStatuses,
  queryKeys,
  requestStatus,
  roles,
  screenSizes,
} from "../../consts";
import Button from "../../Components/core/Button";
import ProjectInfoCards from "./ProjectInfoCards";
import SharesPoolDetails from "./SharesPoolDetails";
import { projectsListSelector } from "../../store/selectors/projects";
import { useWindowSize } from "../../hooks/useWindowSize";
import { openModal } from "../../store/modal/actions";
import { useInvestor } from "../../utils/api/queries/useInvestor";
import { useWeb3React } from "@web3-react/core";
import {
  getProjectOpenStatus,
  getProjectOpenStatusMessage,
} from "../../utils/projectUtils";
import HeartIcon from "../../Components/HeartIcon";
import { useIsInWatchlist } from "../../utils/api/queries/useIsInWatchlist";
import { toLower } from "lodash-es";
import { useUserDBData } from "../../utils/api/queries/useUserDBData";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addToWatchList, removeFromWatchList } from "../../utils/api/mutations";
import { useStripePaymentStatus } from "../../hooks/useStripePaymentStatus";
import { useProjectListener } from "../../hooks/useProjectListener";

const CompanyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, paymentStatus: routerPaymentStatus } = useParams();
  const { width } = useWindowSize();
  const { active } = useWeb3React();
  const queryClient = useQueryClient();
  const [urlParams] = useSearchParams();
  const stripePaymentStatus = useStripePaymentStatus(
    urlParams.get("payment_intent_client_secret")
  );
  const paymentStatus = routerPaymentStatus || stripePaymentStatus;

  const { data } = useUserDBData();
  const projectsList = useSelector(projectsListSelector);
  const isProjectInWatchList = useIsInWatchlist(id);
  const project = projectsList?.[id];

  useProjectListener({
    userId: project?.projectCreatorId,
    role: project?.projectCreatorRole,
    projectId: id,
  });

  const role = data?.role;
  const isInvestorMode = role === roles.investor.value;

  const { data: investorResponse } = useInvestor({
    enabled: isInvestorMode,
  });

  const { mutate: addProjectToWatchList } = useMutation({
    mutationFn: addToWatchList,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.favorites]);
      queryClient.invalidateQueries([queryKeys.projectWatchList]);
    },
  });

  const { mutate: removeProjectFromWatchList } = useMutation({
    mutationFn: removeFromWatchList,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.favorites]);
      queryClient.invalidateQueries([queryKeys.projectWatchList]);
    },
  });

  const { roleData: investorData, verified: isInvestorVerified } =
    investorResponse || {};

  useEffect(() => {
    if (paymentStatus === requestStatus.success) {
      dispatch(
        openModal({
          type: modalTypes.equityInvestingStatusModal,
          investorData,
          status: requestStatus.success,
        })
      );

      navigate(`/company-page/${id}`);
    } else if (paymentStatus === requestStatus.error) {
      dispatch(
        openModal({
          type: modalTypes.equityInvestingStatusModal,
          investorData,
          status: requestStatus.error,
        })
      );

      navigate(`/company-page/${id}`);
    }
  }, [paymentStatus]);

  const {
    logo,
    projectName,
    description,
    startTime,
    endTime,
    fundingType,
    swapRate,
    projectCreatorId,
    availableAmount,
  } = project;

  const isToken = toLower(fundingType) === fundingTypes.token;
  const withConnect = isToken && isInvestorMode;

  const openStatus = getProjectOpenStatus(project);

  const getCountdownEndDate = () => {
    switch (openStatus) {
      case projectOpenStatuses.upcoming:
        return startTime;

      case projectOpenStatuses.open:
        return endTime;

      default:
        return moment().toDate();
    }
  };

  const toggleHeart = () => {
    if (isProjectInWatchList) {
      removeProjectFromWatchList({
        projectId: id,
      });
    } else {
      addProjectToWatchList({
        projectId: id,
        projectCreatorId: projectCreatorId,
      });
    }
  };

  const renderTextElements = () => (
    <>
      <Logo src={logo} />
      <Title>{projectName}</Title>
      <Subtitle>Project Overview</Subtitle>
      <StyledShowMoreBox maxHeight={100}>{description}</StyledShowMoreBox>
    </>
  );

  const renderButtonsWrapper = () => (
    <ButtonsWrapper>
      <Button
        height="100%"
        width="153px"
        variant={buttonVariants.primary}
        disabled={
          openStatus !== projectOpenStatuses.open ||
          (isToken && !active) ||
          !isInvestorVerified
        }
        onClick={() => {
          isToken
            ? dispatch(
                openModal({
                  type: modalTypes.investingModal,
                  project,
                  investorData,
                  swapRate: swapRate,
                })
              )
            : dispatch(
                openModal({
                  type: modalTypes.equityInvestingModal,
                  investorData,
                  projectId: id,
                  minAmount: 0,
                  maxAmount: availableAmount,
                })
              );
        }}
      >
        Invest
      </Button>

      <HeartIcon isFilled={isProjectInWatchList} onClick={toggleHeart} />
    </ButtonsWrapper>
  );

  return (
    <Container>
      <Navbar status={"launchpad"} withConnect={withConnect} />

      <Content>
        <ProjectInfo>
          {width <= screenSizes.large ? (
            <SmallScreenWrapper>
              <StatusMessage>
                {getProjectOpenStatusMessage(project)}
              </StatusMessage>

              <StyledTimeCountdown endDate={getCountdownEndDate()} />

              {renderTextElements()}

              {isInvestorMode && renderButtonsWrapper()}

              <ProjectInfoCards project={project} />
            </SmallScreenWrapper>
          ) : (
            <LargeScreenWrapper>
              <Column>
                {renderTextElements()}

                {isInvestorMode && renderButtonsWrapper()}
              </Column>

              <Column>
                <StatusMessage>
                  {getProjectOpenStatusMessage(project)}
                </StatusMessage>
                <StyledTimeCountdown endDate={getCountdownEndDate()} />
                <ProjectInfoCards project={project} />
              </Column>
            </LargeScreenWrapper>
          )}
        </ProjectInfo>
      </Content>

      <SharesPoolDetails project={project} />

      <PoolAlert />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusMessage = styled.div`
  color: #09216a;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
  grid-area: status-message;
`;

const ProjectInfo = styled(PaddingBox)`
  background: linear-gradient(101.01deg, #d3deff -21.7%, #ffffff 29.15%);
  display: flex;
  flex-direction: column;
  padding-top: 106px;
  padding-bottom: 72px;

  @media (min-width: 768px) {
    padding-top: 155px;
  }
`;

const StyledTimeCountdown = styled(TimeCountdown)`
  margin: 0 auto 45px auto;
  justify-content: center;
  grid-area: time-countdown;

  @media (max-width: 768px) {
    margin-bottom: 45px;
  }
`;

const Logo = styled.img`
  margin-bottom: 22px;
  grid-area: logo;

  width: 173px;
  height: 36px;

  @media (max-width: 768px) {
    width: 120px;
    height: 25px;
  }
`;

const Title = styled.div`
  color: #09216a;
  font-weight: 600;
  font-size: 40px;
  grid-area: title;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const StyledShowMoreBox = styled(ShowMoreBox)`
  margin-bottom: 25px;
  color: #09216a;
  font-weight: 300;
  font-size: 17px;
  max-width: 564px;

  @media (max-width: 1920px) {
    max-width: 391px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    max-width: none;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 64px;
  height: 56px;

  @media (max-width: 768px) {
    height: 45px;
  }
`;

const Subtitle = styled.div`
  color: #09216a;
  font-weight: 500;
  font-size: 25px;
  text-decoration-line: underline;
  margin-bottom: 13px;

  @media (max-width: 1280px) {
    display: none;
  }
`;

const SmallScreenWrapper = styled.div``;

const LargeScreenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export default CompanyPage;
