import React from "react";
import styled from "styled-components/macro";

const Track = ({ props, state }) => {
  const { min } = props;
  const { value, index } = state;

  return <Container {...props} index={index} value={value} min={min} />;
};

const Container = styled.div`
  border-radius: 5px;
  height: 100%;
  z-index: ${({ index, value, min }) => (!index && value !== min ? 1 : 0)};
  background-color: ${({ index }) => (index === 1 ? "#DEE6FF" : "#FF2853")};
`;

export default Track;
