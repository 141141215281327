import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { newProSlider } from "../../config/settings";
import { normalizeDollarAmount } from "../../utils/normalizations";
import HorizontalProgressLine from "../../Components/core/HorizontalProgressLine";
import { fundingTypes, projectOpenStatuses } from "../../consts";
import { getProjectOpenStatus } from "../../utils/projectUtils";
import { filter } from "lodash-es";

const NewProjects = () => {
  const { projects } = useSelector((state) => state);
  const { projectsList } = projects;
  const navigate = useNavigate();

  let newProjects = filter(
    projectsList,
    (project) => getProjectOpenStatus(project) === projectOpenStatuses.open
  );

  return (
    <div className="new-project-section">
      <div className="container-fluid">
        <div className="row position-relative d-flex align-items-center">
          <div className="col-lg-2 col-md-12 position-md-absolute">
            <div className="new-project-title">
              <img src="/img/frame.svg" alt="" />
              <h2 className="main-title text-capitalize py-lg-1">
                new <br />
                projects
              </h2>
              <img src="/img/frame.svg" alt="" />
            </div>
          </div>
          {newProjects.length === 0 && (
            <div
              style={{ height: "200px" }}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <h5 className="text-capitalize text-center py-5">
                  coming soon...
                </h5>
              </div>
            </div>
          )}
          <div className="col-9 mx-auto">
            {newProjects.length > 0 && (
              <div className="new-project-items all">
                <Slider {...newProSlider}>
                  {newProjects &&
                    newProjects.slice(0, 4).map((item, index) => {
                      const { fundingType, id, totalRaising, totalRaised } =
                        item;
                      const progress = Math.round(
                        (totalRaised / totalRaising) * 100
                      );

                      const left = totalRaising - totalRaised;

                      return (
                        <div key={index}>
                          <div className="px-2 mt-md-5">
                            <div className="new-project-item">
                              <Link to={`/company-page/${id}`}>
                                <div className="card">
                                  <img src={item.logo} alt="" />
                                  <h4> {item.projectName}</h4>
                                  <p>
                                    <div className="text-center">
                                      Total Raising:
                                    </div>
                                    <div className="text-center">
                                      <span className="text-bold">
                                        {normalizeDollarAmount(totalRaising)}
                                      </span>
                                    </div>
                                  </p>
                                  <p>
                                    <div className="text-center">
                                      Left For Completion:
                                    </div>
                                    <div className="text-center">
                                      <span className="text-bold">
                                        {fundingType.toLowerCase() ===
                                        fundingTypes.token
                                          ? left == 1
                                            ? `${left} token`
                                            : `${left} tokens`
                                          : normalizeDollarAmount(left)}
                                      </span>
                                    </div>
                                  </p>
                                </div>
                              </Link>
                              <HorizontalProgressLine
                                fillPercent={progress}
                                className={"my-2"}
                                backColor="white"
                                fillColor="#FF2853"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {!newProjects.length && (
                    <div>
                      <h5 className="text-capitalize text-center py-5">
                        coming soon...
                      </h5>
                    </div>
                  )}
                </Slider>
              </div>
            )}
            {newProjects.length > 0 && (
              <div className="new-project-items tablet">
                <div className="row">
                  {newProjects &&
                    newProjects.slice(0, 4).map((item, index) => {
                      const { fundingType, totalRaising, id, totalRaised } =
                        item;

                      const progress = Math.round(
                        (totalRaised / totalRaising) * 100
                      );
                      const left = totalRaising - totalRaised;

                      return (
                        <div key={index} className="col-6 mt-5 m-auto">
                          <div className="new-project-item">
                            <Link to={`/company-page/${id}`}>
                              <div className="card">
                                <img src={item.logo} alt="" />
                                <h4> {item.projectName}</h4>
                                <p>
                                  <div className="text-center">
                                    Total Raising:
                                  </div>
                                  <div className="text-center">
                                    <span className="text-bold">
                                      {normalizeDollarAmount(totalRaising)}
                                    </span>
                                  </div>
                                </p>
                                <p>
                                  <div className="text-center">
                                    Left For Completion:
                                  </div>
                                  <div className="text-center">
                                    <span className="text-bold">
                                      {fundingType.toLowerCase() ===
                                      fundingTypes.token
                                        ? left == 1
                                          ? `${left} token`
                                          : `${left} tokens`
                                        : normalizeDollarAmount(left)}
                                    </span>
                                  </div>
                                </p>
                              </div>
                            </Link>
                            <HorizontalProgressLine
                              fillPercent={progress}
                              className={"my-2"}
                              backColor="white"
                              fillColor="#FF2853"
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {!newProjects.length && (
                  <div>
                    <h5 className="text-capitalize text-center py-5">
                      coming soon...
                    </h5>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {newProjects.length > 0 && (
          <div className="row">
            <div className="col-md-12 text-end">
              <button onClick={() => navigate("/launchpad")}>
                ALL PROJECTS
                <span>
                  <img src="/img/arrow-right.svg" alt="" />
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewProjects;
