import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import Button from "./core/Button";
import { buttonVariants } from "../consts";

const Congratulations = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Image src="/img/flag.svg" alt="" />

      <Title>Congratulations</Title>

      <Subtitle>The registration was completed successfully</Subtitle>

      <Text>
        Our team will check your data and confirm your registration within 24
        hours
      </Text>

      <Button
        variant={buttonVariants.primary}
        onClick={() => navigate("/")}
        width="189px"
        height="48px"
      >
        Go to Homepage
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000f3b;
`;

const Image = styled.img`
  width: 42px;
  height: 67px;
  margin-bottom: 14px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
`;

const Subtitle = styled.div`
  font-size: 23px;
  font-weight: 500;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 14px;
  max-width: 448px;
`;

export default Congratulations;
