import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { buttonVariants } from "../../../../consts";
import { closeModal } from "../../../../store/modal/actions";
import Button from "../../../core/Button";

const SwapSuccess = () => {
  const dispatch = useDispatch();

  return (
    <Container>
      <Message>The purchase was made successfully</Message>

      <Button
        variant={buttonVariants.primary}
        onClick={() => dispatch(closeModal())}
      >
        Close
      </Button>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
`;

const Message = styled.div`
  font-weight: 600;
  font-size: 23px;
  color: #edf2ff;
  margin: 0 20px 100px 20px;
`;

export default SwapSuccess;
