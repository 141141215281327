import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { filter, isEmpty, last } from "lodash-es";

const INPUT_ID = "code-input";
const INPUTS_AMOUNT = 6;

const CodeInputs = ({ className, onFilled, isError }) => {
  const [code, setCode] = useState([]);

  useEffect(() => {
    const filledInputs = filter(code, (char) => char);

    if (filledInputs.length === INPUTS_AMOUNT) {
      onFilled(code.toString().replaceAll(",", ""));
    }
  }, [code]);
  const handleInputChange = (e, index) => {
    let typedValue = e.target.value;
    const isRemovingValue = !typedValue.length;

    // Filled and typing
    if (!isEmpty(code[index]) && !isRemovingValue) {
      typedValue = last(e.target.value);
    }

    const newValues = [...code];
    newValues[index] = typedValue;

    setCode(newValues);

    if (isRemovingValue) {
      focusOnPrevInput(index);
    } else {
      focusOnNextInput(index);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const copiedValue = e.clipboardData.getData("Text");

    if (!copiedValue) {
      return;
    }

    const slicedValue = copiedValue.slice(0, INPUTS_AMOUNT).split("");
    setCode(slicedValue);
  };

  const focusOnNextInput = (currentInputIndex) => {
    if (currentInputIndex + 1 === INPUTS_AMOUNT) {
      return;
    }

    window.test = document.getElementById(
      `${INPUT_ID}-${currentInputIndex + 1}`
    );
    document.getElementById(`${INPUT_ID}-${currentInputIndex + 1}`).focus();
  };
  const focusOnPrevInput = (currentInputIndex) => {
    if (!currentInputIndex) {
      return;
    }

    document.getElementById(`${INPUT_ID}-${currentInputIndex - 1}`).focus();
  };

  const renderInputs = () => {
    const result = [];

    for (let i = 0; i < INPUTS_AMOUNT; i++) {
      result.push(
        <InputElement
          key={i}
          value={code[i] || ""}
          onChange={(e) => handleInputChange(e, i)}
          type="number"
          onPaste={handlePaste}
          id={`${INPUT_ID}-${i}`}
          isError={isError}
        />
      );
    }

    return result;
  };

  return (
    <Container className={className}>
      <InputsWrapper>{renderInputs()}</InputsWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const InputsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const InputElement = styled.input`
  outline: none;
  width: 50px;
  height: 64px;
  border: 1px solid #727272;
  border-radius: 6px;
  font-weight: 400;
  font-size: 26px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;

  ${({ isError }) =>
    isError
      ? css`
          border: 1.5px solid #ff2853;
        `
      : css`
          &:focus {
            border: 1.5px solid #000000;
          }
        `}

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export default CodeInputs;
