import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
} from "../../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/core/Button";
import {
  atLeast3CharsRegex,
  buttonVariants,
  signUpCompanyEquitySteps,
} from "../../../../consts";
import {
  setCompanyCompletedSteps,
  setPartnersAndClients,
} from "../../../../store/companySignUp/actions";
import { getFieldError } from "../../../../utils/formUtils";
import {
  ButtonsWrapper,
  Container,
  Description,
  InputsWrapper,
  SectionTitle,
  Title,
} from "../styled";
import Input from "../../../../Components/core/formElements/Input";
import ValidateIcon from "../../../../Components/core/icons/ValidateIcon";
import Dropdown from "../../../../Components/core/formElements/dropdown/Dropdown";
import config from "../../../../assets/config.json";
import { filterArrayEmptyValues } from "../../../../utils/normalizations";
import Checkbox from "../../../../Components/core/formElements/Checkbox";

const PartnersAndClients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { partnersAndClients } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);

  const { handleSubmit, control, watch } = useForm({
    defaultValues: partnersAndClients,
  });

  const isRegulated = watch("isRegulated")?.value;
  const isHaveClients = watch("isHaveClients");
  const isHaveUsers = watch("isHaveUsers");

  const onSubmit = (data) => {
    const clients = filterArrayEmptyValues(data.clients);

    dispatch(setPartnersAndClients({ ...data, clients }));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyEquitySteps.partnersAndClients,
      ])
    );

    navigate("/sign-up/company/equity-exit-strategy");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Partners & clients</Title>

      <Description>Enter your partners & clients below</Description>

      <Content>
        <InputsWrapper>
          <SectionTitle>Do you have:</SectionTitle>

          <CheckboxesWrapper>
            <Controller
              name="isHaveClients"
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <Checkbox
                  label="Clients"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <Controller
              name="isHaveUsers"
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <Checkbox
                  label="Users"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </CheckboxesWrapper>

          {(isHaveClients || isHaveUsers) && (
            <Controller
              name="numberOfClientsUsers"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <Input
                  placeholder="Number of clients / users"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isNumeric
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Number",
                  })}
                />
              )}
            />
          )}

          <Controller
            name="topPartners"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Top partners"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Partners info",
                })}
              />
            )}
          />

          <Controller
            name="facebookLink"
            control={control}
            rules={{ pattern: atLeast3CharsRegex }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              const regex = new RegExp(atLeast3CharsRegex);
              const isValid = regex.test(value);

              return (
                <Input
                  placeholder="Facebook link"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  InnerComponent={() => <ValidateIcon isValid={isValid} />}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Facebook link",
                  })}
                />
              );
            }}
          />
          <Controller
            name="instagramLink"
            control={control}
            rules={{ pattern: atLeast3CharsRegex }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              const regex = new RegExp(atLeast3CharsRegex);
              const isValid = regex.test(value);

              return (
                <Input
                  placeholder="Instagram link"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  InnerComponent={() => <ValidateIcon isValid={isValid} />}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Instagram link",
                  })}
                />
              );
            }}
          />
          <Controller
            name="linkedInLink"
            control={control}
            rules={{ pattern: atLeast3CharsRegex }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              const regex = new RegExp(atLeast3CharsRegex);
              const isValid = regex.test(value);

              return (
                <Input
                  placeholder="LinkedIn link"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  InnerComponent={() => <ValidateIcon isValid={isValid} />}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "LinkedIn link",
                  })}
                />
              );
            }}
          />
          <Controller
            name="isRegulated"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <Dropdown
                  placeholder="Is your company regulated?"
                  options={config.shared.yesNoOptions}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={getFieldError({
                    error,
                    fieldLabel: "Selection",
                  })}
                />
              );
            }}
          />
          {isRegulated && (
            <Controller
              name="jurisdiction"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="Enter what jurisdiction"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={getFieldError({
                      error,
                      fieldLabel: "Jurisdiction",
                    })}
                  />
                );
              }}
            />
          )}
        </InputsWrapper>
      </Content>

      <ButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        <Button
          variant={buttonVariants.secondary}
          onClick={() => navigate("/sign-up/company/raising-info")}
        >
          Back
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

const Content = styled.div`
  margin-bottom: 57px;
`;

const CheckboxesWrapper = styled.div`
  display: flex;
  gap: 25px;
`;

export default PartnersAndClients;
