import React from "react";
import styled from "styled-components/macro";
import PaddingBox from "../../Components/PaddingBox";
import { useSelector } from "react-redux";
import { map } from "lodash";
import { ProjectsWrapper, SectionTitle, ShowMoreButton } from "./shared";
import UpcomingClosedProject from "./UpcomingClosedProject";
import moment from "moment";
import { getAmountOfDaysUtilDate } from "../../utils/projectUtils";
import { closedFilteredProjectsSelector } from "../../store/selectors/projects";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useClientPagination } from "../../hooks/useClientPagination";
import { screenSizes } from "../../consts";

const ClosedProjects = () => {
  const { width } = useWindowSize();
  const closedProjects = useSelector(closedFilteredProjectsSelector);

  const { slicedData, viewNextPage, isHasMore } = useClientPagination({
    pageSize: width > screenSizes.xlarge ? 10 : 8,
    allData: closedProjects,
  });

  if (!slicedData?.length) {
    return null;
  }

  return (
    <Container>
      <StyledSectionTitle>Closed Projects</StyledSectionTitle>

      <ProjectsWrapper>
        {map(
          slicedData,
          ({
            logo,
            projectName,
            totalRaised,
            startTime,
            id,
            maxAllocation,
          }) => {
            const startMoment = moment(startTime);
            const daysAmount = getAmountOfDaysUtilDate(startMoment);

            return (
              <UpcomingClosedProject
                key={projectName}
                logo={logo}
                name={projectName}
                totalRaised={totalRaised}
                daysAmount={daysAmount}
                projectId={id}
                maxAllocation={maxAllocation}
                isClosed
              />
            );
          }
        )}
      </ProjectsWrapper>

      {isHasMore && (
        <ShowMoreButton onClick={viewNextPage}>Show More</ShowMoreButton>
      )}
    </Container>
  );
};

const Container = styled(PaddingBox)`
  flex-direction: column;
  background-color: #09216a;
  padding-top: 58px;
  padding-bottom: 84px;

  @media (max-width: 1920px) {
    padding-top: 65px;
    padding-bottom: 101px;
  }

  @media (max-width: 1280px) {
    padding-top: 51px;
    padding-bottom: 60px;
  }

  @media (max-width: 768px) {
    padding-top: 54px;
    padding-bottom: 88px;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  color: #e4ebff;
`;

export default ClosedProjects;
