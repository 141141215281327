import React from "react";
import styled from "styled-components/macro";

const Socials = ({ className, project }) => {
  const { facebook, instagram, linkedin, telegram, discord } = project;

  return (
    <Container className={className}>
      <Title>Socials</Title>

      <IconsWrapper>
        {facebook && (
          <Icon
            src="/img/facebook.svg"
            onClick={() => {
              window.open(facebook);
            }}
          />
        )}

        {instagram && (
          <Icon
            src="/img/instagram.svg"
            onClick={() => {
              window.open(instagram);
            }}
          />
        )}

        {linkedin && (
          <Icon
            src="/img/linkedin.svg"
            onClick={() => {
              window.open(linkedin);
            }}
          />
        )}

        {telegram && (
          <Icon
            src="/img/telegram.svg"
            onClick={() => {
              window.open(telegram);
            }}
          />
        )}

        {discord && (
          <Icon
            src="/img/discord.svg"
            onClick={() => {
              window.open(discord);
            }}
          />
        )}
      </IconsWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  color: #09216a;
  font-weight: 500;
  font-size: 16px;
`;

const IconsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

export default Socials;
