import styled from "styled-components/macro";
import PaddingBox from "../../Components/PaddingBox";
import Breadcrumbs from "../../Components/core/Breadcrumbs";
import FiltersArea from "../../Components/FiltersArea";

export const FieldValue = ({ className, field, value, isVertical }) => (
  <Container className={className} isVertical={isVertical}>
    {field && <Field>{field}:</Field>}
    <Value className="value">{value}</Value>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? "column" : "row")};
  color: #000f3c;
  font-weight: 300;
  font-size: 17px;

  @media (max-width: 1280px) {
    font-size: 13px;
  }
`;

const Field = styled.div`
  margin-right: 5px;
`;

const Value = styled.div`
  font-weight: 600;
`;

export const Title = styled.div`
  color: #000f3c;
  font-weight: 600;
  font-size: 50px;
  margin-bottom: 30px;
  line-height: 50px;

  @media (max-width: 1280px) {
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

export const Description = styled.div`
  color: #000f3c;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 53px;

  @media (max-width: 1280px) {
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eaf0ff;
`;

export const Content = styled(PaddingBox)`
  padding-top: 175px;
  padding-bottom: 110px;

  @media (max-width: 768px) {
    padding-top: 82px;
    padding-bottom: 66px;
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 20px;
`;

export const TopElements = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1280px) {
    flex-direction: column;
    margin-bottom: 29px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 30px;
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 834px;
  width: 100%;

  @media (max-width: 1280px) {
    max-width: initial;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 10px;
  }
`;

export const StyledFiltersArea = styled(FiltersArea)`
  width: 100%;

  .dropdowns-container {
    gap: 50px;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const FilterBy = styled.div`
  margin-right: 50px;
  color: #000f3c;
  font-weight: 400;
  font-size: 17px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const RowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 86px;

  @media (max-width: 1280px) {
    gap: 10px;
    margin-bottom: 34px;
  }
`;
