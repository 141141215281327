import React from "react";
import styled from "styled-components/macro";
import Checkbox from "../Checkbox";
import { components } from "react-select";

const OptionWithCheckbox = (props) => {
  const { children, ...rest } = props;
  const { isSelected } = rest;

  return (
    <components.Option {...rest}>
      <OptionWrapper>
        <Checkbox value={isSelected} label={props?.label}>
          {children}
        </Checkbox>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export default OptionWithCheckbox;
