import React, { useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../core/Button";
import { buttonVariants, modalTypes, queryKeys, roles } from "../../../consts";
import PhoneCodeDropdown from "../../core/formElements/PhoneCodeDropdown";
import Input from "../../core/formElements/Input";
import { ModalContent } from "../shared";
import { closeModal, openModal } from "../../../store/modal/actions";
import { modalSelector } from "../../../store/selectors/modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createEquityInvestmentRequest,
  updateRoleProfile,
} from "../../../utils/api/mutations";
import { getCountryAndPhoneCode } from "../../../services/country-service";

const InvestingInterestModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate: updateInvestor } = useMutation(updateRoleProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.investor]);
      dispatch(closeModal());
    },
  });

  const { mutate: createRequest } = useMutation(createEquityInvestmentRequest, {
    onSuccess: () =>
      dispatch(openModal({ type: modalTypes.applicationSubmitted })),
    onError: () => {
      dispatch(closeModal());
    },
  });

  const { investorData, projectId } = useSelector(modalSelector);

  const {
    fullName,
    phoneCode: currentPhoneCode,
    phoneNumber: currentPhoneNumber,
  } = investorData.contactInfo;

  const [isChangeNumberMode, setIsChangeNumberMode] = useState(false);
  const [phoneCode, setPhoneCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const fullPhoneNumber = `${currentPhoneCode.value}${currentPhoneNumber}`;

  const title = (
    <>
      Hi {fullName}! <br />
      We are happy for your <br />
      interest in investing
    </>
  );

  const handleContactMe = () => {
    if (isChangeNumberMode) {
      updateInvestor({
        role: roles.investor.value,
        roleData: {
          "roleData.contactInfo.phoneCode": phoneCode,
          "roleData.contactInfo.phoneNumber": phoneNumber,
        },
      });
    }

    createRequest({ projectId });
  };

  const handleChangePhoneNumber = async () => {
    setIsChangeNumberMode(true);
    const { code, phoneCode } = await getCountryAndPhoneCode();
    setPhoneCode({
      label: `+${phoneCode}`,
      value: `+${phoneCode}`,
      meta: {
        code,
      },
    });
  };

  const renderMessage = () => {
    if (isChangeNumberMode) {
      return <div>Enter the phone number you want us to contact you</div>;
    }

    return (
      <>
        We will get back to you within 24 hours to the phone number:
        <b>{` ${fullPhoneNumber} `}</b>
        in order to give you all the necessary information and to continue the
        investment process securely.
      </>
    );
  };

  return (
    <StyledModalContent>
      <Title>{title}</Title>
      <Message>{renderMessage()}</Message>

      {isChangeNumberMode && (
        <PhoneDropdownWrapper>
          <StyledPhoneCodeDropdown value={phoneCode} onChange={setPhoneCode} />

          <Input
            isInteger
            value={phoneNumber}
            onChange={setPhoneNumber}
            placeholder="Enter Number"
            isNumeric
          />
        </PhoneDropdownWrapper>
      )}

      <ButtonsWrapper>
        <Button
          variant={buttonVariants.primary}
          onClick={handleContactMe}
          disabled={isChangeNumberMode && (!phoneNumber || !phoneCode)}
        >
          Contact Me
        </Button>
        {!isChangeNumberMode && (
          <Button
            variant={buttonVariants.bordered}
            color="#031854"
            borderRadius="10px"
            onClick={handleChangePhoneNumber}
          >
            Change phone number
          </Button>
        )}
      </ButtonsWrapper>
    </StyledModalContent>
  );
};

const StyledModalContent = styled(ModalContent)`
  height: 560px;

  @media (max-width: 768px) {
    height: 503px;
  }
`;

const Title = styled.div`
  color: #09216a;
  font-weight: 600;
  font-size: 23px;
  line-height: 23px;

  margin: 109px 80px 27px 80px;

  @media (max-width: 768px) {
    margin: 81px 30px 17px 30px;
  }
`;

const Message = styled.div`
  color: #09216a;
  font-weight: 300;

  margin: 0 auto 47px auto;
  max-width: 398px;
  font-size: 18px;
  line-height: 22px;

  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 19px;
    margin: 0 auto 28px auto;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledPhoneCodeDropdown = styled(PhoneCodeDropdown)`
  width: 128px;
  flex-shrink: 0;
  margin-bottom: 40px;
`;

const PhoneDropdownWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default InvestingInterestModal;
