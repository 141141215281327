import React from "react";
import styled from "styled-components/macro";
import Button from "../../Components/core/Button";
import { buttonVariants, signUpCompanyBasicSteps } from "../../consts";
import { useNavigate } from "react-router-dom";
import { setCompanyCompletedSteps } from "../../store/companySignUp/actions";
import { useDispatch } from "react-redux";

const AddNewCompany = ({ className, isAddCompanyEnabled }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddCompany = () => {
    dispatch(setCompanyCompletedSteps([signUpCompanyBasicSteps.contactInfo]));

    navigate("/sign-up/company/company-type", {
      state: { notInitialSignup: true },
    });
  };
  return (
    <Container className={className}>
      <Image src="/img/add-new-company.svg" />

      <Title>Add a new company easily</Title>

      <Description>
        Need to add a new company? With one click you can go to the registration
        form to add another company easily.
      </Description>

      <StyledButton
        disabled={!isAddCompanyEnabled}
        variant={buttonVariants.primary}
        onClick={handleAddCompany}
      >
        Add Company
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 370px;

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const Image = styled.img`
  width: 39px;
  height: 37px;

  @media (max-width: 768px) {
    width: 32px;
    height: 30px;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #000f3c;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000f3b;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const StyledButton = styled(Button)`
  height: 56px;
  width: 178px;

  @media (max-width: 768px) {
    height: 45px;
    width: 150px;
  }
`;

export default AddNewCompany;
