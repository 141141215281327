import React, { useEffect } from "react";
import HomePage from "./HomePage";
import { useDispatch } from "react-redux";
import { AuthService } from "../services/auth.service";
import { openModal } from "../store/modal/actions";
import { modalTypes } from "../consts";

const Temp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const mode = urlSearchParams.get("mode");
    const actionCode = urlSearchParams.get("oobCode");
    const continueUrl = urlSearchParams.get("continueUrl");
    const lang = urlSearchParams.get("lang") || "en";

    switch (mode) {
      case "verifyEmail":
        handleVerifyEmail(actionCode, continueUrl, lang);
        break;
      case "resetPassword":
        handleResetPassword(actionCode, continueUrl, lang);
        break;

      default:
        return;
    }
  }, []);

  function handleResetPassword(actionCode, continueUrl, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    AuthService.verifyPasswordResetCode(actionCode)
      .then((email) => {
        dispatch(
          openModal({
            type: modalTypes.resetPasswordModal,
            oobCode: actionCode,
          })
        );
      })
      .catch((error) => {
        handleError();
      });
  }

  function handleVerifyEmail(actionCode, continueUrl, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    AuthService.applyActionCode(actionCode)
      .then((resp) => {
        dispatch(openModal({ type: modalTypes.initialProcessCompletedModal }));
      })
      .catch((error) => {
        handleError();
      });
  }

  const handleError = () => {
    dispatch(
      openModal({
        type: modalTypes.errorModal,
        description: "The link is wrong or expired. Please try again!",
      })
    );
  };

  return <HomePage />;
};

export default Temp;
