import React from "react";
import styled from "styled-components/macro";

const PageSearch = ({ className, placeholder, onChange, value }) => (
  <Container className={className}>
    <Icon src="/img/search.svg" />

    <StyledInput
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(160, 184, 255, 0.5);
  border-radius: 8px;
  filter: drop-shadow(0px 0px 10px rgba(160, 184, 255, 0.5));
  height: 56px;
  padding: 0 20px;

  @media (max-width: 1280px) {
    padding: 0 14px;
  }

  @media (max-width: 768px) {
    padding: 0 9px;
    height: 46px;
  }
`;

const StyledInput = styled.input`
  font-weight: 300;
  outline: none;
  border: none;
  padding: 0;
  color: #071b56;
  font-size: 21px;
  width: 100%;

  &::placeholder {
    color: #a8b9e4;
  }

  @media (max-width: 1280px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 23px;
  margin-right: 13px;

  @media (max-width: 768px) {
    height: 17px;
  }
`;

export default PageSearch;
