import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as Icon } from "../../../assets/v-icon.svg";
import FormElementWrapper from "./FormElementWrapper";

const Checkbox = ({
  className,
  value,
  onChange,
  label,
  onBlur,
  errorMessage,
}) => (
  <FormElementWrapper
    className={className}
    onBlur={onBlur}
    errorMessage={errorMessage}
  >
    <Container onClick={() => onChange && onChange(!value)}>
      <Content checked={value}>
        {value && <StyledIcon src="src/assets/v-icon.svg" />}

        <Input type="checkbox" checked={value} onChange={() => {}} />
      </Content>

      {label && <Label>{label}</Label>}
    </Container>
  </FormElementWrapper>
);

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  color: #071b56;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
`;

const Label = styled.span`
  cursor: pointer;
`;

const Content = styled.div`
  width: 20px;
  height: 22px;
  border: 1px solid #071b56;
  border-radius: 3px;
  display: flex;
  margin-right: 9px;
  cursor: pointer;
  user-select: none;
`;

const StyledIcon = styled(Icon)`
  width: 8px;
  height: 6px;
  margin: auto;
  fill: #071b56;
`;

const Input = styled.input`
  height: 0;
  width: 0;
  display: none;
`;

export default Checkbox;
