import React, { useState } from "react";
import styled from "styled-components/macro";
import Collapsible from "react-collapsible";
import { FieldValue } from "./shared";
import { capitalize } from "lodash";
import moment from "moment";
import {
  getProjectOpenStatus,
  getTimeRemainingString,
} from "../../utils/projectUtils";
import {
  normalizeDollarAmount,
  normalizeNumber,
} from "../../utils/normalizations";

const CollapsibleProjectInfo = ({ className, project, numberOfInvestors }) => {
  const [isOpen, setIsOpen] = useState();

  if (!project) {
    return null;
  }

  const { totalRaising, totalRaised, endTime } = project;

  return (
    <Collapsible
      handleTriggerClick={() => setIsOpen(!isOpen)}
      trigger={
        <Container className={className}>
          <TitleWrapper>
            <Circle />
            <Title>Project Info</Title>
          </TitleWrapper>

          <TextButton>{isOpen ? "Close" : "See Here"}</TextButton>
        </Container>
      }
      transitionTime={300}
      open={isOpen}
    >
      <Content>
        <FieldValue
          field="Project's status"
          value={capitalize(getProjectOpenStatus(project))}
          isVertical
        />
        <FieldValue
          field="Total raising"
          value={normalizeDollarAmount(totalRaising)}
          isVertical
        />

        <FieldValue
          field="Number of investments"
          value={normalizeNumber(numberOfInvestors)}
          isVertical
        />

        <FieldValue
          field="Total amount raised"
          value={normalizeDollarAmount(totalRaised)}
          isVertical
        />

        <FieldValue
          field="Total amount left to be raised"
          value={normalizeDollarAmount(totalRaising - totalRaised)}
          isVertical
        />

        <FieldValue
          field="Remaining time"
          value={getTimeRemainingString(moment(endTime))}
          isVertical
        />
      </Content>
    </Collapsible>
  );
};

const Container = styled.div`
  height: 49px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: #000f3b;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
`;

const Circle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ff2853;
  margin-right: 7px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 600;
`;

const TextButton = styled.div`
  cursor: pointer;
`;

const Content = styled.div`
  padding: 10px 35px 20px 35px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default CollapsibleProjectInfo;
