import "react-datepicker/dist/react-datepicker.css";
import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import LibraryDatePicker from "react-datepicker";
import moment from "moment";
import Input from "./Input";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import FormElementWrapper from "./FormElementWrapper";

const DatePicker = ({
  className,
  value,
  onChange,
  onBlur,
  placeholder,
  errorMessage,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <FormElementWrapper
      className={className}
      onBlur={onBlur}
      errorMessage={errorMessage}
    >
      <Container ref={ref} onBlur={onBlur}>
        {isOpen ? (
          <LibraryDatePicker
            dateFormat="dd.MM.yyyy"
            selected={value}
            onChange={(date) => {
              setIsOpen(false);
              onChange(date);
            }}
            inline
            {...rest}
          />
        ) : (
          <Input
            value={value ? moment(value).format("DD.MM.yyyy") : null}
            InnerComponent={() => <Icon src="/img/calendar.svg" />}
            onClick={() => setIsOpen(true)}
            placeholder={placeholder}
            onChange={() => {}}
          />
        )}
      </Container>
    </FormElementWrapper>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;

  .react-datepicker {
    background: #f1f4ff;
    border: 1px solid #8e9fcb;
    border-radius: 5px;
    font-family: inherit;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .react-datepicker__month-container {
    padding: 10px 20px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }
  .react-datepicker__current-month {
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #071b56;
  }
  .react-datepicker__day-name {
    color: #071b56;
    font-size: 15px;
    font-weight: 600;
  }
  .react-datepicker__navigation {
    top: 20px;
  }
  .react-datepicker__navigation--previous {
    left: 7px;
  }
  .react-datepicker__navigation-icon--next {
    left: -7px;
  }
  .react-datepicker__day {
    color: #071b56;
    font-size: 14px;
    font-weight: 300;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    border: 1px solid #ff2853;
    border-radius: 3px;
  }
  .react-datepicker__day:hover {
    background-color: transparent;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #071b56;
  }
  .react-datepicker__day--disabled {
    opacity: 0.5;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

export default DatePicker;
