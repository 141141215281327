import React from "react";
import styled, { css } from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
  selectedCompanyTypeSelector,
} from "../../../../store/selectors/signUp";
import { useFieldArray, useForm } from "react-hook-form";
import Button from "../../../../Components/core/Button";
import {
  buttonVariants,
  fundingTypes,
  signUpCompanyTokenSteps,
} from "../../../../consts";
import {
  setCompanyCompletedSteps,
  setFoundersInfo,
} from "../../../../store/companySignUp/actions";
import { ButtonsWrapper, Container, Description, Title } from "../styled";
import Founder from "./Founder";
import { toLower } from "lodash-es";

const founderInitData = {
  name: "",
  role: "",
  background: "",
  linkedIn: "",
};

const FoundersInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { foundersInfo } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);
  const companyType = useSelector(selectedCompanyTypeSelector);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      founders: isEmpty(foundersInfo) ? [{ ...founderInitData }] : foundersInfo,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "founders",
  });

  const onSubmit = (data) => {
    dispatch(setFoundersInfo(data.founders));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyTokenSteps.foundersInfo,
      ])
    );

    if (toLower(companyType.value) === fundingTypes.token) {
      navigate("/sign-up/company/token-company-info");
    } else {
      navigate("/sign-up/company/equity-company-info");
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Founders info</Title>

      <StyledDescription>Enter your founders info below</StyledDescription>

      <Founders>
        {fields.map((field, index) => (
          <Founder
            key={field.id}
            index={index}
            control={control}
            remove={remove}
          />
        ))}
      </Founders>

      <AddButton
        isSingleFounder={fields?.length === 1}
        onClick={() => append({ ...founderInitData })}
      >
        <PlusIcon src="/img/red-plus.svg" />
        <Text>Add a founder</Text>
      </AddButton>

      <ButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        <Button
          variant={buttonVariants.secondary}
          onClick={() => navigate("/sign-up/company/company-type")}
        >
          Back
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

const StyledDescription = styled(Description)`
  margin-bottom: 60px;
`;

const Founders = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 30px;

  ${({ isSingleFounder }) =>
    isSingleFounder &&
    css`
      margin-top: 15px;
    `}
`;

const PlusIcon = styled.img`
  width: 19px;
  height: 19px;
  margin-right: 4px;
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  align-self: flex-end;
`;

export default FoundersInfo;
