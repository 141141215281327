import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../../assets/config.json";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
} from "../../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/core/Button";
import { buttonVariants, signUpCompanyEquitySteps } from "../../../../consts";
import {
  setCompanyCompletedSteps,
  setRaisingInfo,
} from "../../../../store/companySignUp/actions";
import { getFieldError } from "../../../../utils/formUtils";
import {
  ButtonsWrapper,
  Container,
  Description,
  InputsWrapper,
  Title,
} from "../styled";
import Input from "../../../../Components/core/formElements/Input";
import FileUpload from "../../../../Components/core/fileUpload/FileUpload";
import InfoIcon from "../../../../Components/core/icons/InfoIcon";
import DatePicker from "../../../../Components/core/formElements/DatePicker";
import Dropdown from "../../../../Components/core/formElements/dropdown/Dropdown";

const RaisingInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { raisingInfo, companyType } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);

  const { handleSubmit, control } = useForm({
    defaultValues: raisingInfo,
  });

  const isEquity = companyType?.value === "Equity";

  const onSubmit = (data) => {
    dispatch(setRaisingInfo(data));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyEquitySteps.raisingInfo,
      ])
    );

    navigate("/sign-up/company/partners-and-clients");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Raising info</Title>

      <Description>Enter Raising info below</Description>

      <Content>
        <InputsWrapper>
          <Controller
            name="currentRound"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Current round"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isNumeric
                InnerComponent={() => (
                  <InfoIcon info="Total amount in USD to raise in price value" />
                )}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Round",
                })}
              />
            )}
          />

          <Controller
            name="additionalPlatforms"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Additional platforms"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                InnerComponent={() => (
                  <InfoIcon info="Name of the platform raising funds" />
                )}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Additional info",
                })}
              />
            )}
          />

          <Controller
            name="dateOfOpening"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <DatePicker
                placeholder="Date of opening"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Date",
                })}
                minDate={new Date()}
              />
            )}
          />

          <Controller
            name="timeframe"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Timeframe (in days)"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Timeframe",
                })}
              />
            )}
          />

          <Controller
            name="moneyLastDuration"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Dropdown
                placeholder="For how long will the money last?"
                options={config.shared.moneyLastDuration}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Duration",
                })}
              />
            )}
          />
        </InputsWrapper>

        <FileUploadsWrapper>
          <Controller
            name="vestingTerms"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FileUpload
                title="Vesting terms (optional)"
                subtitle="Upload vesting terms"
                value={value}
                onChange={onChange}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "File",
                })}
              />
            )}
          />

          <Controller
            name="pitchshowVestingTerms"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FileUpload
                title="Pitchshow vesting terms"
                subtitle="Upload pitchshow vesting terms"
                value={value}
                onChange={onChange}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "File",
                })}
              />
            )}
          />
        </FileUploadsWrapper>
      </Content>

      <ButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        <Button
          variant={buttonVariants.secondary}
          onClick={() => navigate("/sign-up/company/shares-info")}
        >
          Back
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

const Content = styled.div``;

const FileUploadsWrapper = styled.div`
  margin: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

export default RaisingInfo;
