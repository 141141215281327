import React from "react";
import {
  BackToSignIn,
  ModalContent,
  ModalText,
  ModalTitle,
  SubmitButton,
} from "./shared";
import styled from "styled-components/macro";
import { Controller, useForm } from "react-hook-form";
import ModalInput from "../../../core/formElements/ModalInput";
import { buttonVariants, emailRegex, modalTypes } from "../../../../consts";
import { openModal } from "../../../../store/modal/actions";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { sendPasswordResetEmail } from "../../../../utils/api/mutations";

const ForgotPasswordModal = () => {
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation({
    mutationFn: sendPasswordResetEmail,
    onSettled: () =>
      dispatch(openModal({ type: modalTypes.forgotPasswordSubmittedModal })),
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const handleSignIn = () => {
    dispatch(openModal({ type: modalTypes.signInModal }));
  };
  const onSubmit = ({ email }) => {
    mutate({ email });
  };

  return (
    <ModalContent>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledModalTitle>Forgot Password?</StyledModalTitle>

        <Description>Don't worry, it happens to the best of us.</Description>

        <Controller
          name="email"
          control={control}
          rules={{ required: true, pattern: emailRegex }}
          render={({ field: { value, onChange } }) => (
            <ModalInput
              value={value}
              onChange={onChange}
              label="Email address"
            />
          )}
        />

        <StyledSubmitButton
          variant={buttonVariants.primary}
          type="submit"
          disabled={!isValid || isLoading}
        >
          Send Reset Link
        </StyledSubmitButton>

        <BackToSignIn onClick={handleSignIn}>Back to Sign in</BackToSignIn>
      </Form>
    </ModalContent>
  );
};

const Form = styled.form``;

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 15px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Description = styled(ModalText)`
  margin-bottom: 50px;
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 40px;
  margin-bottom: 25px;
`;

export default ForgotPasswordModal;
