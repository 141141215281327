import React from "react";
import { useSelector } from "react-redux";
import { map } from "lodash";
import OpenProject from "./OpenProject";
import { ProjectsWrapper, SectionTitle, ShowMoreButton } from "./shared";
import { openFilteredProjectsSelector } from "../../store/selectors/projects";
import { useClientPagination } from "../../hooks/useClientPagination";
import { useWindowSize } from "../../hooks/useWindowSize";
import { fundingTypes, screenSizes } from "../../consts";
import { toLower } from "lodash-es";

const OpenProjects = () => {
  const { width } = useWindowSize();
  const openProjects = useSelector(openFilteredProjectsSelector);

  const { slicedData, viewNextPage, isHasMore } = useClientPagination({
    pageSize: width > screenSizes.xlarge ? 10 : 8,
    allData: openProjects,
  });

  if (!slicedData?.length) {
    return null;
  }

  return (
    <>
      <SectionTitle>Open Projects</SectionTitle>

      <ProjectsWrapper>
        {map(
          slicedData,
          ({
            logo,
            projectName,
            totalRaising,
            totalRaised,
            id,
            fundingType,
            tokenAmount,
            availableAmount,
            maxAllocation,
          }) => {
            let fillPercent;

            if (toLower(fundingType) === fundingTypes.token) {
              fillPercent = Math.round(
                ((parseInt(tokenAmount) - parseInt(availableAmount)) /
                  parseInt(tokenAmount)) *
                  100
              );
            } else {
              fillPercent = parseInt((totalRaised / totalRaising) * 100);
            }

            return (
              <OpenProject
                maxAllocation={maxAllocation}
                key={projectName}
                logo={logo}
                name={projectName}
                totalRaised={totalRaised}
                projectId={id}
                fillPercent={fillPercent}
              />
            );
          }
        )}
      </ProjectsWrapper>

      {isHasMore && (
        <ShowMoreButton onClick={viewNextPage}>Show More</ShowMoreButton>
      )}
    </>
  );
};

export default OpenProjects;
