import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../consts";
import { DALServiceV2 } from "../../../services/data-access-layer.service";
import { forEach } from "lodash-es";

export const useFilledRoles = (queryOptions = {}) =>
  useQuery([queryKeys.filledRoles], {
    queryFn: DALServiceV2.getRoleProfiles,
    select: (response) => {
      const result = {};

      forEach(response, ({ id, roleData }) => {
        result[id] = roleData;
      });

      return result;
    },
    ...queryOptions,
  });
