import React, { useState } from "react";
import styled from "styled-components/macro";
import { ModalContent } from "../shared";
import Slider from "../../core/slider/Slider";
import CurrencyInput from "react-currency-input-field";
import Button from "../../core/Button";
import {
  buttonVariants,
  modalTypes,
  paymentIntents,
  paymentMethods,
  requestStatus,
} from "../../../consts";
import { closeModal, openModal } from "../../../store/modal/actions";
import { useDispatch } from "react-redux";
import { makePayment } from "../../../utils/api/mutations";
import { useMutation } from "@tanstack/react-query";
import EquityInvestingStatusModal from "../EquityInvestingStatusModal";

const EquityInvestingModal = ({
  minAmount,
  maxAmount,
  investorData,
  projectId,
}) => {
  const dispatch = useDispatch();
  const { mutate, isLoading } = useMutation({
    mutationFn: makePayment,
    onSuccess: (response) => {
      if (response?.data?.redirectUrl) {
        window.location = response.data.redirectUrl;
      }
    },
  });

  const [amount, setAmount] = useState(minAmount);
  const handleValueChange = (value) => {
    let updatedValue = Number(value);

    if (!updatedValue) {
      updatedValue = 0;
    } else if (updatedValue > maxAmount) {
      updatedValue = maxAmount;
    }

    setAmount(updatedValue);
  };

  const handleInputBlur = () => {
    if (amount < minAmount) {
      setAmount(minAmount);
    }
  };

  const handleBankWireClick = () => {
    dispatch(
      openModal({
        type: modalTypes.investingInterest,
        investorData,
        projectId,
      })
    );
  };

  const handleCardClick = () => {
    if (!amount) {
      return;
    }

    dispatch(
      openModal({
        type: modalTypes.stripePaymentModal,
        amount,
        projectId,
      })
    );
  };

  const handleCryptoClick = async () => {
    if (!amount) {
      return;
    }

    mutate({
      paymentMethod: paymentMethods.crypto,
      projectId,
      amount,
      intent: paymentIntents.projectInvestment,
      successUrl: `${window.location.href}/${requestStatus.success}`,
      errorUrl: `${window.location.href}/${requestStatus.error}`,
    });
  };

  if (isLoading) {
    return <EquityInvestingStatusModal />;
  }

  return (
    <Container>
      <Title>Amount</Title>

      <Slider
        min={minAmount}
        max={maxAmount}
        value={amount}
        onChange={setAmount}
      />

      <Amount>
        <Label>Investment amount ($):</Label>

        <Input
          value={amount}
          onBlur={handleInputBlur}
          onValueChange={handleValueChange}
          prefix={"$"}
        />
      </Amount>

      <InvestmentTitle>Investment method</InvestmentTitle>

      <InvestmentMethods>
        <Method onClick={handleBankWireClick}>BANK WIRE</Method>
        <Method onClick={handleCardClick}>CREDIT CARD</Method>
        <Method onClick={handleCryptoClick}>CRYPTO</Method>
      </InvestmentMethods>

      <Button
        variant={buttonVariants.primary}
        onClick={() => dispatch(closeModal())}
      >
        Close
      </Button>
    </Container>
  );
};

const Container = styled(ModalContent)`
  padding: 60px 30px 45px 30px;
  background-color: white;
`;

const Title = styled.div`
  font-size: 23px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
  margin-bottom: 80px;
`;

const InvestmentTitle = styled(Title)`
  margin-bottom: 0;
`;

const Amount = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 60px;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: 600;
  flex-shrink: 0;
`;

const Input = styled(CurrencyInput)`
  height: 61px;
  text-align: center;
  width: 100%;
  background: #f1f4ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
`;

const InvestmentMethods = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 15px;
  margin-bottom: 40px;
`;

const Method = styled.div`
  height: 50px;
  width: 100%;
  box-shadow: 0 0 30px 12px rgba(93, 130, 241, 0.15);
  border-radius: 3px;
  background: #ffffff;
  color: #000f3c;
  font-weight: 600;
  font-size: 15px;
  transition: background 0.3s ease-in, color 0.3s ease-in;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    background: #000f3c;
    color: #ffffff;
  }
`;

export default EquityInvestingModal;
