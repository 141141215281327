import React from "react";
import styled from "styled-components/macro";

const PasswordExplanation = () => (
  <Container>
    Your password must contains at least 8 characters and contain 1 uppercase, 1
    lowercase, and 1 number
  </Container>
);

const Container = styled.div`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #505050;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

export default PasswordExplanation;
