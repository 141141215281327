import { createSelector } from "reselect";
import { omit } from "lodash-es";

export const modalSelector = (state) => state.modal;

export const modalTypeSelector = createSelector(
  [modalSelector],
  (modal) => modal.type
);

export const modalPropsSelector = createSelector([modalSelector], (modal) =>
  omit(modal, "type")
);
