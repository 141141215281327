import React from "react";
import { ModalContent, ModalText, ModalTitle } from "./shared";
import styled from "styled-components/macro";

const SignUpSubmittedModal = ({ email }) => (
  <StyledModalContent>
    <Animation src="/img/email-sent.gif" />

    <StyledModalTitle>
      In these moments an email will be sent to your email inbox {email}
    </StyledModalTitle>

    <Description>
      Please click on the link in the email to be sure it’s really you!
    </Description>
  </StyledModalContent>
);

const StyledModalContent = styled(ModalContent)`
  padding-top: 40px;
`;

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 15px;
  margin-top: 20px;
  text-align: center;
  word-break: break-word;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Animation = styled.img`
  width: 100px;
  height: 100px;
  margin: 0 auto;
`;

const Description = styled(ModalText)`
  margin-bottom: 50px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
export default SignUpSubmittedModal;
