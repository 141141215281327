import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { camelCase, last, map } from "lodash";
import styled from "styled-components/macro";
import Navbar from "../../../Components/Section/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import VerticalStepsLine from "../../../Components/VerticalStepsLine";
import {
  companySignUpCompletedStepsSelector,
  projectSignUpDataByCompanyTypeSelector,
  selectedCompanyTypeSelector,
} from "../../../store/selectors/signUp";
import {
  roles,
  signUpCompanyBasicSteps,
  signUpCompanyEquitySteps,
  signUpCompanyTokenSteps,
} from "../../../consts";
import DataSubmitted from "../../../Components/DataSubmitted";
import { resetCompanySignup } from "../../../store/companySignUp/actions";
import HorizontalProgressLine from "../../../Components/core/HorizontalProgressLine";
import { useRerouteToFormStart } from "../../../hooks/useRerouteToFormStart";
import {
  createCompanyProject,
  createConsultantProject,
} from "../../../utils/api/mutations";
import { useUserDBData } from "../../../utils/api/queries/useUserDBData";
import { toLower } from "lodash-es";

const Company = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();

  useRerouteToFormStart({
    noReroute: state?.notInitialSignup,
    formStartUrl: "/sign-up/company/contact-info",
    completedStepsSelector: companySignUpCompletedStepsSelector,
  });

  const { data } = useUserDBData();
  const role = data?.role;

  const urlStep = last(pathname.split("/"));

  const companyType = useSelector(selectedCompanyTypeSelector);
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);
  const projectSignUpData = useSelector(projectSignUpDataByCompanyTypeSelector);

  const isFormSubmitted = completedSteps?.includes(
    signUpCompanyTokenSteps.dataRoom
  );

  useEffect(() => {
    return () => {
      dispatch(resetCompanySignup());
    };
  }, []);

  const formSteps = useMemo(() => {
    switch (toLower(companyType?.value)) {
      case "token":
        return signUpCompanyTokenSteps;

      case "equity":
        return signUpCompanyEquitySteps;

      default:
        return signUpCompanyBasicSteps;
    }
  }, [companyType?.value]);

  const formStepsArray = useMemo(
    () => map(formSteps, (value) => value),
    [formSteps]
  );

  const currentStep = formSteps[camelCase(urlStep)];
  const formFillPercent =
    ((completedSteps.length + 1) / formStepsArray.length) * 100;

  if (isFormSubmitted) {
    return (
      <DataSubmitted
        mutation={
          role === roles.company.value
            ? createCompanyProject
            : createConsultantProject
        }
        requestData={{
          data: projectSignUpData,
        }}
      />
    );
  }

  return (
    <Container>
      <Navbar status={"submit"} />

      <LeftPanel>
        <TextWrapper>
          <Title>Onboarding</Title>

          <Description>Please complete the following steps</Description>

          <VerticalStepsLine
            steps={formStepsArray}
            completedSteps={completedSteps}
            currentStep={currentStep}
          />
        </TextWrapper>
      </LeftPanel>

      <FormSteps>
        <StyledHorizontalProgressLine fillPercent={formFillPercent} />

        <FormStepsWrapper>
          <Outlet />
        </FormStepsWrapper>
      </FormSteps>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
`;

const LeftPanel = styled.div`
  display: flex;
  align-items: center;
  background-color: #203471;
  flex: 1;
  padding: 170px 20px 106px 20px;
  flex-direction: column;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: white;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: white;
  margin-bottom: 36px;
`;

const FormSteps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 228px 20px 0 20px;
  background: linear-gradient(
    115.04deg,
    #e5ebff 27.52%,
    rgba(242, 245, 255, 0) 96.92%
  );
  flex: 2;

  @media (max-width: 768px) {
    padding-top: 130px;
  }
`;

const StyledHorizontalProgressLine = styled(HorizontalProgressLine)`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const FormStepsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default Company;
