import React, { useEffect, useRef, useState } from "react";
import { ModalContent, ModalText, ModalTitle, SubmitButton } from "./shared";
import styled from "styled-components/macro";
import { buttonVariants, modalTypes } from "../../../../consts";
import { Controller, useForm } from "react-hook-form";
import PhoneCodeDropdown from "../../../core/formElements/PhoneCodeDropdown";
import Input from "../../../core/formElements/Input";
import { useMobile } from "../../../../hooks/useMobile";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../store/modal/actions";
import { useDefaultCountryCode } from "../../../../hooks/useDefaultCountryCode";
import { registerPhone } from "../../../../utils/authUtils";
import { useMutation } from "@tanstack/react-query";
import { nanoid } from "@reduxjs/toolkit";

const EnterPhoneNumberModal = ({ email, password }) => {
  const dispatch = useDispatch();
  const { isMobile } = useMobile();
  const recaptchaRef = useRef();
  const { data: phoneCode } = useDefaultCountryCode();
  const [recaptchaKey, setRecaptchaKey] = useState(nanoid());

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const { mutate } = useMutation({
    mutationFn: ({ fullNumber }) =>
      registerPhone({
        email,
        password,
        phoneNumber: fullNumber,
        recaptchaRef,
        onSMSSent: openPhoneCodeModal,
        changeRecaptchaKey,
      }),
  });

  useEffect(() => {
    if (phoneCode) {
      setValue("phoneCode", phoneCode);
    }
  }, [phoneCode]);

  const changeRecaptchaKey = () => {
    setRecaptchaKey(nanoid());
  };

  const openPhoneCodeModal = ({ verificationId, phoneNumber }) => {
    dispatch(
      openModal({
        type: modalTypes.phoneCodeModal,
        verificationId,
        phoneNumber,
        isFirstPhoneVerification: true,
      })
    );
  };

  const handleKeyDown = (e) => {
    // Enter button click
    if (e.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async ({ phoneCode, phoneNumber }) => {
    const fullNumber = `${phoneCode.value}${phoneNumber}`;

    mutate({ fullNumber });
  };

  return (
    <StyledModalContent>
      <div ref={recaptchaRef} key={recaptchaKey} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledModalTitle>
          Congratulations! <br /> Just one more thing and we're done
        </StyledModalTitle>

        <Description>Please enter your phone number:</Description>

        <PhoneNumberWrapper>
          <Controller
            name="phoneCode"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => {
              return (
                <StyledPhoneCodeDropdown
                  value={value}
                  onChange={onChange}
                  backgroundColor="white"
                  minHeight={isMobile ? "46px" : "64px"}
                />
              );
            }}
          />

          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <StyledInput
                value={value}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                isNumeric
                isInteger
              />
            )}
          />
        </PhoneNumberWrapper>

        <SubmitButton
          variant={buttonVariants.primary}
          type="submit"
          disabled={!isValid}
        >
          Next
        </SubmitButton>
      </Form>
    </StyledModalContent>
  );
};

const StyledModalContent = styled(ModalContent)`
  overflow: initial;
`;

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Description = styled(ModalText)`
  margin-bottom: 10px;
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const StyledInput = styled(Input)`
  .input-container {
    background: white;
    height: 64px;
    color: black;

    @media (max-width: 768px) {
      height: 46px;
    }
  }
`;

const StyledPhoneCodeDropdown = styled(PhoneCodeDropdown)`
  .content {
    width: 125px;
  }
`;

const Form = styled.form``;
export default EnterPhoneNumberModal;
