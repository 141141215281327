import { doc, onSnapshot, where } from "firebase/firestore";
import { useEffect } from "react";
import db from "../utils/db";
import { projectsListSelector } from "../store/selectors/projects";
import { useOnChainProjects } from "../utils/api/queries/useOnChainProjects";
import { toLower } from "lodash-es";
import { fundingTypes } from "../consts";
import {
  normalizeEquityProject,
  normalizeTokenProject,
} from "../utils/normalizations";
import { useSelector, useDispatch } from "react-redux";
import { updateProject } from "../store/projects/actions";

export const useProjectListener = ({ userId, projectId, role }) => {
  const dispatch = useDispatch();

  const projects = useSelector(projectsListSelector);
  const project = projects?.[projectId];
  const { data: onChainProjects } = useOnChainProjects();

  useEffect(() => {
    if (!userId || !projectId) {
      return;
    }

    const unsub = onSnapshot(
      doc(db, `/users/${userId}/roles/${role}/projects_listed/${projectId}`),
      where("verified", "==", true),
      (doc) => {
        let normalizedProject;
        const updatedProject = doc.data();

        if (
          toLower(project?.projectData?.companyType?.value) ===
          fundingTypes.token
        ) {
          normalizedProject = normalizeTokenProject(
            updatedProject,
            onChainProjects
          );
        } else {
          normalizedProject = normalizeEquityProject(updatedProject);
        }

        dispatch(
          updateProject({
            projectId,
            project: { ...normalizedProject, id: projectId },
          })
        );
      }
    );

    return () => {
      unsub();
    };
  }, [userId, projectId]);
};
