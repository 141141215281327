import React from "react";
import { Controller } from "react-hook-form";
import Input from "../../../Components/core/formElements/Input";
import { getFieldError } from "../../../utils/formUtils";
import { InputsWrapper, SectionTitle } from "../company/styled";

const MultipleInputs = ({
  className,
  title,
  control,
  amount,
  formFieldName,
  inputPlaceholder,
  errorFieldLabel = "Name",
}) => (
  <InputsWrapper className={className}>
    <SectionTitle>{title}</SectionTitle>

    {new Array(amount).fill(null).map((value, index) => (
      <Controller
        key={index}
        name={`${formFieldName}.${index}`}
        control={control}
        rules={{ required: !index }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error },
        }) => {
          return (
            <Input
              placeholder={inputPlaceholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={getFieldError({
                error,
                fieldLabel: errorFieldLabel,
              })}
            />
          );
        }}
      />
    ))}
  </InputsWrapper>
);

export default MultipleInputs;
