import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { upcomingProSlider } from "../../config/settings";
import { normalizeDollarAmount } from "../../utils/normalizations";
import { filter } from "lodash-es";
import { getProjectOpenStatus } from "../../utils/projectUtils";
import { projectOpenStatuses } from "../../consts";

const UpcomingProjects = () => {
  const { projects } = useSelector((state) => state);
  const { projectsList } = projects;

  let upcomingProjects = filter(
    projectsList,
    (project) => getProjectOpenStatus(project) === projectOpenStatuses.upcoming
  );

  return (
    <div className="upcoming-projects">
      <div className="d-flex justify-content-center aling-items-center">
        <h2 className="main-title">Upcoming projects</h2>
      </div>
      <div className="container">
        <div className="new-project-items all">
          <Slider {...upcomingProSlider}>
            {upcomingProjects.length > 0 &&
              upcomingProjects.slice(0, 4).map((item, index) => {
                const {
                  startTime,
                  project_id,
                  logo,
                  id,
                  projectName,
                  totalRaising,
                } = item;
                const days = moment(startTime).diff(Date.now(), "days");
                return (
                  <div key={index} className="px-2 mt-md-5">
                    <div className="up-project">
                      <Link to={`company-page/${id}`}>
                        <div className="card">
                          <div className="up-p-card-header">
                            <img src={logo} alt="" />
                            <h4> {projectName}</h4>
                            <p className="count">
                              In <span>{days}</span> Days
                            </p>
                          </div>
                          <div className="up-p-card-body">
                            <p>
                              <div className="text-center">Total Raising:</div>
                              <div className="text-center">
                                <span className="text-bold">
                                  {normalizeDollarAmount(totalRaising)}
                                </span>
                              </div>
                            </p>
                            <p>
                              <div className="text-center">Max allocation:</div>
                              <div className="text-center">
                                <span className="text-bold">TBA</span>
                              </div>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </Slider>
          {!upcomingProjects.length && (
            <div>
              <h5 className="text-capitalize text-center py-5">
                coming soon...
              </h5>
            </div>
          )}
        </div>
        <div className="new-project-items tablet">
          <div className="row">
            {upcomingProjects.length > 0 &&
              upcomingProjects.slice(0, 4).map((item, index) => {
                const {
                  startTime,
                  project_id,
                  logo,
                  id,
                  projectName,
                  totalRaising,
                } = item;
                const days = moment(startTime).diff(Date.now(), "days");
                return (
                  <div key={index} className="col-6 mt-5">
                    <div className="up-project">
                      <Link to={`company-page/${id}`}>
                        <div className="card">
                          <div className="up-p-card-header">
                            <img src={logo} alt="" />
                            <h4> {projectName}</h4>
                            <p className="count">
                              {" "}
                              In <span>{days}</span> Days
                            </p>
                          </div>
                          <div className="up-p-card-body">
                            <p>
                              <div className="text-center">Total Raising:</div>
                              <div className="text-center">
                                <span className="text-bold">
                                  {normalizeDollarAmount(totalRaising)}
                                </span>
                              </div>
                            </p>
                            <p>
                              <div className="text-center">Max allocation:</div>
                              <div className="text-center">
                                <span className="text-bold">TBA</span>
                              </div>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            {!upcomingProjects.length && (
              <div>
                <h5 className="text-capitalize text-center py-5">
                  coming soon...
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingProjects;
