import { modalSlice } from "./slice";
const { actions: slice } = modalSlice;

export const openModal = (modalProps) => (dispatch) => {
  dispatch(slice.openModal(modalProps));
};

export const closeModal = (modalProps) => (dispatch) => {
  dispatch(slice.closeModal(modalProps));
};
