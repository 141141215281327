import React from "react";
import { ModalContent, ModalText, ModalTitle, SubmitButton } from "./shared";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../store/modal/actions";

const ErrorModal = ({ description }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <ModalContent>
      <StyledModalTitle>Error!</StyledModalTitle>

      <Description>{description}</Description>

      <SubmitButton variant="primary" onClick={handleClose}>
        Close
      </SubmitButton>
    </ModalContent>
  );
};

const StyledModalTitle = styled(ModalTitle)`
  text-align: center;
`;

const Description = styled(ModalText)`
  margin-bottom: 50px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
export default ErrorModal;
