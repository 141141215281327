import React from "react";
import styled from "styled-components/macro";

const SwapProcessing = () => (
  <Container>
    <Animation src="/img/animation2.gif" />

    <Message>Swap Processing...</Message>
  </Container>
);

const Container = styled.div`
  margin: auto;
`;

const Animation = styled.img`
  width: 210px;
  height: 210px;
`;

const Message = styled.div`
  font-weight: 600;
  font-size: 23px;
  color: #edf2ff;
  transform: translateY(-60px);
`;

export default SwapProcessing;
