import React from "react";
import styled from "styled-components/macro";
import FormElementWrapper from "./FormElementWrapper";

const TextArea = ({
  className,
  value,
  onChange,
  onBlur,
  placeholder,
  errorMessage,
}) => (
  <FormElementWrapper
    className={className}
    onBlur={onBlur}
    errorMessage={errorMessage}
  >
    <Container>
      <TextAreaElement
        placeholder={placeholder}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
      />
    </Container>
  </FormElementWrapper>
);

const Container = styled.div`
  height: 137px;
  background: #f1f4ff;
  border: 1px solid #8e9fcb;
  border-radius: 5px;
  padding: 14px 10px;
  width: 100%;
`;

const TextAreaElement = styled.textarea`
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 300;
  font-size: 18px;
  color: #071b56;
  width: 100%;
  resize: none;
  height: 100%;

  &::placeholder {
    color: #8e9fcb;
  }
`;

export default TextArea;
