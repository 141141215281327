import moment from "moment";
import { projectFilterTypes, projectOpenStatuses } from "../consts";
import { map, toLower } from "lodash";
import Fuse from "fuse.js";
import parseMilliseconds from "parse-ms";
import { includes } from "lodash-es";

export const getProjectOpenStatus = (project) => {
  const { startTime, endTime, totalRaising, totalRaised } = project;
  const startMoment = moment(startTime);
  const endMoment = moment(endTime);
  const currentMoment = moment();

  if (currentMoment > endMoment || totalRaised >= totalRaising) {
    return projectOpenStatuses.closed;
  }

  if (currentMoment < startMoment) {
    return projectOpenStatuses.upcoming;
  }

  if (currentMoment >= startMoment && currentMoment <= endMoment) {
    return projectOpenStatuses.open;
  }
};

export const getProjectOpenStatusMessage = (project) => {
  const status = getProjectOpenStatus(project);

  switch (status) {
    case projectOpenStatuses.upcoming:
      return "Opening Soon";

    case projectOpenStatuses.open:
      return "Open Project";

    case projectOpenStatuses.closed:
      return "Closed Project";

    default:
      return "";
  }
};

export const getAmountOfDaysUtilDate = (endDateMoment) =>
  parseInt((endDateMoment - moment()) / 1000 / 60 / 60 / 24);

export const getTimeRemainingString = (endDateMoment) => {
  const milliseconds = endDateMoment - moment();
  const { days, hours, minutes } = parseMilliseconds(milliseconds);

  return `${days} days ${hours} hours and ${minutes} minutes`;
};

export const getCountriesListMessage = (countriesArray) => {
  let result = "";
  const length = countriesArray.length;

  for (let i = 0; i < length - 1; i++) {
    result = `${result}${countriesArray[i].value}, `;
  }

  return `${result}${countriesArray[length - 1].value}`;
};

export const getFuzzySearchResults = ({
  originalData,
  searchFields,
  inputValue,
}) => {
  if (!inputValue) {
    return originalData;
  }

  const options = {
    keys: searchFields,
  };
  const fuse = new Fuse(originalData, options);

  const searchResults = fuse.search(inputValue);

  return map(searchResults, (result) => result.item);
};

export const isProjectFitsFilter = ({ project, filterValues }) => {
  let isCorrectStatus = true;
  let isCorrectFundingType = true;
  let isCorrectIndustry = true;
  let isCorrectStage = true;

  if (!project) {
    return false;
  }

  const { fundingType, typeOfIndustry, currentStage } = project;

  if (filterValues[projectFilterTypes.projectStatus]) {
    isCorrectStatus =
      toLower(getProjectOpenStatus(project)) ===
      toLower(filterValues[projectFilterTypes.projectStatus]);
  }

  if (filterValues[projectFilterTypes.typeOfInvestment]) {
    isCorrectFundingType =
      toLower(fundingType) ===
      toLower(filterValues[projectFilterTypes.typeOfInvestment]);
  }

  if (filterValues[projectFilterTypes.typeOfIndustry]) {
    isCorrectIndustry = includes(
      map(typeOfIndustry, (industry) => toLower(industry)),
      toLower(filterValues[projectFilterTypes.typeOfIndustry])
    );
  }

  if (filterValues[projectFilterTypes.companyStage]) {
    isCorrectStage =
      toLower(currentStage) ===
      toLower(filterValues[projectFilterTypes.companyStage]);
  }

  return (
    isCorrectFundingType &&
    isCorrectIndustry &&
    isCorrectStage &&
    isCorrectStatus
  );
};
