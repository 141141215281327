import { DALServiceV2 } from "../../../services/data-access-layer.service";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../consts";

export const useConsultant = (queryOptions = {}) =>
  useQuery(
    [queryKeys.consultant],
    () => DALServiceV2.getConsultantProfile(),
    queryOptions
  );
