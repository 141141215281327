import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

export const useRerouteToFormStart = ({
  noReroute,
  formStartUrl,
  completedStepsSelector,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const completedSteps = useSelector(completedStepsSelector);
  const isCurrentlyOnStart = location.pathname === formStartUrl;

  useEffect(() => {
    if (noReroute) {
      return;
    }

    if (isEmpty(completedSteps) && !isCurrentlyOnStart) {
      navigate(formStartUrl);
    }
  }, []);
};
