import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { useSelector } from "react-redux";
import { ModalContent } from "../shared";
import { modalSelector } from "../../../store/selectors/modal";
import { useUSDTBalance } from "../../../utils/api/queries/useUSDTBalance";
import { useQueryClient } from "@tanstack/react-query";
import { useSelectedNetwork } from "../../../utils/api/queries/useSelectedNetwork";
import Input from "../../core/formElements/Input";
import Button from "../../core/Button";
import { buttonVariants, queryKeys } from "../../../consts";
import { NetworkHandler } from "../../../helpers/NetworkHandler";
import {
  _invest,
  getBaseCoinAddress,
  HandleApprove,
} from "../../../helpers/HttpService";
import toast from "react-hot-toast";
import SwapProcessing from "./investingModal/SwapProcessing";
import SwapSuccess from "./investingModal/SwapSuccess";
import SwapError from "./investingModal/SwapError";

const swapSteps = {
  inProgress: "inProgress",
  success: "success",
  error: "error",
};

const InvestingModal = () => {
  const queryClient = useQueryClient();

  const [amountOfTokens, setAmountOfTokens] = useState();
  const [amountOfUSDT, setAmountOfUSDT] = useState();
  const [swapStep, setSwapStep] = useState();

  const { project, investorData, swapRate } = useSelector(modalSelector);
  const { data: usdtBalance, isSuccess: isBalanceSuccess } = useUSDTBalance();
  const { data: selectedNetwork } = useSelectedNetwork();

  const fullName = investorData?.contactInfo.fullName;
  const logo = selectedNetwork?._chain?.logo;
  const chainName = selectedNetwork?._chain?.name;
  const tokenPrice = Number(1 / swapRate);

  const title = (
    <>
      Hi {fullName}! <br />
      We are happy for your <br />
      interest in investing
    </>
  );

  const handleBalanceReload = () => {
    queryClient.invalidateQueries([queryKeys.usdtBalance]);
  };

  const handleChangeAmountOfTokens = (inputValue) => {
    const parsedAmount = Number(inputValue);

    setAmountOfTokens(inputValue);
    setAmountOfUSDT((parsedAmount * tokenPrice).toString());
  };

  const handleChangeAmountOfUSDT = (inputValue) => {
    const parsedAmount = Number(inputValue);

    setAmountOfUSDT(inputValue);
    setAmountOfTokens((parsedAmount / tokenPrice).toString());
  };

  const addToken = async (tokenAddress, tokenSymbol) =>
    window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: 18, // The number of decimals in the token
          // image: tokenImage, // A string url of the token logo
        },
      },
    });

  const handleSwap = async () => {
    setSwapStep(swapSteps.inProgress);
    const address = window.ethereum && window.ethereum?.selectedAddress;
    const { status, message } = await NetworkHandler();
    if (status) {
      const basecoinAddress = await getBaseCoinAddress();
      //TODO: add a check if already approved
      HandleApprove(basecoinAddress, amountOfUSDT, address)
        .then((status) => {
          if (status) {
            return _invest(project.projectId, amountOfUSDT, address)
              .then(async (res) => {
                try {
                  await addToken(project.tokenAddress, project.symbol);
                } catch (e) {
                  console.error(`e`, e);
                } finally {
                  setSwapStep(swapSteps.success);
                }
              })
              .catch((e) => {
                setSwapStep(swapSteps.error);
              });
          }
        })
        .catch((e) => {
          setSwapStep(swapSteps.error);
        });
    } else {
      toast.error(message);
    }
  };

  const renderContent = () => {
    switch (swapStep) {
      case swapSteps.inProgress:
        return <SwapProcessing />;

      case swapSteps.success:
        return <SwapSuccess />;

      case swapSteps.error:
        return <SwapError retry={handleSwap} />;

      default:
        return (
          <>
            <Title>{title}</Title>

            {isBalanceSuccess && (
              <BalanceWrapper>
                <Balance>{`Balance: ${usdtBalance.toFixed(2)}`}</Balance>
                <ReloadImage
                  src="/img/loader-icon.svg"
                  onClick={handleBalanceReload}
                />
              </BalanceWrapper>
            )}

            <NetworkWrapper>
              Network: <ChainLogo src={logo} /> {chainName}
            </NetworkWrapper>

            <PriceBanner> {`1 Token=${tokenPrice} USDT`}</PriceBanner>

            <FieldsWrapper>
              <FieldWrapper>
                <FieldTitle>Amount of Tokens</FieldTitle>

                <StyledInput
                  isNumeric
                  value={amountOfTokens}
                  onChange={handleChangeAmountOfTokens}
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldTitle>Amount of USDT</FieldTitle>

                <StyledInput
                  isNumeric
                  value={amountOfUSDT}
                  onChange={handleChangeAmountOfUSDT}
                />
              </FieldWrapper>
            </FieldsWrapper>

            <Button
              variant={buttonVariants.primary}
              onClick={handleSwap}
              disabled={!Number(amountOfUSDT) > 0}
            >
              Swap
            </Button>
          </>
        );
    }
  };

  return (
    <StyledModalContent swapStep={swapStep}>
      {renderContent()}
    </StyledModalContent>
  );
};

const StyledModalContent = styled(ModalContent)`
  height: 701px;
  align-items: center;

  @media (max-width: 768px) {
    height: 616px;
  }

  ${({ swapStep }) =>
    !!swapStep &&
    css`
      background-color: #09216a;
      height: 508px;

      @media (max-width: 768px) {
        height: 327px;
      }
    `}
`;

const Title = styled.div`
  color: #09216a;
  font-weight: 600;
  font-size: 23px;
  line-height: 23px;

  margin: 109px 80px 27px 80px;

  @media (max-width: 768px) {
    margin: 81px 30px 17px 30px;
  }
`;

const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Balance = styled.div`
  color: #09216a;
  font-weight: 700;
  font-size: 14px;
`;

const PriceBanner = styled.div`
  width: 245px;
  height: 52px;
  margin-bottom: 20px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d4798;
  border-radius: 10px;
`;

const ReloadImage = styled.img`
  margin-left: 43px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const NetworkWrapper = styled.div`
  color: #09216a;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ChainLogo = styled.img`
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;

const FieldTitle = styled.div`
  color: #09216a;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 15px;
`;

const StyledInput = styled(Input)`
  width: 118px;
`;

const FieldsWrapper = styled.div`
  gap: 10px;
  margin-bottom: 68px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default InvestingModal;
