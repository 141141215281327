import React, { useRef } from "react";
import styled, { css } from "styled-components/macro";
import toast from "react-hot-toast";
import Button from "../Button";
import { buttonVariants } from "../../../consts";
import { isEmpty, last } from "lodash";
import FormElementWrapper from "../formElements/FormElementWrapper";
import DisplayFile from "./DisplayFile";

const imageFileExtensions = ["jpg", "jpeg", "png", "pdf"];

const FileUpload = ({
  className,
  title,
  subtitle,
  accept = ".jpg, .jpeg, .png, .doc, .docx, .pdf",
  info = "*Pdf/Word/Jpeg/Png (50MB max size)",
  errorMessage,
  value = {},
  onChange,
}) => {
  const inputRef = useRef();

  const isWithFile = !isEmpty(value);

  const handleSymbolUpload = (file) => {
    const formDate = new FormData();
    formDate.append("file", file[0]);
    formDate.append("upload_preset", "pitchshow");
    const fileExtension = last(file[0]?.name?.split("."));
    const isImage = imageFileExtensions.includes(fileExtension);

    fetch(
      `https://api.cloudinary.com/v1_1/pitchshow/${
        isImage ? "image" : "raw"
      }/upload`,
      {
        method: "post",
        body: formDate,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.secure_url) {
          onChange &&
            onChange({
              ...value,
              symbolURL: result.secure_url,
              fileName: file[0]?.name,
            });
        } else {
          toast.error("Please try again.");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleButtonClick = () => {
    inputRef?.current?.click();
  };

  return (
    <FormElementWrapper className={className} errorMessage={errorMessage}>
      <Container>
        <ControlSection>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Button
            width="157px"
            variant={buttonVariants.tertiary}
            borderRadius="10px"
            onClick={handleButtonClick}
          >
            {isWithFile ? "Replace" : "Upload"} Doc
          </Button>

          <Info>{info}</Info>
        </ControlSection>

        <InputWrapper isWithFile={isWithFile}>
          <Input
            ref={inputRef}
            type="file"
            accept={accept}
            onChange={(e) => handleSymbolUpload(e.target.files)}
          />

          {isWithFile ? (
            <DisplayFile name={value?.fileName} />
          ) : (
            <span>Drag & Drop File Here</span>
          )}
        </InputWrapper>

        {isWithFile && (
          <SmallDisplayFile>
            <DisplayFile name={value?.fileName} />
          </SmallDisplayFile>
        )}
      </Container>
    </FormElementWrapper>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ControlSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000f3c;
`;

const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #6d80b1; ;
`;

const Info = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #3e558d;
`;

const InputWrapper = styled.div`
  width: 223px;
  height: 132px;
  flex-shrink: 0;
  background: linear-gradient(0deg, #e4ebff, #e4ebff), #e2eaff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;

  ${({ isWithFile }) =>
    !isWithFile &&
    css`
      align-items: center;
    `}

  @media (max-width: 768px) {
    display: none;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
`;

const SmallDisplayFile = styled.div`
  display: none;

  @media (max-width: 768px) {
    margin-top: 8px;
    display: flex;
  }
`;

export default FileUpload;
