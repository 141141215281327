import { useState, useEffect } from "react";
import { AuthService } from "../services/auth.service";

export const useRecaptcha = ({ recaptchaId }) => {
  const [verifier, setVerifier] = useState();

  useEffect(() => {
    const init = async () => {
      const verifier = await AuthService.recaptchaVerifier(recaptchaId, (e) => {
        setVerifier(e);
      });

      setVerifier(verifier);
    };

    init();
  }, []);

  return verifier;
};
