import { useState } from "react";
import style from "./box.module.scss";
import styled from "styled-components/macro";
export default ({
  onClick,
  backgroundColor: _backgroundColor,
  title,
  icon: Icon,
  onHoverBackgroundColor,
  iconColor: _iconColor,
  onHoverIconColor,
}) => {
  const [iconColor, setIconColor] = useState(_iconColor);
  const [backgroundColor, setBackgroundColor] = useState(_backgroundColor);

  const mouseOut = (e) => {
    setBackgroundColor(_backgroundColor);
    setIconColor(_iconColor);
  };

  const mouseOver = (e) => {
    setBackgroundColor(onHoverBackgroundColor);
    setIconColor(onHoverIconColor);
  };

  return (
    <Container
      onClick={onClick}
      onMouseOver={mouseOver}
      style={{ backgroundColor }}
      onMouseOut={mouseOut}
      className={`${style.box_container} position-relative d-flex justify-content-center align-items-center flex-column text-capitalize`}
    >
      <Icon color={iconColor} />
      <span>{title}</span>
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
`;
