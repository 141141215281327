import { DALServiceV2 } from "../../../services/data-access-layer.service";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../consts";

export const useInvestor = (queryOptions = {}) => {
  return useQuery(
    [queryKeys.investor],
    () => DALServiceV2.getInvestorProfile(),
    queryOptions
  );
};
