import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companySignUpCompletedStepsSelector,
  companySignUpSelector,
} from "../../../../store/selectors/signUp";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/core/Button";
import { buttonVariants, signUpCompanyTokenSteps } from "../../../../consts";
import {
  setCompanyCompletedSteps,
  setListingInfo,
} from "../../../../store/companySignUp/actions";
import { getFieldError } from "../../../../utils/formUtils";
import {
  ButtonsWrapper,
  Container,
  Description,
  InputsWrapper,
  Title,
} from "../styled";
import Input from "../../../../Components/core/formElements/Input";
import FileUpload from "../../../../Components/core/fileUpload/FileUpload";
import InfoIcon from "../../../../Components/core/icons/InfoIcon";
import DatePicker from "../../../../Components/core/formElements/DatePicker";
import Dropdown from "../../../../Components/core/formElements/dropdown/Dropdown";
import config from "../../../../assets/config.json";

const ListingInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listingInfo } = useSelector(companySignUpSelector) || {};
  const completedSteps = useSelector(companySignUpCompletedStepsSelector);

  const { handleSubmit, control } = useForm({
    defaultValues: listingInfo,
  });

  const onSubmit = (data) => {
    dispatch(setListingInfo(data));

    dispatch(
      setCompanyCompletedSteps([
        ...completedSteps,
        signUpCompanyTokenSteps.listingInfo,
      ])
    );

    navigate("/sign-up/company/partners-and-influencers");
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Title>Listing info</Title>

      <Description>Enter Listing info below</Description>

      <Content>
        <StyledInputsWrapper>
          <Controller
            name="idoSize"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="IDO size"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                InnerComponent={() => (
                  <InfoIcon info="Total amount in USD to raise in price value" />
                )}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Size",
                })}
              />
            )}
          />

          <Controller
            name="maxAllocation"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Tokens allocated"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isNumeric
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Amount",
                })}
              />
            )}
          />

          <Controller
            name="additionalPlatforms"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Additional platforms"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                InnerComponent={() => (
                  <InfoIcon info="Name of the platform raising funds" />
                )}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Additional info",
                })}
              />
            )}
          />

          <Controller
            name="pitchshowsSize"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Pitchshows size"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Size",
                })}
              />
            )}
          />

          <Controller
            name="tokenGenerationEvent"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <DatePicker
                placeholder="Token generation event"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Date",
                })}
              />
            )}
          />

          <Controller
            name="idoTimeframe"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="IDO Timeframe (in days)"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Timeframe",
                })}
              />
            )}
          />

          <Controller
            name="pitchshowsTokenPrice"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Input
                placeholder="Pitchshows token price"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                InnerComponent={() => (
                  <InfoIcon info="Discount price for Pitchshow in % and number" />
                )}
                isNumeric
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Price",
                })}
              />
            )}
          />

          <Controller
            name="moneyLastDuration"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => (
              <Dropdown
                placeholder="For how long will the money last?"
                options={config.shared.moneyLastDuration}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={getFieldError({
                  error,
                  fieldLabel: "Duration",
                })}
              />
            )}
          />
        </StyledInputsWrapper>

        <Controller
          name="pitchshowVestingTerms"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FileUpload
              title="Pitchshows vesting terms"
              subtitle="Upload pitchshows vesting terms"
              value={value}
              onChange={onChange}
              errorMessage={getFieldError({
                error,
                fieldLabel: "File",
              })}
            />
          )}
        />
      </Content>

      <StyledButtonsWrapper>
        <Button type="submit" variant={buttonVariants.primary}>
          Next
        </Button>

        <Button
          variant={buttonVariants.secondary}
          onClick={() => navigate("/sign-up/company/metrics-info")}
        >
          Back
        </Button>
      </StyledButtonsWrapper>
    </Container>
  );
};

const Content = styled.div`
  margin-bottom: 45px;
`;

const StyledInputsWrapper = styled(InputsWrapper)`
  margin-bottom: 50px;
`;

const StyledButtonsWrapper = styled(ButtonsWrapper)`
  margin-bottom: 57px;
`;

export default ListingInfo;
