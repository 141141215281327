import { deleteUser, getAuth } from "firebase/auth";
import DB, { functions } from "../utils/db";
import promiseRetry from "promise-retry";

import {
  addDoc,
  collection,
  getDoc,
  setDoc,
  doc,
  updateDoc,
  getDocs,
  query,
  where,
  documentId,
  collectionGroup,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { ERRORS } from "../config/errors";
import { AuthService } from "./auth.service";
import { httpsCallable } from "firebase/functions";

export default class DALService {
  collections = {
    USERS: "users",
    COMPANIES: "companies",
    INVESTORS: "investors",
    INVESTOR_LEADS: "investor_leads",
    PROJECTS: "projects",
    INVESTMENTS: "investments",
  };

  async createUser() {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const {
          uid: id,
          accessToken,
          displayName,
          email,
          emailVerified,
          photoURL,
        } = currentUser;
        const existingUserDoc = await this.getUserById();
        if (!existingUserDoc.exists()) {
          const userDoc = doc(DB, this.collections.USERS, id);
          const user = {
            accessToken,
            displayName,
            email,
            emailVerified,
            photoURL,
          };
          await setDoc(userDoc, user);
          return { ...user, id };
        } else {
          throw ERRORS.USER_EXISTS;
        }
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateUser(user) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid: id } = currentUser;
        const existingUserDoc = await this.getUserById();
        if (!existingUserDoc.exists()) {
          throw ERRORS.USER_EXISTS;
        } else {
          const oldData = { ...existingUserDoc.data(), ...user };
          await updateDoc(
            doc(DB, this.collections.USERS, existingUserDoc.id),
            oldData
          );
          return oldData;
        }
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getUserById() {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid: id } = currentUser;
        return getDoc(doc(DB, this.collections.USERS, id));
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async createCompany(company) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const docRef = doc(DB, `/${this.collections.COMPANIES}/${uid}`);
        await setDoc(docRef, company);
        return company;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async createInvestor(investor) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const docRef = doc(DB, `/${this.collections.INVESTORS}/${uid}`);
        await setDoc(docRef, investor);
        return investor;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // public
  async getProjects() {
    try {
      const projectsRef = collection(DB, `/${this.collections.PROJECTS}`);
      const q = query(projectsRef);
      const snapshot = await getDocs(q);
      const data = [];
      snapshot.forEach((el) => data.push({ ...el.data(), project_id: el.id }));
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: to be called from investor account page
  async getInvestments(project_id = null) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        let data = [];
        if (project_id) {
          const path = `/${this.collections.USERS}/${uid}/investor/${project_id}/investments`;
          const investmentsRef = collection(DB, path);
          const q = query(investmentsRef);
          const snapshot = await getDocs(q);
          snapshot.forEach((el) => data.push({ ...el.data() }));
        } else {
          const userInvestments = query(
            collectionGroup(DB, "investments"),
            where("user_id", "==", uid)
          );
          const userInvestmentsSnapshot = await getDocs(userInvestments);
          userInvestmentsSnapshot.forEach((el) => {
            return data.push({ ...el.data() });
          });
        }
        return data;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: to be called as a company to get all users added the company to their watch list
  async getInvestorsAddedToWatchList(project_id) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const data = [];
        const investorsAddedToWatchList = query(
          collectionGroup(DB, "investor"),
          where("in_watch_list", "==", true),
          where("project_id", "==", project_id)
        );
        const investorsAddedToWatchListSnapshot = await getDocs(
          investorsAddedToWatchList
        );

        investorsAddedToWatchListSnapshot.forEach((el) =>
          data.push({ ...el.data() })
        );

        return data;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: to be called from company account page
  async getInvestors(project_id) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const path = `/${this.collections.USERS}/${uid}/company/${project_id}/investors`;
        const investmentsProjectsRef = collection(DB, path);
        const q = query(investmentsProjectsRef);
        const snapshot = await getDocs(q);
        const data = [];
        snapshot.forEach((el) => {
          data.push({ ...el.data() });
        });
        return data;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // to be called to add / remove from watch list
  async updateWatchList(
    project_id = 0,
    add_to_watch_list = true,
    investor_data = null
  ) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const ref = doc(
          DB,
          `/${this.collections.USERS}/${uid}/investor/${project_id}`
        );
        const data = {
          in_watch_list: add_to_watch_list,
          created_at: Date.now(),
          user_id: uid,
          project_id,
          investor_data,
        };

        await setDoc(ref, data);
        return data;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async isInWatchlist(project_id) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const path = `/${this.collections.USERS}/${uid}/investor/${project_id}`;
        const snapshot = await getDoc(doc(DB, path));
        return snapshot.data();
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: to be used as investor to retreive projectId data -> will return {in_watch_list: boolean}
  async getProjectData(project_id = "0") {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const ref = doc(
          DB,
          `/${this.collections.USERS}/${uid}/investor/${project_id}`
        );
        const q = query(ref);
        const snapshot = await getDoc(q);
        return snapshot.data();
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: to simulate the operation from backoffice
  async createProject(
    project_id = "0",
    company_id = "VVS6bCXdnShKuZ1czIDOnAFWbXC2",
    project = {}
  ) {
    try {
      project = {
        ...project,
        company_id,
      };

      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const projectsPublicRef = doc(DB, `/projects/${project_id}`);
        const companyProjectRef = doc(
          DB,
          `/users/${company_id}/company/${project_id}`
        );
        await Promise.all([
          setDoc(projectsPublicRef, project),
          await setDoc(companyProjectRef, project),
        ]);
        return {
          project_id,
          ...project,
        };
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: to simulate the operation from backoffice
  async createInvestment(
    user_id = "VVS6bCXdnShKuZ1czIDOnAFWbXC2",
    company_id = "VVS6bCXdnShKuZ1czIDOnAFWbXC2",
    project_id = 0,
    investment = { test: true }
  ) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const investorRef = doc(
          collection(
            DB,
            `/${this.collections.USERS}/${user_id}/investor/${project_id}/investments`
          )
        );
        const companyRef = doc(
          collection(
            DB,
            `/${this.collections.USERS}/${company_id}/company/${project_id}/investors`
          )
        );
        await Promise.all([
          await setDoc(investorRef, investment),
          await setDoc(companyRef, investment),
        ]);
        return {
          ...investment,
          projectId: project_id,
          company_id,
          user_id,
        };
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getInvestorData() {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const investorRef = doc(DB, `/investors/${uid}`);
        const snapshot = await getDoc(investorRef);
        const data = snapshot.data();
        return data;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async createEquityInvestmentRequest(data) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const equityInvestmentRequestRef = doc(
          DB,
          `/equity_investment_requests/${uid}`
        );
        await setDoc(equityInvestmentRequestRef, data);
        return data;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateInvestor(data) {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        await updateDoc(doc(DB, `/investors/${uid}`), {
          "contactInfo.phoneNumber": data.phoneNumber,
          "contactInfo.phoneCode": data.phoneCode,
        });
        return data;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // to be called to add / remove from watch list
  async getUserWatchlist() {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const ref = collection(
          DB,
          `/${this.collections.USERS}/${uid}/investor`
        );
        const snapshot = await getDocs(ref);
        let data = [];
        snapshot.forEach((doc) => {
          data.push({
            project_id: doc.id,
            ...doc.data(),
          });
        });
        return data;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: assuming company can have only one project at the time
  async getCompanyIdRelatedByUserId() {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        const { uid } = currentUser;
        const ref = collection(DB, `/${this.collections.USERS}/${uid}/company`);
        const companyProjects = await getDocs(ref);
        let project_id;
        companyProjects.forEach((el) => (project_id = el.id));
        return project_id;
      } else {
        throw ERRORS.USER_IS_NOT_SIGNED_IN;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

/**
 * NOTE: PHASE 2
 */
export class DALServiceV2 extends DALService {
  // NOTE: global
  // user signs up
  // user choose init role
  // user provide neccesary documents
  // data is stored and reviewed by moderators

  // NOTE: investor
  // user able to retrieve projects and add to watch list
  // after user is verified is is now able to invest

  // NOTE: company
  // company is now able to list projects that need verification by moderators
  // company is able to retrieve all users who have added their project to their watch list including name, phone number, email, date added to watch list, and aggregated data: project status, total raised, number of investors, total amount left to raise, remaining time for closing the raise funding

  // NOTE: INVESTOR/COMPANY/CONSULTANT => any of the roles
  static async createUser(uid, role) {
    try {
      const path = `users/${uid}/roles/${role}`;
      const _doc = doc(DB, path);
      await setDoc(_doc, {});
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: company =>
  static async createCompanyProject(projectData) {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;
      const path = `/users/${userId}/roles/company/projects_listed`;
      const _collection = collection(DB, path);
      const docRef = doc(_collection);

      await setDoc(docRef, {
        projectId: docRef.id,
        projectData,
        userId,
        createdAt: new Date(),
        role: "company",
      });
      return docRef.id;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: company =>
  static async updateCompanyProject(projectId, projectData) {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;
      const path = `/users/${userId}/roles/company/projects_listed/${projectId}`;
      const _doc = doc(DB, path);
      await setDoc(_doc, {
        projectData,
        userId,
        createdAt: new Date(),
        role: "company",
      });
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: consultant =>
  static async createConsultantProject(projectData) {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;
      const path = `/users/${userId}/roles/consultant/projects_listed`;
      const _collection = collection(DB, path);
      const docRef = doc(_collection);

      await setDoc(docRef, {
        projectId: docRef.id,
        projectData,
        userId,
        createdAt: new Date(),
        role: "consultant",
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: BO =>
  // TODO: need to be tested via BO functionality
  static async verifyCompany(companyId) {
    try {
      const path = `/users/${companyId}/roles/company`;
      const _doc = doc(DB, path);
      const data = { verified: true };
      await setDoc(_doc, data);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: BO =>
  // TODO: need to be tested via BO functionality
  static async unverifyCompany(companyId) {
    try {
      const path = `/users/${companyId}/roles/company`;
      const _doc = doc(DB, path);
      const data = { verified: false };
      await setDoc(_doc, data);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: company =>
  static async getCompanyProjects(userId) {
    try {
      const path = `/users/${userId}/roles/company/projects_listed`;
      const collectionRef = collection(DB, path);
      const docsRef = await getDocs(collectionRef);
      return docsRef.docs.map((doc) => doc.data());
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: consultant =>
  static async getConsultantProjects(userId) {
    try {
      const path = `/users/${userId}/roles/consultant/projects_consulted`;
      const collectionRef = collection(DB, path);
      const docsRef = await getDocs(collectionRef);
      return docsRef.docs.map((doc) => doc.data());
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: investor =>
  static async getProjectsInvested(uid) {
    try {
      const path = `/users/${uid}/roles/investor/projects_invested`;
      const collectionRef = collection(DB, path);
      const docsRef = await getDocs(collectionRef);
      return docsRef.docs.map((doc) => doc.data());
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: BO => after validated you identify the user invested, what project id and what is the belonged company id
  // TODO: need to be tested via BO functionality
  static async assignInvestmentToUser(
    userId,
    companyId,
    projectId,
    investmentData
  ) {
    try {
      const path = `/users/${userId}/roles/investor/projects_invested`;
      const collectionRef = collection(DB, path);
      const { id } = await addDoc(collectionRef, {
        ...investmentData,
        userId,
        companyId,
        projectId,
      });
      return { id };
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: INVESTOR => add to watch list
  static async addToWatchList(companyId, projectId) {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;
      const path = `/users/${userId}/roles/investor/watchlist/${projectId}`;
      const _doc = doc(DB, path);
      await setDoc(_doc, {
        isInWatchList: true,
        projectId,
        userId,
        companyId,
        investorData: doc(DB, `/users/${userId}/roles/investor`),
        createdAt: new Date(),
      });
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  //NOTE: INVESTOR => remove from watch list
  static async removeFromWatchList(projectId) {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;
      const path = `/users/${userId}/roles/investor/watchlist/${projectId}`;
      const _doc = doc(DB, path);
      await updateDoc(_doc, {
        isInWatchList: false,
        createdAt: new Date(),
      });
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: COMPANY =>
  static async getProjectWatchList(projectId) {
    try {
      const path = "watchlist";
      const q = collectionGroup(DB, path);
      const documentDataQuery = query(
        q,
        where("projectId", "==", projectId),
        where("isInWatchList", "==", true)
      );
      const docsRef = await getDocs(documentDataQuery);
      const data = [];
      for (let i = 0; i < docsRef.docs.length; i++) {
        let watchItem = docsRef.docs[i].data();
        const investorData = await getDoc(watchItem.investorData);
        watchItem = {
          ...watchItem,
          investorData: investorData.data().roleData,
        };
        data.push(watchItem);
      }
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getInvestorProfile() {
    try {
      const uid = AuthService?.auth?.currentUser?.uid;
      const q = `/users/${uid}/roles/investor`;
      const _doc = doc(DB, q);
      const docRef = await getDoc(_doc);
      return docRef.data();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getCompanyProfile() {
    try {
      const uid = AuthService?.auth?.currentUser?.uid;
      const q = `/users/${uid}/roles/company`;
      const _doc = doc(DB, q);
      const docRef = await getDoc(_doc);
      return docRef.data();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getConsultantProfile() {
    try {
      const uid = AuthService?.auth?.currentUser?.uid;
      const q = `/users/${uid}/roles/consultant`;
      const _doc = doc(DB, q);
      const docRef = await getDoc(_doc);
      return docRef.data();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: INVESTOR =>
  static async getWatchList() {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;
      const path = `/users/${userId}/roles/investor/watchlist`;
      const collectionRef = collection(DB, path);
      const _query = query(collectionRef, where("isInWatchList", "==", true));
      const docsRef = await getDocs(_query);
      const data = [];
      for (let i = 0; i < docsRef.docs.length; i++) {
        let watchItem = docsRef.docs[i].data();
        const investorData = await getDoc(watchItem.investorData);
        watchItem = { ...watchItem, investorData: investorData.data() };
        data.push(watchItem);
      }
      return data;
      // return docsRef.docs.map((el) => el.data());
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: data = {initialRole: 'investor/consultant/company'}
  static async setUserData(data) {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;
      if (userId) {
        await promiseRetry(function (retry, number) {
          return (async () => {
            const path = `/users/${userId}`;
            const _doc = doc(DB, path);
            await updateDoc(_doc, data);
            return;
          })().catch((e) => retry(e));
        });
      } else {
        throw "USER_IS_NOT_AUTHENTICATED";
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getUserData() {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;

      if (userId) {
        const path = `/users/${userId}`;
        const _doc = doc(DB, path);
        const docRef = await getDoc(_doc);
        return docRef.data();
      } else {
        throw "USER_IS_NOT_AUTHENTICATED";
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: to be called for any role as long as it retrieves its own resource
  static async getProjects(role) {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;

      if (userId) {
        const uniqueCollection = {
          investor: "projects_invested",
          company: "projects_listed",
          consultant: "projects_listed",
        };
        const path = `/users/${userId}/roles/${role}/${uniqueCollection[role]}`;

        const _collection = collection(DB, path);
        const docsRef = await getDocs(_collection);
        return docsRef.docs.map((el) => ({ ...el.data(), id: el.id }));
      } else {
        throw "USER_IS_NOT_AUTHENTICATED";
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getProject(projectId) {
    try {
      const documentDataQuery = query(
        collectionGroup(DB, "projects_listed"),
        where("verified", "==", true),
        where("projectId", "==", projectId)
      );
      const docsRef = await getDocs(documentDataQuery);

      return docsRef.docs.map((el) => ({ ...el.data(), id: el.id }))?.[0];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: to be called as any user => will retrieve the verified projects by the moderator team
  static async getVerifiedProjects() {
    try {
      const path = "projects_listed";
      const q = collectionGroup(DB, path);
      const documentDataQuery = query(
        q,
        where("verified", "==", true),
        orderBy("createdAt", "desc")
      );
      const docsRef = await getDocs(documentDataQuery);
      return docsRef.docs.map((el) => ({ ...el.data(), id: el.id }));
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: as investor. needs to be connected to yelypay system and think about token project inevstment mechanism
  //TODO:
  invest(userId, companyId, projectId, currency, amount, createdAt) {
    try {
      const data = {
        currency,
        amount,
        userId,
        projectId,
        companyId,
        createdAt,
      };
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: as backoffice
  // TODO: to be tested
  static async createBackofficeInvestment(
    payerId,
    companyId,
    projectId,
    currency,
    amount,
    verified,
    createdAt
  ) {
    try {
      const data = {
        userId: payerId,
        projectId,
        companyId,
        currency,
        amount,
        verified,
        createdAt,
      };
      const path = `/users/${payerId}/roles/investor/projects_invested/${projectId}`;
      const _doc = doc(DB, path);
      const q = query(_doc);
      await setDoc(q, data);
      return;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: as backoffice
  // TODO:
  updateBackofficeInvestment() {}

  // NOTE: to be called as investor
  static async getInvestments() {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;

      if (userId) {
        const q = query(
          collectionGroup(DB, "orders"),
          where("uid", "==", userId),
          orderBy("createdAt", "desc")
        );
        const docsRef = await getDocs(q);

        return docsRef.docs.map((el) => ({ ...el.data(), id: el.id }));
      } else {
        throw "USER_IS_NOT_AUTHENTICATED";
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // NOTE: to be called by company/consultant
  static async getProjectInvestments(projectId, role) {
    try {
      const collection = "projects_invested";
      const _query = collectionGroup(DB, collection);
      const q = query(
        _query,
        where("role", "==", role),
        where("project_id", "==", projectId)
      );
      const docsRef = await getDocs(q);

      return docsRef.docs.map((el) => el.data());
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async setRoleProfile(role, roleData) {
    try {
      const uid = AuthService?.auth?.currentUser?.uid;
      if (uid) {
        const path = `/users/${uid}/roles/${role}`;
        const _doc = doc(DB, path);
        const q = query(_doc);
        await setDoc(q, { roleData });
        return;
      } else {
        throw "USER_IS_NOT_AUTHENTICATED";
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getRoleProfiles() {
    try {
      const uid = AuthService?.auth?.currentUser?.uid;
      if (uid) {
        const path = `/users/${uid}/roles`;
        const collectionRef = collection(DB, path);
        const docsRef = await getDocs(collectionRef);
        return docsRef.docs.map((el) => ({ ...el.data(), id: el.id }));
      } else {
        throw "USER_IS_NOT_AUTHENTICATED";
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // works only if investor is not verified
  static async updateRoleProfile(role, data) {
    try {
      const uid = AuthService?.auth?.currentUser?.uid;
      if (uid) {
        const path = `/users/${uid}/roles/${role}`;
        const _doc = doc(DB, path);
        const q = query(_doc);
        await updateDoc(q, data);
        return;
      } else {
        throw "USER_IS_NOT_AUTHENTICATED";
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async createInvestmentEnquiry(projectId) {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;

      if (userId) {
        const path = `/users/${userId}/roles/investor/investment_enquiry/${projectId}`;
        const _doc = doc(DB, path);
        const q = query(_doc);
        await setDoc(q, { createdAt: new Date(), userId });
        return;
      } else {
        throw "USER_IS_NOT_AUTHENTICATED";
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // TODO: to be used by company/consultant => apply a corresponding rule in DB
  static async getInvestmentsAsFundRaiser(projectId) {
    try {
      const userId = AuthService?.auth?.currentUser?.uid;

      if (userId) {
        const data = [];
        const projectInvestorsQuery = query(
          collectionGroup(DB, "orders"),
          where("status", "==", "success"),
          where("projectId", "==", projectId)
        );
        const projectInvestorsSnapshot = await getDocs(projectInvestorsQuery);

        projectInvestorsSnapshot.forEach((el) => data.push({ ...el.data() }));

        return data;
      } else {
        throw new Error("USER_IS_NOT_AUTHENTICATED");
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getInvestmentsData({ role }) {
    const callableReturnMessage = httpsCallable(
      functions,
      "getInvestmentsData"
    );

    return callableReturnMessage({ role });
  }
}
