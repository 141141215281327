import React, { useState } from "react";
import { ModalContent, ModalText, ModalTitle, SubmitButton } from "./shared";
import styled from "styled-components/macro";
import { Controller, useForm } from "react-hook-form";
import ModalInput from "../../../core/formElements/ModalInput";
import {
  buttonVariants,
  emailRegex,
  modalTypes,
  passwordRegex,
  roles,
} from "../../../../consts";
import RoleRadioButtonGroup from "../../../core/formElements/RoleRadioButtonGroup";
import Recaptcha from "../../../Recaptcha";
import { useMutation } from "@tanstack/react-query";
import { createUserWithEmailAndPassword } from "../../../../utils/api/mutations";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../store/modal/actions";
import PasswordExplanation from "./PasswordExplanation";
import { keys, startCase, values } from "lodash-es";
import ClickableText from "../../../core/ClickableText";

const SignUpModal = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const { handleSubmit, control, getValues, watch } = useForm({
    defaultValues: {
      role: roles.investor.value,
    },
  });

  const currentEmail = watch("email");

  const { mutate: signUp, isLoading } = useMutation({
    mutationFn: createUserWithEmailAndPassword,
    onSuccess: () => {
      dispatch(
        openModal({
          type: modalTypes.signUpSubmittedModal,
          email: currentEmail,
        })
      );
    },
    onError: (e) => {
      const { code } = e;

      if (code === "auth/email-already-in-use") {
        setError("Email is already in use!\nUse other email to register");
      } else {
        setError("Something went wrong!\nPlease try again later");
      }
    },
  });

  const onSubmit = (values) => {
    const { email, password, role } = values;

    signUp({ email, password, role });
  };

  const onError = (errorFields) => {
    const firstErrorField = keys(errorFields)[0];

    setError(`${startCase(firstErrorField)} is incorrect!`);
  };

  const handleLogIn = () => {
    dispatch(openModal({ type: modalTypes.signInModal }));
  };

  return (
    <ModalContent>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Recaptcha />
        <StyledModalTitle>Account Sign Up</StyledModalTitle>

        <Description>
          {!error ? (
            <p>
              Welcome! For initial registration, enter your email address and
              password and select your profile type
            </p>
          ) : (
            <Error>{error}</Error>
          )}
        </Description>

        <InputsWrapper>
          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: emailRegex }}
            render={({ field: { value, onChange } }) => (
              <ModalInput
                value={value}
                onChange={onChange}
                label="Email address"
              />
            )}
          />

          <Password>
            <Controller
              name="password"
              control={control}
              rules={{ required: true, pattern: passwordRegex }}
              render={({ field: { value, onChange } }) => (
                <ModalInput
                  value={value}
                  onChange={onChange}
                  label="Password"
                  type="password"
                />
              )}
            />

            <PasswordExplanation />
          </Password>

          <Controller
            name="repeatPassword"
            control={control}
            rules={{
              required: true,
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();

                  return password === value || "Passwords should match!";
                },
              },
            }}
            render={({ field: { value, onChange } }) => (
              <ModalInput
                value={value}
                onChange={onChange}
                label="Repeat password"
                type="password"
              />
            )}
          />
        </InputsWrapper>

        <StyledModalText>I want to sign up as</StyledModalText>

        <RoleSelection>
          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <RoleRadioButtonGroup
                value={value}
                onChange={onChange}
                config={values(roles)}
              />
            )}
          />
        </RoleSelection>

        <SubmitButton
          variant={buttonVariants.primary}
          type="submit"
          disabled={isLoading}
        >
          Sign Up
        </SubmitButton>

        <AlreadyHaveAccount>
          Already have an account? <LogIn onClick={handleLogIn}>Log in</LogIn>
        </AlreadyHaveAccount>
      </Form>
    </ModalContent>
  );
};

const Form = styled.form``;

const StyledModalTitle = styled(ModalTitle)`
  margin-bottom: 15px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Description = styled(ModalText)`
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    gap: 18px;
  }
`;

const Password = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledModalText = styled(ModalText)`
  text-align: center;
  margin-bottom: 10px;
`;

const RoleSelection = styled.div`
  width: 80%;
  margin: 0 auto 40px auto;
`;

const Error = styled.div`
  font-size: 18px;
  color: #ff2853;
  white-space: break-spaces;
`;

const AlreadyHaveAccount = styled.div`
  display: flex;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #696f79;
  justify-content: center;
`;

const LogIn = styled(ClickableText)`
  margin-left: 5px;
  color: #2c73eb;
`;

export default SignUpModal;
