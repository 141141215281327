import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import Web3 from "web3";
import { APP_ABI, PITCHSHOW_ABI } from "../utils/config";
import { injected } from "../utils/connectors";

export const useAppContract = () => {
  const { activate, account: from, active, library, error } = useWeb3React();

  const web3 = new Web3(library?.provider || process.env.REACT_APP_RPC_URI);

  useEffect(() => {
    if (!(active && error)) {
      activate(injected);
    }
  }, [from]);

  const appContract = new web3.eth.Contract(
    APP_ABI,
    process.env.REACT_APP_APP_ADDRESS,
    { from }
  );

  const pitchContract = new web3.eth.Contract(
    PITCHSHOW_ABI,
    process.env.REACT_APP_PITCHSHOW_ADDRESS,
    { from }
  );

  const getInvestmentsAmount = (projectId) =>
    appContract.methods.getInvestmentsAmount(projectId).call();

  const getProjects = async () => pitchContract.methods.getProjects().call();
  const getBaseCoinAddress = async () =>
    pitchContract.methods.getBaseCoinAddress().call();
  const isWhitelisted = async (onChainProjectId) =>
    pitchContract.methods.getBaseCoinAddress(onChainProjectId).call();
  const getProjectLeftOverTokenAmount = async (onChainProjectId) =>
    pitchContract.methods
      .getProjectLeftOverTokenAmount(onChainProjectId)
      .call();

  const getMyInvestments = async () => {
    try {
      const projects = await getProjects();
      return Promise.all(projects.map((_, ind) => getInvestmentsAmount(ind)));
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return {
    getInvestmentsAmount,
    getMyInvestments,
    getBaseCoinAddress,
    isWhitelisted,
    getProjectLeftOverTokenAmount,
  };
};
