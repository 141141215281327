import React from "react";
import styled from "styled-components/macro";
import ProjectInfoCard from "./ProjectInfoCard";
import {
  normalizeDollarAmount,
  normalizeNumber,
} from "../../utils/normalizations";

const ProjectInfoCards = ({ className, project }) => {
  const {
    typeOfIndustry,
    totalRaising,
    totalRevenueSinceIncorporated,
    numberOfStaff,
    currentStage,
    totalRaisedTillDate,
  } = project;

  return (
    <Container className={className}>
      <ProjectInfoCard
        icon="/img/industry.svg"
        title="Type of industry"
        description={typeOfIndustry?.[0]}
      />

      <ProjectInfoCard
        icon="/img/money-bag.svg"
        title="Total raising"
        description={normalizeDollarAmount(totalRaising)}
      />

      <ProjectInfoCard
        icon="/img/calculator.svg"
        title="Total Revenue since Incorporation"
        description={normalizeDollarAmount(totalRevenueSinceIncorporated)}
      />

      <ProjectInfoCard
        icon="/img/people.svg"
        title="Number of staff"
        description={normalizeNumber(numberOfStaff)}
      />

      <ProjectInfoCard
        icon="/img/rocket.svg"
        title="Current stage"
        description={currentStage}
      />

      <ProjectInfoCard
        icon="/img/graph.svg"
        title="Total Investments to Date"
        description={normalizeDollarAmount(totalRaisedTillDate)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 610px;

  gap: 20px;

  @media (max-width: 768px) {
    gap: 16px;
    justify-content: center;
  }
`;

export default ProjectInfoCards;
